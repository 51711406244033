import React from 'react';
import './Button.css';
import CircularLoader from '../CircularLoader/CircularLoader';

/* Vars
  type: string: CallToAction, Solid, Outline, Borderless
  onClick: function
*/

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <button
        type="button"
        id={this.props.id}
        className={this.props.type}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        {this.props.loading && <CircularLoader className="loader-sm" />}
        {this.props.text}
      </button>
    );
  }
}

export default Button;
