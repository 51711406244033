import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './common/i18n/i18n';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/* eslint-disable */
ReactDOM.render(
  <Suspense fallback={null}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
