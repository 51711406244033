import * as React from 'react';
import './RedwoodCheckbox.css';

/*
Controlled React Input Checkbox
Props:
disabled: boolean
onChange: () => void
checked: boolean
children: string (label and other content)
 */

const RedwoodCheckbox = (props) => {
  const { children, checked, ...rest } = props;
  return (
    <>
      <label className="rw-checkbox">
        <input
          type="checkbox"
          checked={checked ? 'checked' : ''}
          {...rest}
        />
        <div className="checkmark" />
        <span>{children}</span>
      </label>
    </>
  );
};

export default RedwoodCheckbox;
