import * as _ from 'lodash';
import { removeSpace } from '../Helpers';

export default function CreateAccountDetailsBuilder() {
  const shortMonths = ['', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  let promoAmount;
  let currencyCode;
  let orgId;
  let operationItemDefinition;
  let individualCustomer;
  let promotionIntendedUse;
  let promotionGuestId;
  let intendedUse;
  let programType;
  let customerName;
  let alternateCustomerName;
  let cloudUICustomerName;
  let cloudUIAlternateCustomerName;
  let cloudAccountName;
  let dataRegion;
  let homeRegion;
  let country;
  let address1;
  let address2;
  let address3;
  let address4;
  let city;
  let directToPaid;
  let offerType;
  let postalCode;
  let state;
  let province;
  let county;
  let userName;
  let emailAddress;
  let firstName;
  let lastName;
  let departmentName;
  let jobTitle;
  let phoneNumber;
  let adminFirstName;
  let adminLastName;
  let adminEmail;
  let adminUserName;
  let validationSmsNumber;
  let validationCode;
  let validationSmsEmail;
  let miniBobEnabled;
  let adminPassword;
  let taxPayerId = '';
  let taxRegNumber;
  let taxCNPJ;
  let taxCPF;
  let billingTaxCNPJ = '';
  let noTaxReasonCode = '';
  let noTaxReasonCodeDetails = '';
  let cloudUISourceType;
  let cloudUIReferrer;
  let billingDepartmentName;
  let billingAddress1;
  let billingAddress2;
  let billingAddress3;
  let billingAddress4;
  let billingCity;
  let billingState;
  let billingCounty;
  let billingProvince;
  let billingPostalCode;
  let billingFirstName;
  let billingLastName;
  let billingPhoneNumber;
  let billingEmailAddress;
  let creditCardFirstName;
  let creditCardLastName;
  let creditCardLastFourDigits;
  let creditCardType;
  let paymentResponse;
  let creditCardPaymentToken = '';
  let creditCardExpirationMonth;
  let creditCardExpirationYear;
  let billingCountry;
  let apiaryId;
  let verificationToken;
  let paypalId;
  let paymentTransactionType;
  let captchaJwtToken;

  return {
    getCloudUISourceType() { return cloudUISourceType; },
    setPromoAmount(value) { promoAmount = value; return this; },
    setCurrencyCode(value) { currencyCode = value; return this; },
    setOrgId(value) { orgId = value; return this; },
    setOperationItemDefinition(value) { operationItemDefinition = value; return this; },
    setIndividualCustomer(value) { individualCustomer = value; return this; },
    setPromotionIntendedUse(value) { promotionIntendedUse = value; return this; },
    setPromotionGuestId(value) { promotionGuestId = value; return this; },
    setIntendedUse(value) { intendedUse = value; return this; },
    setProgramType(value) { programType = value; return this; },
    setCustomerName(value) { customerName = value; return this; },
    setAlternateCustomerName(value) { alternateCustomerName = value; return this; },
    setCloudUICustomerName(value) { cloudUICustomerName = value; return this; },
    setCloudUIAlternateCustomerName(value) { cloudUIAlternateCustomerName = value; return this; },
    setCloudAccountName(value) { cloudAccountName = value; return this; },
    setDataRegion(value) { dataRegion = value; return this; },
    setHomeRegion(value) { homeRegion = value; return this; },
    setCountry(value) { country = value; return this; },
    setAddress(value) {
      address1 = _.get(value, 'address1', '');
      address2 = _.get(value, 'address2', '');
      address3 = _.get(value, 'address3', '');
      address4 = _.get(value, 'address4', '');
      city = _.get(value, 'city', '');
      postalCode = _.get(value, 'postalcode', '');
      province = _.get(value, 'province', '');
      county = _.get(value, 'county', '');
      state = _.has(value, 'selectedState.value.id') ? _.split(value.selectedState.value.id, '-', 2)[1] : _.get(value, 'state', '');
      return this;
    },
    setAddress1(value) { address1 = value; return this; },
    setAddress2(value) { address2 = value; return this; },
    setAddress3(value) { address3 = value; return this; },
    setAddress4(value) { address4 = value; return this; },
    setCity(value) { city = value; return this; },
    setDirectToPaid(value) { directToPaid = value; return this; },
    setOfferType(value) { offerType = value; return this; },
    setPostalCode(value) { postalCode = value; return this; },
    setState(value) { state = value; return this; },
    setProvince(value) { province = value; return this; },
    setCounty(value) { county = value; return this; },
    setUserName(value) { userName = value; adminUserName = value; return this; },
    setEmailAddress(value) {
      emailAddress = value;
      adminEmail = value;
      validationSmsEmail = value;
      return this;
    },
    setFirstName(value) { firstName = value; adminFirstName = value; return this; },
    setLastName(value) { lastName = value; adminLastName = value; return this; },
    setDepartmentName(value) { departmentName = value; return this; },
    setJobTitle(value) { jobTitle = value; return this; },
    setPhoneNumber(value) { phoneNumber = value; validationSmsNumber = value; return this; },
    setAdminFirstName(value) { adminFirstName = value; return this; },
    setAdminLastName(value) { adminLastName = value; return this; },
    setAdminEmail(value) { adminEmail = value; return this; },
    setAdminUserName(value) { adminUserName = value; return this; },
    setValidationSmsNumber(value) { validationSmsNumber = value; return this; },
    setValidationCode(value) { validationCode = value; return this; },
    setValidationSmsEmail(value) { validationSmsEmail = value; return this; },
    setMiniBobEnabled(value) { miniBobEnabled = value; return this; },
    setAdminPassword(value) { adminPassword = value; return this; },
    setTaxPayerId(value) { taxPayerId = value; return this; },
    setTaxRegNumber(value) { taxRegNumber = value; return this; },
    setTaxCNPJ(value) { taxCNPJ = value; return this; },
    setTaxCPF(value) { taxCPF = value; return this; },
    setBillingTaxCNPJ(value) { billingTaxCNPJ = value; return this; },
    setNoTaxReasonCode(value) { noTaxReasonCode = value; return this; },
    setNoTaxReasonCodeDetails(value) { noTaxReasonCodeDetails = value; return this; },
    setCloudUISourceType(value) { cloudUISourceType = value; return this; },
    setCloudUIReferrer(value) { cloudUIReferrer = value; return this; },
    setBillingAddress(value) {
      billingDepartmentName = _.get(value, 'departmentName', '');
      billingAddress1 = _.get(value, 'line1', '').trim() || address1;
      billingAddress2 = _.get(value, 'line2', '').trim() || address2;
      billingAddress3 = _.get(value, 'line3', '').trim() || address3;
      billingAddress4 = _.get(value, 'line4', '').trim() || address4;
      billingCity = _.get(value, 'city', '').trim() || city;
      billingState = _.get(value, 'state', '').trim() || state;
      billingCounty = _.get(value, 'county', '').trim() || county;
      billingProvince = _.get(value, 'province', '').trim() || province;
      billingPostalCode = _.get(value, 'postalcode', '').trim() || postalCode;
      billingCountry = _.get(value, 'country', '');
      billingFirstName = _.get(value, 'firstName', '');
      billingLastName = _.get(value, 'lastName', '');
      billingPhoneNumber = _.get(value, 'phoneNumber', '');
      billingEmailAddress = _.get(value, 'emailAddress', '');
      return this;
    },
    setBillingDepartmentName(value) { billingDepartmentName = value; return this; },
    setBillingAddress1(value) { billingAddress1 = value; return this; },
    setBillingAddress2(value) { billingAddress2 = value; return this; },
    setBillingAddress3(value) { billingAddress3 = value; return this; },
    setBillingAddress4(value) { billingAddress4 = value; return this; },
    setBillingCity(value) { billingCity = value; return this; },
    setBillingState(value) { billingState = value; return this; },
    setBillingCounty(value) { billingCounty = value; return this; },
    setBillingProvince(value) { billingProvince = value; return this; },
    setBillingPostalCode(value) { billingPostalCode = value; return this; },
    setBillingCountry(value) { billingCountry = value; return this; },
    setBillingFirstName(value) { billingFirstName = value; return this; },
    setBillingLastName(value) { billingLastName = value; return this; },
    setBillingPhoneNumber(value) { billingPhoneNumber = value; return this; },
    setBillingEmailAddress(value) { billingEmailAddress = value; return this; },
    setPaymentOptions(value) {
      creditCardFirstName = _.get(value, 'creditCardFirstName', '');
      creditCardLastName = _.get(value, 'creditCardLastName', '');
      creditCardLastFourDigits = _.get(value, 'creditCardLastDigits', '');
      creditCardType = _.get(value, 'creditCardType', '');
      creditCardType = creditCardType === 'DINER' ? 'DINERS' : creditCardType;
      creditCardExpirationMonth = _.has(value, 'creditCardExpirationDate') ? shortMonths[Number(_.split(value.creditCardExpirationDate, '-', 2)[1])] : '';
      creditCardExpirationYear = _.has(value, 'creditCardExpirationDate') ? _.split(value.creditCardExpirationDate, '-', 2)[0] : '';
      paypalId = _.get(value, 'paypalId', '');
      paymentTransactionType = _.get(value, 'paymentMethod', '');
      return this;
    },
    setCreditCardFirstName(value) { creditCardFirstName = value; return this; },
    setCreditCardLastName(value) { creditCardLastName = value; return this; },
    setCreditCardLastFourDigits(value) { creditCardLastFourDigits = value; return this; },
    setCreditCardType(value) { creditCardType = value; return this; },
    setCreditCardPaymentToken(value) { creditCardPaymentToken = value; return this; },
    setCreditCardExpirationMonth(value) { creditCardExpirationMonth = value; return this; },
    setCreditCardExpirationYear(value) { creditCardExpirationYear = value; return this; },
    setApiaryId(value) { apiaryId = value; return this; },
    setVerificationToken(value) { verificationToken = value; return this; },
    setPaypalId(value) { paypalId = value; return this; },
    setPaymentTransactionType(value) { paymentTransactionType = value; return this; },
    setPaymentResponse(value) { paymentResponse = value; return this; },
    setCaptchaJwtToken(value) { captchaJwtToken = value; return this; },
    build() {
      return removeSpace({
        promoAmount,
        currencyCode,
        orgId,
        operationItemDefinition,
        individualCustomer,
        promotionIntendedUse,
        promotionGuestId,
        intendedUse,
        programType,
        customerName,
        alternateCustomerName,
        cloudUICustomerName,
        cloudUIAlternateCustomerName,
        cloudAccountName,
        dataRegion,
        homeRegion,
        country,
        address1,
        address2,
        address3,
        address4,
        city,
        directToPaid,
        offerType,
        postalCode,
        state,
        province,
        county,
        userName,
        emailAddress,
        firstName,
        lastName,
        departmentName,
        jobTitle,
        phoneNumber,
        adminFirstName,
        adminLastName,
        adminEmail,
        adminUserName,
        validationSmsNumber,
        validationCode,
        validationSmsEmail,
        miniBobEnabled,
        adminPassword,
        taxPayerId,
        taxRegNumber,
        taxCNPJ,
        taxCPF,
        billingTaxCNPJ,
        noTaxReasonCode,
        noTaxReasonCodeDetails,
        cloudUISourceType,
        cloudUIReferrer,
        billingDepartmentName,
        billingAddress1,
        billingAddress2,
        billingAddress3,
        billingAddress4,
        billingCity,
        billingState,
        billingCounty,
        billingProvince,
        billingPostalCode,
        billingFirstName,
        billingLastName,
        billingPhoneNumber,
        billingEmailAddress,
        creditCardFirstName,
        creditCardLastName,
        creditCardLastFourDigits,
        creditCardType,
        paymentResponse,
        creditCardPaymentToken,
        creditCardExpirationMonth,
        creditCardExpirationYear,
        billingCountry,
        apiaryId,
        verificationToken,
        paypalId,
        paymentTransactionType,
        captchaJwtToken,
      });
    },
  };
}
