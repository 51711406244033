/*
eslint-disable no-nested-ternary,no-unused-vars,no-shadow,no-console,
react/prop-types,import/no-named-as-default,import/no-named-as-default-member,max-len
*/
import * as React from 'react';
import * as _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { Context } from '../../state/Store';
import Button from '../../common/Button/Button';
import RedwoodCheckbox from '../../common/Checkbox/RedwoodCheckbox';
import './AgreementComponent.css';
import RedwoodModal from '../../common/Modal/RedwoodModal';
import SendAction from '../../CourierService/SendAction';
import SendLogs from '../../CourierService/SendLogs';
import OrgNameList from '../../data/orgNames.json';
import SendMetrics from '../../CourierService/SendMetrics';
import { randomPaddedIntString, removeSpace, getFeatureFlag } from '../Helpers';
import { navTrack } from '../../config/adobeAnalytics';
import { fetchCreateAccount } from '../../CourierService/FetchService';
import CreateAccountDetailsBuilder from './CreateAccountDetailsBuilder';

const AgreementComponent = (props) => {
  const [state, dispatch] = React.useContext(Context);
  const [show, setShow] = React.useState(false);
  const [expired, setExpired] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const {
    createAccountStatus, updateCreateAccountStatus, checkbox, updateCheckbox, toggleCallback, programType,
  } = props;
  const [createAccountRetries, setCreateAccountRetries] = React.useState(0);
  const { featureFlags } = state;

  const setApiError = () => {
    dispatch({
      type: 'UPDATE_APIERROR',
      payload: true,
    });
  };

  const setApi403Error = () => {
    dispatch({
      type: 'UPDATE_API403ERROR',
      payload: true,
    });
  };

  const setAccountNotUnique = () => {
    dispatch({
      type: 'SHOW_ACCOUNT_NOT_UNIQUE',
      payload: true,
    });
  };

  const sourceType = () => {
    let sourceType = '';
    if (state.sourceType !== '') {
      sourceType = state.sourceType;
    }
    if (!_.isEmpty(sourceType) && !_.isEmpty(state.selectedPromo.campaignId)) {
      sourceType = `${state.sourceType}-campaignId-${state.selectedPromo.campaignId}`;
    } else if (!_.isEmpty(state.selectedPromo.campaignId)) {
      sourceType = `campaignId-${state.selectedPromo.campaignId}`;
    }
    return sourceType;
  };

  const getPromoAmount = () => {
    const promoTypes = ['Standard', 'Educator', 'Developer', 'Student', 'OCI-Arm', 'EVENT-TRIALS', 'RESEARCH-STARTER'];

    if (_.isEmpty(state.selectedPromo)) {
      const type = _.get(state.defaultPromo, 'programType', 'Standard');

      return state.countryInfo.promos[type];
    }
    if (_.findIndex(promoTypes, (o) => o === state.selectedPromo.programType) !== -1) {
      return state.countryInfo.promos[state.selectedPromo.programType];
    }
    if (_.findIndex(promoTypes, (o) => o === state.selectedPromo.type) !== -1) {
      return state.countryInfo.promos[state.selectedPromo.type];
    }
    return state.selectedPromo.promoAmount;
  };

  const randomNumber = randomPaddedIntString(0, 999999, '0', 6);
  const name = `${state.username}-${state.companyName}${state.companyName === '' ? '' : '-'}${randomNumber}`; // emailWithRandomNumber
  let organization = state.companyName === '' ? name : state.companyName; // orgName
  organization = organization.replace(/\s+/g, ' ');

  const getCustomerName = () => {
    let customerName = (state.intendedUse === 'COMPANY'
      || _.toUpper(state.selectedPromo.intendedUse) === 'ENTERPRISE'
      || _.get(state.selectedPromo, 'programType', '') === 'SE-TRIAL')
      ? `${state.username}-${state.companyName}-${randomNumber}`
      : `${state.username}-${randomNumber}`;

    if (_.get(state.selectedPromo, 'customerName', '') !== '') {
      customerName = state.selectedPromo.customerName;
    }

    return customerName;
  };

  const getAltCustomerName = () => {
    if (state.intendedUse === 'COMPANY'
     || _.toUpper(state.selectedPromo.intendedUse) === 'ENTERPRISE'
     || _.get(state.selectedPromo, 'programType', '') === 'SE-TRIAL') {
      if (_.get(state, 'altEnglishCompanyName', '') !== '') {
        return `${state.altEnglishCompanyName}${randomNumber}`;
      }
      const company = state.companyName.replace(/[^a-zA-Z0-9 ]+/g, '');

      if (company !== '') {
        return `${company}${randomNumber}`;
      }
    }

    if (_.get(state, 'alternateName', '') !== '') {
      return `${state.alternateName}${randomNumber}`;
    }

    const name = `${state.firstName} ${state.lastName}`.replace(/[^a-zA-Z0-9 ]+/g, '');

    if (name !== ' ' && name !== '') {
      return `${name}${randomNumber}`;
    }

    return '';
  };

  const getCloudUICustomerName = () => {
    let cloudUICustomerName = '';
    if (_.get(state.selectedPromo, 'customerName', '') !== '') {
      cloudUICustomerName = state.selectedPromo.customerName;
    } else if (state.intendedUse === 'PERSONAL') {
      cloudUICustomerName = state.accountName;
    } else {
      cloudUICustomerName = `${state.username}-${state.companyName}-${randomNumber}`;
    }
    return cloudUICustomerName;
  };

  const getCloudUIAltCustomerName = () => {
    let cloudUIAltCustomerName = '';
    if (_.get(state.selectedPromo, 'customerName', '') !== '') {
      cloudUIAltCustomerName = state.selectedPromo.customerName;
    } else {
      cloudUIAltCustomerName = state.accountName;
    }
    return cloudUIAltCustomerName;
  };

  const setOperationItemDefinition = () => {
    let operationId = _.isEmpty(state.selectedPromo)
      ? state.defaultPromo.id : state.selectedPromo.id;

    if (_.get(state.paymentOptions, 'paymentMethod', '') === 'PAYPAL'
        && (state.country === 'MX' || state.country === 'NZ')
        && getFeatureFlag('enable_paypal', featureFlags)
    ) {
      operationId = 'OIH-PROMOCM-PAYPAL-CREATE_V2';
    }
    return operationId;
  };

  const toggleModal = () => {
    setShow(!show);
    SendAction({ action: 'POST createAccount API failed', value: 'Error Dialog Pop-up displayed', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  const closeModal = () => {
    setShow(!show);
    window.location.reload();
    SendAction({ action: 'POST createAccount API failed', value: 'Reload signup page', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  const closeExpiredModal = () => {
    setExpired(!expired);
    window.location.reload();
    SendAction({ action: 'POST createAccount Token Expired', value: 'Reload signup page', userSession: state.user_session });
    toggleCallback();
  };

  const diffSeconds = (d1, d2) => Math.round((d2.getTime() - d1.getTime()) / 1000);

  const tokenIsExpired = () => {
    let result = false;
    if (!_.isEmpty(state.promoId)) return result;
    const token = state.emailToken;
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const endTime = new Date(payload.exp);
      const limit = new Date();
      result = diffSeconds(limit, endTime) > -15;
    } catch (e) {
      SendLogs({ severity: '40000', message: `Parsing token exception: Token: ${state.emailToken}`, userSession: state.user_session });
    }
    return result;
  };

  const getAccountDetailsBuilt = () => new CreateAccountDetailsBuilder()
    .setPromoAmount(getPromoAmount())
    .setCurrencyCode(state.countryInfo.currency.code)
    .setOrgId(state.orgId)
    .setOperationItemDefinition(setOperationItemDefinition())
    .setIndividualCustomer(state.companyName === '' ? 'Y' : 'N')
    .setPromotionIntendedUse(_.toUpper(_.get(state.selectedPromo, 'intendedUse', (state.companyName !== '' ? 'ENTERPRISE' : '') || 'PERSONAL')))
    .setPromotionGuestId(_.get(state.selectedPromo, 'promoId', ''))
    .setIntendedUse(state.companyName === '' ? 'PERSONAL' : 'COMPANY')
    .setProgramType(_.get(state.selectedPromo, 'programType', '').trim() || programType)
    .setCustomerName(getCustomerName())
    .setAlternateCustomerName(getAltCustomerName())
    .setCloudUICustomerName(getCloudUICustomerName())
    .setCloudUIAlternateCustomerName(getCloudUIAltCustomerName())
    .setCloudAccountName(state.accountName)
    .setDataRegion(state.homeRegion.group)
    .setHomeRegion(state.homeRegion.value.id)
    .setCountry(state.country)
    .setAddress(state.address)
    .setBillingAddress(state.billingAddress) // always set after address
    .setDirectToPaid(state.isPaid)
    .setOfferType(getFeatureFlag('enable_paid', featureFlags) && state.isPaid === true ? '' : 'STANDARD')
    .setUserName(state.username)
    .setEmailAddress(state.username)
    .setFirstName(state.firstName)
    .setLastName(state.lastName)
    .setDepartmentName(state.departmentName)
    .setJobTitle(state.jobTitle)
    .setPhoneNumber(state.phoneNumber)
    .setValidationCode(state.verificationCode)
    .setMiniBobEnabled(state.miniBobEnabled)
    .setAdminPassword(state.password)
    .setTaxRegNumber(state.isPaid ? (state.country === 'BR' ? (state.paidCPF || state.paidCNPJ) : state.taxRegNumber) : '')
    .setTaxPayerId(state.taxPayerId)
    .setTaxCNPJ(state.country === 'BR' ? (state.isPaid ? state.paidCNPJ : state.taxPayerId) : '')
    .setTaxCPF(state.country === 'BR' ? (state.isPaid ? state.paidCPF : state.taxPayerId) : '')
    .setNoTaxReasonCode(state.taxRegNumber === '' ? _.get(state.taxInfoStatus, 'value', '') : '')
    .setNoTaxReasonCodeDetails(state.taxRegNumber === '' ? _.get(state.taxInfoStatus, 'value', '') : '')
    .setCloudUISourceType(sourceType())
    .setCloudUIReferrer(state.referrer)
    .setPaymentResponse(state.paymentResponse)
    .setApiaryId(_.get(state, 'apiaryId', ''))
    .setVerificationToken(_.get(state, 'emailToken', ''))
    .setPaymentOptions(state.paymentOptions)
    .setCaptchaJwtToken(state.captchaJwtToken)
    .build();

  React.useEffect(() => {
    if (createAccountStatus.createAccountApi) {
      const createAccountDetailsCopy = getAccountDetailsBuilt();
      setLoading(true);
      if (tokenIsExpired()) {
        setLoading(false);
        setExpired(true);
        SendLogs({ severity: '20000', message: `POST createAccount Token Expired: Token: ${state.emailToken}`, userSession: state.user_session });
      } else {
        const start = new Date();
        fetchCreateAccount(encodeURIComponent(state.username),
          JSON.stringify(createAccountDetailsCopy))
          .then(async (res) => {
            SendMetrics({ name: 'CreateAccount.Latency', value: new Date() - start, userSession: state.user_session });

            setLoading(false);
            const result = await res.json();
            if (res.status === 201) {
              updateCheckbox(false);
              const status = _.isEmpty(result.status) ? '' : result.status;
              if (status === 'REVIEW') {
                SendLogs({ severity: '20000', message: `Create Account Status: Review, EmailID:${state.username}`, userSession: state.user_session });
                SendMetrics({ name: 'CreateAccount.Count.Metrics.Review', value: 1, userSession: state.user_session });
                navTrack('oci-signup', state.language, 's2-received-fraud-status', 'REVIEW-status-flagged-by-OCIAF');
              } else {
                SendLogs({ severity: '20000', message: `Create Account Status: Accepted, EmailID:${state.username}`, userSession: state.user_session });
                if (state.operationId === 'OIH-PROMOCM-UNIVERSAL-CREDITS-VIRTUAL-STARTUP-CREATE' && _.isEmpty(state.promoId)) {
                  navTrack('oci-signup', state.language, 'signup-account-created', 'Internal-oracle-employees');
                  SendLogs({ severity: '20000', message: `signup-account-created-INTERNAL-oracle-employees, EmailID:${state.username}`, userSession: state.user_session });
                } else {
                  navTrack('oci-signup', state.language, 'signup-account-created', 'External-customers');
                  SendLogs({ severity: '20000', message: `signup-account-created-EXTERNAL-customers, EmailID:${state.username}`, userSession: state.user_session });
                }
                SendMetrics({ name: 'CreateAccount.Count.Metrics.Accept', value: 1, userSession: state.user_session });
              }
              dispatch({ type: 'OPERATION_ID', payload: [result.operationId, status] });
              SendLogs({ severity: '20000', message: `POST createAccount API successful: ${JSON.stringify(result)}EmailID:${state.username}`, userSession: state.user_session });
              const cloudUISourceType = _.get(createAccountDetailsCopy, 'cloudUISourceType', '');
              if (cloudUISourceType.length > 0) {
                SendLogs({ severity: '20000', message: `POST createAccount SourceType: ${cloudUISourceType} EmailID:${state.username}`, userSession: state.user_session });
              }
            }
            if (res.status === 400) {
              if (result.paymentGatewayAuthReasonCode) {
                navTrack('oci-signup', state.language, 's2-received-fraud-status', 'REJECT-status-by-cybersource');
                dispatch({ type: 'PAYMENT_ERROR', payload: result });
                updateCheckbox(true);
                updateCreateAccountStatus(!(createAccountStatus.checked), false);
                SendLogs({ severity: '40000', message: `Fraud Response: ${result.paymentGatewayAuthReasonCode}EmailID:${state.username}`, userSession: state.user_session });
                SendMetrics({ name: 'CreateAccount.Count.Metrics.Reject', value: 1, userSession: state.user_session });
              } else {
                toggleModal();
                SendLogs({ severity: '40000', message: `POST createAccount API error - Not Found or Auth: ${JSON.stringify(result)}EmailID:${state.username}`, userSession: state.user_session });
                SendMetrics({ name: 'CreateAccount.Count.Metrics.Error', value: 1, userSession: state.user_session });
              }
            } else if (res.status === 401) {
              setExpired(true);
              updateCreateAccountStatus(!createAccountStatus.checked, false);
              navTrack('oci-signup', state.language, 's1-entered-email', 'existing-email');
              SendLogs({ severity: '40000', message: `Create Account API Error - Invalid token: ${JSON.stringify(result)} EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.Unauthorized', value: 1, userSession: state.user_session });
            } else if (res.status === 403) {
              const result = await res.json();
              if (_.startsWith(result.message, 'Too many requests')) {
                toggleModal();
                updateCreateAccountStatus(!createAccountStatus.checked, false);
                navTrack('oci-signup', state.language, 's1-entered-email', 'invalid-value-in-payload');
                SendLogs({ severity: '40000', message: `Too many requests: ${JSON.stringify(result)}EmailID:${state.username}`, userSession: state.user_session });
                SendMetrics({ name: 'CreateAccount.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
                setApi403Error();
              }
            } else if (res.status === 406) {
              toggleModal();
              updateCreateAccountStatus(!createAccountStatus.checked, false);
              navTrack('oci-signup', state.language, 's1-entered-email', 'invalid-value-in-payload');
              SendLogs({ severity: '40000', message: `Create Account API Error - Invalid value in payload: ${JSON.stringify(result)}EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.InvalidValue', value: 1, userSession: state.user_session });
            } else if (res.status === 412) {
              toggleModal();
              updateCreateAccountStatus(!createAccountStatus.checked, false);
              navTrack('oci-signup', state.language, 's1-entered-email', 'existing-email');
              SendLogs({ severity: '40000', message: `Create Account API Error - MissingPaymentOptions: ${JSON.stringify(result)} EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.MissingPaymentOptions', value: 1, userSession: state.user_session });
            } else if (res.status === 409) {
              updateCreateAccountStatus(!createAccountStatus.checked, false);
              navTrack('oci-signup', state.language, 's1-entered-email', 'existing-email');
              setAccountNotUnique();
              SendMetrics({ name: 'CreateAccount.Count.Metrics.Duplicate', value: 1, userSession: state.user_session });
              SendLogs({ severity: '40000', message: `Create Account API Error - Duplicate: ${JSON.stringify(result)} EmailID:${state.username}`, userSession: state.user_session });
            } else if (res.status >= 500) {
              SendLogs({ severity: '40000', message: `Create Account API Error: ${JSON.stringify(result)}EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.Error', value: 1, userSession: state.user_session });
              if (createAccountRetries === 3) {
                setApiError();
              } else {
                setCreateAccountRetries(createAccountRetries + 1);
              }
            }
            SendMetrics({ name: 'CreateAccount.Count.Metrics', value: 1, userSession: state.user_session });
            SendLogs({ severity: '20000', message: `Create Account API Status Code:${JSON.stringify(result)}  EmailID:${state.username}`, userSession: state.user_session });
          }).catch((error) => {
            SendMetrics({ name: 'CreateAccount.Latency', value: new Date() - start, userSession: state.user_session });
            if (error.message === 'Failed to fetch') {
              SendLogs({ severity: '40000', message: `POST createAccount API error - CORS Error: ${error.message} EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.Fault', value: 1, userSession: state.user_session });
            } else if (error.message.includes('NetworkError')) {
              SendLogs({ severity: '40000', message: `POST createAccount API error - Network Error: ${error.message} EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.Fault', value: 1, userSession: state.user_session });
            } else if (error.message === 'Load failed') {
              SendLogs({ severity: '40000', message: `POST createAccount API error - Load failed: ${error.message} EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.Fault', value: 1, userSession: state.user_session });
            } else {
              SendLogs({ severity: '40000', message: `POST createAccount API error - Internal Error: ${error.message} EmailID:${state.username}`, userSession: state.user_session });
              SendMetrics({ name: 'CreateAccount.Count.Metrics.Fail', value: 1, userSession: state.user_session });
            }
            SendMetrics({ name: 'CreateAccount.Count.Metrics', value: 1, userSession: state.user_session });
          });
      }
    }
  }, [createAccountStatus.createAccountApi, createAccountRetries]);

  const doClick = () => {
    if (!_.isEmpty(state.paymentError) && state.paymentError.paymentGatewayAuthReasonCode === '481') {
      updateCreateAccountStatus(!createAccountStatus.checked, false);
    }

    if (createAccountStatus.checked) {
      updateCreateAccountStatus(createAccountStatus.checked, true);
    }

    SendAction({ action: 'User clicked Start my free trial button', value: 'POST createAccount API called', userSession: state.user_session });
    SendLogs({ severity: '20000', message: `User clicked Start my free trial button, EmailID:${state.username}`, userSession: state.user_session });
    navTrack('oci-signup', state.language, 's2-clicked-start-my-free-trial-button', 'finished-signup-process');
  };

  const translatedOrgName = OrgNameList[state.countryInfo.tc.OrgName];
  const translatedStartTrial = getFeatureFlag('enable_taxfields', featureFlags) ? t('agreement.createAccount', 'Create account') : t('agreement.startMyFreeTrial', 'Start my free trial');

  const agreementCheckbox = () => {
    const index = _.findIndex(state.featureFlags, (o) => (_.isEmpty(state.featureFlags) ? false : o.featureName === 'sms'));

    const smsFeatureFlag = index !== -1 ? state.featureFlags[index].isActive : false;

    const isMobileNumberInvalid = _.isEmpty(state.phoneNumber) ? true : !state.validPhoneNumber;

    // Below cases when sms verification is Active and inActive:
    // 1. Standard Promotion or both creditCardRequired and validationCodeRequired
    // 2. ValidationCodeRequired and creditCard is not required
    // 3. creditCardRequired and validationCode is not required
    // 4. validationcode not required and creditcard is not required

    if (_.isEmpty(state.selectedPromo)
        || (state.selectedPromo.creditCardRequired && state.selectedPromo.validationCodeRequired)) {
      if (smsFeatureFlag) {
        return state.addressValidation
          || state.mobileValidation
          || _.isEmpty(state.paymentOptions)
          || checkbox;
      }
      return state.addressValidation
        || isMobileNumberInvalid
        || _.isEmpty(state.paymentOptions)
        || checkbox;
    } if (state.selectedPromo.validationCodeRequired && !state.creditCardRequired) {
      if (smsFeatureFlag) {
        return state.addressValidation || state.mobileValidation;
      }
    } else if (state.selectedPromo.creditCardRequired && !state.validationCodeRequired) {
      return state.addressValidation || isMobileNumberInvalid || _.isEmpty(state.paymentOptions);
    } else {
      return state.addressValidation || isMobileNumberInvalid;
    }
    return state.addressValidation || isMobileNumberInvalid;
  };

  if (!_.isEmpty(state.paymentError)) {
    if (state.paymentError.paymentGatewayAuthReasonCode === '481') {
      return (null);
    }
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="agreementComponent">
      <h2 className="title6">
        {t('agreement.title')}
      </h2>
      <RedwoodCheckbox
        checked={createAccountStatus.checked}
        disabled={agreementCheckbox()}
        onChange={() => {
          updateCreateAccountStatus(!(createAccountStatus.checked), false);
          navTrack('oci-signup', state.language, 's2-checked-TnC-checkbox', 'Agreed-to-TnC');
        }}
      >
        {(!getFeatureFlag('enable_taxfields', featureFlags) || state.isPaid === false) && (
        <Trans i18nKey="agreement.termsAndConditions">
          By clicking
          <b>
            {{ translatedStartTrial }}
          </b>
          , I agree to the terms and conditions of the
          <a href={state.countryInfo.tc.URL} target="_blank" rel="noopener noreferrer">
            Oracle Cloud Services Agreement V040119 for
            {{ translatedOrgName }}
          </a>
          , (also available
          <a href="https://www.oracle.com/corporate/contracts/" target="_blank" rel="noopener noreferrer">here</a>
          )
          and this order including Service Description for Free Oracle Cloud Promotion
          Universal Credits - Part Number
          {_.isEmpty(state.selectedPromo) ? state.defaultPromo.partNumber : state.selectedPromo.partNumber}
          .
        </Trans>
        )}
        {state.isPaid === true && getFeatureFlag('enable_taxfields', featureFlags) && (
        <Trans i18nKey="agreement.latestTermsAndConditions">
          I agree to the terms and conditions of the
          <a href={state.countryInfo.tc.URL} target="_blank" rel="noopener noreferrer">
            Oracle Cloud Services Agreement for
            {{ translatedOrgName }}
          </a>
          , (
          <a href="https://www.oracle.com/corporate/contracts/cloud-services/contracts.html?gclid=EAIaIQobChMI35jW25bT-AIVLsLCBB3hGgorEAAYASAAEgLXJvD_BwE&gclsrc=aw.ds&source=:ad:pas:go:dg:a_nas:71700000084178434-58700007116661812-p63986697426:RC_WWMK220512P00032C0001:#online" target="_blank" rel="noopener noreferrer">here</a>
          )
          and this order including
          <a href="https://www.oracle.com/a/ocom/docs/paas-iaas-universal-credits-3940775.pdf" target="_blank" rel="noopener noreferrer">
            service description
          </a>
          for B88206
          - Oracle PaaS and IaaSs Universal Credits.
        </Trans>
        )}

      </RedwoodCheckbox>

      <RedwoodModal onClose={(e) => closeModal()} show={show} title={t('agreement.errorTitle')} buttonName={t('agreement.tryMeButton')}>
        <p>{t('agreement.errorDialog1')}</p>
        <p>
          <Trans i18nKey="agreement.errorDialog2">
            <a href="https://signup.cloud.oracle.com/" target="_blank" rel="noopener noreferrer">Refresh</a>
            {' '}
            and start the sign-up process over, this problem may be temporary.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="agreement.errorDialog3">
            Otherwise, if the problem persists you can
            <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target="_blank" rel="noopener noreferrer">contact chat support</a>
            {' '}
            and we will make sure to get you through the sign-up process.
          </Trans>
        </p>
      </RedwoodModal>

      <RedwoodModal onClose={(e) => closeExpiredModal()} title={t('emailVerification.failureTitle')} show={expired} buttonName={t('signup.retry', 'Retry')}>
        <p>{t('emailVerification.failureText')}</p>
        <p>{t('emailVerification.failureText1')}</p>
      </RedwoodModal>

      <Button type="solid" id="startMyTrialBtn" disabled={!createAccountStatus.checked || state.accountNotUnique} onClick={(e) => doClick(e)} text={translatedStartTrial} loading={loading} />
    </div>

  );
};

export default AgreementComponent;
