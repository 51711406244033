/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import './PromoContainer.css';
import * as _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Context } from '../../state/Store';
import { getFeatureFlag } from '../Helpers';
import promoAmountData from '../../data/PromoAmount.json';

const PromoContainer = (props) => {
  const { t } = useTranslation();
  const [state] = React.useContext(Context);
  const promoTypes = ['Standard', 'Educator', 'Developer', 'Student', 'OCI-Arm', 'EVENT-TRIALS', 'RESEARCH-STARTER'];
  const { featureFlags } = state;

  // eslint-disable-next-line react/prop-types
  const { programType } = props;
  const amountResearchStarter = state.defaultPromo.programType === 'RESEARCH-STARTER' && state.countryInfo !== undefined && state.countryInfo.promos !== undefined ? state.countryInfo.promos['RESEARCH-STARTER'] : '1000';
  let promo = state.countryInfo !== undefined && state.countryInfo.promos !== undefined ? state.countryInfo.promos.Standard : '300';
  const currency = state.countryInfo !== undefined && state.countryInfo.currency !== undefined ? state.countryInfo.currency.symbol : '$';

  // eslint-disable-next-line consistent-return,no-shadow
  const currencyChangeForSelectedPromo = (promo) => {
    if (state.countryInfo !== undefined) {
      if (_.findIndex(promoTypes, (o) => o === promo.programType) !== -1) {
        return state.countryInfo.promos[promo.programType];
      } if (_.findIndex(promoTypes, (o) => o === promo.type) !== -1) {
        return state.countryInfo.promos[promo.type];
      }
      return promo.promoAmount;
    }
  };

  const promoAmount = currencyChangeForSelectedPromo(state.selectedPromo);

  if (state.country === 'MX' && getFeatureFlag('enable_paypal', featureFlags) && _.isEmpty(state.selectedPromo) && !_.isEmpty(state.emailToken) && state.defaultPromo.type === 'Standard') {
    state.countryInfo.currency.code = 'MXN';
    state.countryInfo.currency.symbol = 'Mex$';
    promo = '5850';
  }
  if (getFeatureFlag('enable_local_payment', featureFlags)) {
    if (state.country === 'CO') {
      state.countryInfo.currency.code = 'COP';
      state.countryInfo.currency.symbol = 'COL$';
      state.countryInfo.currency.local = true;
      state.countryInfo.promos = promoAmountData.currencies[state.countryInfo.currency.code].promos;
      state.countryInfo.tc.OrgName = 'coOrgName';
      state.countryInfo.tc.URL = 'https://www.oracle.com/goto/oraclecsa-co';
    }
    if (state.country === 'CL') {
      state.countryInfo.currency.code = 'CLP';
      state.countryInfo.currency.symbol = 'CLP$';
      state.countryInfo.currency.local = true;
      state.countryInfo.promos = promoAmountData.currencies[state.countryInfo.currency.code].promos;
      state.countryInfo.tc.OrgName = 'clOrgName';
      state.countryInfo.tc.URL = 'https://www.oracle.com/goto/oraclecsa-cl';
    }
  }
  const amtOrPromo = (type) => {
    if (type === 'RESEARCH-STARTER') {
      return amountResearchStarter;
    }
    return promo;
  };
  const displayName = _.isEmpty(state.selectedPromo.campaignDisplayName)
    ? state.selectedPromo.type : state.selectedPromo.campaignDisplayName;
  const promoDays = state.selectedPromo.promoDurationInDays;

  const getPromoContainer = () => {
    if (!_.isEmpty(state.selectedPromo)) {
      if (state.oracleEmployee) {
        return (
          <>
            <h2>{t('promo.specialOfferTitle')}</h2>
            <p>
              {t('promo.welcome')}
              {' '}
              <strong>{state.username}</strong>
            </p>
            <p>
              <Trans i18nKey="promo.oracleEmployeeFreeTierPromoTitle">
                You have selected to sign up for:
                {/* eslint-disable-next-line max-len */}
                <strong>Oracle Cloud for Employees with access to Always Free resources and services</strong>
              </Trans>
            </p>
          </>
        );
      }
      return (
        <>
          <h2>{t('promo.specialOfferTitle')}</h2>
          <p>
            {t('promo.welcome')}
            {' '}
            <strong>{state.username}</strong>
          </p>
          <p>
            <Trans i18nKey="promo.selectedPromo">
              You have selected to sign up for:
              <strong>
                Oracle Cloud For
                {{ displayName }}
                with
                {currency}
                {promoAmount}
                credits for
                {promoDays}
                days
              </strong>
            </Trans>
          </p>
        </>
      );
    } if (_.toUpper(programType) === 'OCI-ARM') {
      return (
        <>
          <strong><h2>{t('promo.promoArmociTitle', 'OCI Arm Accelerator')}</h2></strong>
          {t('promo.promoArmOciDesc')}
          <ul>
            <li>{t('promo.promoArmOci1', 'Ampere A1 Compute')}</li>
            <li>{t('promo.promoArmOci2', 'Block, Object and Archive Storage')}</li>
            <li>{t('promo.promoArmOci3', 'Networking')}</li>
            <li>{t('promo.promoArmOci4', 'Plus other OCI services')}</li>
          </ul>
          <br />
          {' '}
          <br />
          <div>
            <Trans i18nKey="promo.promoArmOciDesc1">
              Not OCI Arm Accelerator?
              <a href={window.location.pathname}>Click here</a>
            </Trans>
          </div>
        </>
      );
    } if (_.toUpper(programType) === 'RESEARCH-STARTER') {
      return (
        <>
          <strong>
            <h2>
              {t('promo.ResearchStarterTitle', 'Researchers get')}
              ...
            </h2>
          </strong>
          <Trans i18nKey="promo.promoResearchStarterDesc">
            {currency}
            {amtOrPromo(state.defaultPromo.programType)}
            {' '}
            {/* eslint-disable-next-line max-len */}
            for cloud apps, storage and services. You can host, process, analyze, share, and store research data on Oracle Cloud.
          </Trans>
          <br />
          {' '}
          <br />
          <div>
            <Trans i18nKey="promo.promoResearchStarterDesc1">
              Interested in even more support for your research?
              <a href="https://www.oracle.com/oracle-for-research/apply.html">Click here</a>
            </Trans>
          </div>
        </>
      );
    } if (getFeatureFlag('enable_heatwave', featureFlags) && _.toUpper(programType) === 'MYSQLDSAWS') {
      return (
        <>
          <strong><h2>{t('promo.promoHeatwaveTitle', 'HeatWave on AWS')}</h2></strong>
          {t('promo.promoHeatwaveDesc')}
          <ul>
            <li>{t('promo.promoHeatwave1', 'Accelerate MySQL query performance, get real-time analytics without ETL, and enhance security using HeatWave MySQL')}</li>
            <li>{t('promo.promoHeatwave2', 'Query data in object storage with record performance using HeatWave Lakehouse')}</li>
            <li>{t('promo.promoHeatwave3', 'Automate the pipeline to build, train, and explain machine learning models with HeatWave AutoML')}</li>
            <li>{t('promo.promoHeatwave4', 'Improve performance and price-performance at scale using HeatWave Autopilot')}</li>
          </ul>
          <br />
          {' '}
          <br />
          <div>
            <Trans i18nKey="promo.promoHeatwaveDesc1">
              Not here for MySQL HeatWave on AWS?
              <a href={window.location.pathname}>Click here</a>
            </Trans>
          </div>
        </>
      );
    } if (_.toUpper(programType) === 'MYSQLHEATWAVESIGNUP') {
      return (
        <>
          <strong><h2>{t('promo.promoHeatwaveCopyTitle', 'Sign up to access HeatWave')}</h2></strong>
          <Trans i18nKey="promo.promoHeatwaveCopyDesc">
            Get
            {currency}
            {amtOrPromo(state.defaultPromo.programType)}
            {' '}
            of credits for 30 days to:
          </Trans>
          <ul>
            <li>{t('promo.promoHeatWaveCopy1', 'Accelerate MySQL query performance, get real-time analytics without ETL, and enhance security using HeatWave MySQL')}</li>
            <li>{t('promo.promoHeatWaveCopy2', 'Take advantage of integrated and automated generative AI with HeatWave GenAI')}</li>
            <li>{t('promo.promoHeatWaveCopy3', 'Query data in object storage with record performance using HeatWave Lakehouse')}</li>
            <li>{t('promo.promoHeatWaveCopy4', 'Automate the pipeline to build, train, and explain machine learning models with HeatWave AutoML')}</li>
            <li>{t('promo.promoHeatWaveCopy5', 'Access a wide range of other Oracle Cloud services including Object Storage, Container Engine for Kubernetes, and Analytics Cloud')}</li>
          </ul>
          <br />
          {' '}
          <br />
        </>
      );
    }

    return (
      <>
        <h2>{t('promo.promo1Title', 'Get started with…')}</h2>
        <strong>{t('promo.promoApplicationTitle', 'Always-Free access to essential services including:')}</strong>
        <ul>
          <li>{t('promo.promoApplication1', 'Autonomous Database')}</li>
          <li>{t('promo.promoApplication2', 'Object storage')}</li>
        </ul>

        <strong>
          <Trans i18nKey="promo.promoServiceTitle1">
            Plus,
            {currency}
            {amtOrPromo(state.defaultPromo.programType)}
            {' '}
            of credits for 30 days to use on even more services:
          </Trans>
        </strong>
        <ul>
          <li>{t('promo.promoService1', 'Container Engine for Kubernetes')}</li>
          <li>{t('promo.promoService2', 'Analytics Cloud')}</li>
          <li>{t('promo.promoService3', 'Data Integration')}</li>
        </ul>
      </>
    );
  };

  return (
    <>
      {!state.nextStep && (
        <div className="promoContainer">
          {getPromoContainer()}
        </div>
      )}
    </>
  );
};

export default PromoContainer;
