/* eslint-disable no-unused-vars,no-shadow,no-console,no-use-before-define,
import/no-named-as-default,import/no-named-as-default-member,react/prop-types,
react/destructuring-assignment,
*/
import * as React from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import RedwoodModal from '../../../common/Modal/RedwoodModal';
import { Context } from '../../../state/Store';
import { getFeatureFlag, getOrgId, transformPromos } from '../../Helpers';
import SendAction from '../../../CourierService/SendAction';
import SendLogs from '../../../CourierService/SendLogs';
import SendMetrics from '../../../CourierService/SendMetrics';
import { navTrack } from '../../../config/adobeAnalytics';

import { fetchPromotionGuests, fetchValidateEmail } from '../../../CourierService/FetchService';
import countries from '../../../data/CountriesInfo.json';

const PromoSelection = (props) => {
  const {
    emailVerification,
    verifyEmailBtnClicked,
    captchaJwtToken,
    promoAvailableCheck,
    toggleCallBack,
    programType,
  } = props;
  const { t } = useTranslation();
  const [state, dispatch] = React.useContext(Context);
  const [show, setShow] = React.useState(false);
  const [selectedPromo, setSelectedPromo] = React.useState({});
  const [promoList, setPromoList] = React.useState([]);
  const countrySpecificPromos = ['Standard', 'Developer', 'Student', 'Educator', 'OCI-Arm', 'EVENT-TRIALS', 'RESEARCH-STARTER'];
  const [promoRetries, setPromoRetries] = React.useState(0);
  const [validateEmailRetries, setValidateEmailRetries] = React.useState(0);
  const [validateEmail, setValidateEmail] = React.useState(false);
  const { featureFlags } = state;

  const promotions = [];

  const getCurrency = () => (state.countryInfo
  && state.countryInfo.currency
  && state.countryInfo.currency.symbol
    ? state.countryInfo.currency.symbol : '$');

  const toggleModal = () => {
    setShow(!show);
    toggleCallBack();
  };

  const setAccountNotUnique = () => {
    dispatch({
      type: 'SHOW_ACCOUNT_NOT_UNIQUE',
      payload: true,
    });
  };

  const selectOffer = () => {
    setShow(!show);
    dispatch({
      type: 'UPDATE_PROMO',
      payload: selectedPromo,
    });

    if (!_.isEmpty(selectedPromo.firstName)) {
      dispatch({
        type: 'UPDATE_FIRST_NAME',
        payload: selectedPromo.firstName,
      });
      props.resetError('firstName');
    }

    if (!_.isEmpty(selectedPromo.lastName)) {
      dispatch({
        type: 'UPDATE_LAST_NAME',
        payload: selectedPromo.lastName,
      });
      props.resetError('lastName');
    }

    if (!_.isEmpty(selectedPromo.country)) {
      const countryObject = { value: selectedPromo.country.id, label: t(`countries.${selectedPromo.country}`) };
      dispatch({
        type: 'UPDATE_PROMO_COUNTRY',
        payload: countryObject,
      });
      const countryInfo = countries.countries[selectedPromo.country];
      if (countryInfo === undefined) {
        dispatch({
          type: 'UPDATE_COUNTRY',
          payload: ['', countryObject, {}, '', ''],
        });
      } else {
        const orgId = getOrgId(countryInfo.tc.OrgName);
        dispatch({
          type: 'UPDATE_COUNTRY',
          payload: [selectedPromo.country, countryObject, countryInfo, orgId],
        });
      }
    }

    navTrack('oci-signup', state.language, 's1-selected-promo', 'user-selected-a-promo');
    navTrack('oci-signup', state.language, 's1-selected-promo', `promo-type: ${selectedPromo.type}`);
    navTrack('oci-signup', state.language, 's1-selected-promo', `program-type: ${selectedPromo.programType}`);
    SendAction({ action: 'promo_selected', value: `selected promo: ${JSON.stringify(selectedPromo)}`, userSession: state.user_session });

    if (selectedPromo.programType === 'SE-TRIAL') {
      props.updateSETrialAccountName(selectedPromo.customerName);
    }

    if (selectedPromo.creditCardRequired && state.isOpayOutage) {
      dispatch({
        type: 'UPDATE_SHOW_BANNER',
        payload: true,
      });
      toggleCallBack();
    } else {
      props.sendVerificationLink();
    }

    toggleModal();
  };

  const setApiError = () => {
    dispatch({
      type: 'UPDATE_APIERROR',
      payload: true,
    });
  };

  const setApi403Error = () => {
    dispatch({
      type: 'UPDATE_API403ERROR',
      payload: true,
    });
  };

  React.useEffect(() => {
    dispatch({
      type: 'UPDATE_PROMO',
      payload: {},
    });
  }, [props.email.value]);

  React.useEffect(() => {
    if (!_.isEmpty(props.email.value) && !_.isEmpty(state.username) && verifyEmailBtnClicked > 0) {
      if (getFeatureFlag('enable_heatwave', featureFlags) && _.toUpper(programType) === 'MYSQLDSAWS') {
        promoAvailableCheck(false, state.username);
        setValidateEmail(true);
      } else {
        fetchPromotionGuests(encodeURIComponent(state.username), captchaJwtToken)
          .then(async (res) => {
            if (res.status === 200) {
              const results = await res.json();
              promotions.push(...results.items);
              if (!_.isEmpty(promotions)) {
                promoAvailableCheck(true, state.username);
                const promos = transformPromos(promotions, getCurrency().toString());
                setPromoList(promos);
                setSelectedPromo(promos[0]);
                toggleModal();
                props.updateAccountName(state.username);
              } else {
                promoAvailableCheck(false, state.username);
                setValidateEmail(true);
              }
              SendMetrics({ name: 'PromotionGuests.Count.Metrics', value: 1, userSession: state.user_session });
            } else if (res.status === 400) {
              SendMetrics({ name: 'PromotionGuests.Count.Metrics.Error', value: 1, userSession: state.user_session });
            } else if (res.status >= 500) {
              SendMetrics({ name: 'PromotionGuests.Count.Metrics.Error', value: 1, userSession: state.user_session });
              if (promoRetries === 3) {
                setApiError();
              } else {
                setPromoRetries(promoRetries + 1);
              }
            }
          }).catch((error) => {
            console.log(error);
          });
      }
    }
  }, [promoRetries, verifyEmailBtnClicked]);

  React.useEffect(() => {
    if (!_.isEmpty(state.username) && validateEmail) {
      emailVerification(true);
      fetchValidateEmail(JSON.stringify({
        username: state.username,
        validationAttributes: [{
          operationItemDefinitionId: 'OIH-PROMOCM-CREATE_V2',
        }],
      }))
        .then(async (res) => {
          emailVerification(false);
          if (res.status === 204) {
            props.sendVerificationLink();
          } else if (res.status === 409) {
            navTrack('oci-signup', state.language, 's1-entered-email', 'existing-email');
            setAccountNotUnique();
            props.updateEmailError(false, '', 'It looks like you are already registered.');
          } else if (res.status === 400) {
            const result = await res.json();
            SendMetrics({ name: 'Email.Count.Metrics.Error', value: 1, userSession: state.user_session });
            if (_.startsWith(result.message, 'Invalid email')) {
              props.updateEmailError(false, '', 'Invalid email entered');
            }
          } else if (res.status === 403) {
            const result = await res.json();
            if (_.startsWith(result.message, 'Too many requests')) {
              SendMetrics({ name: 'Email.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
              setApi403Error();
            }
          } else if (res.status >= 500) {
            SendMetrics({ name: 'Email.Count.Metrics.Error', value: 1, userSession: state.user_session });
            if (validateEmailRetries === 3) {
              setApiError();
            } else {
              setValidateEmailRetries(validateEmailRetries + 1);
            }
          }
          SendMetrics({ name: 'Email.Count.Metrics', value: 1, userSession: state.user_session });
        }).catch((error) => {
          console.log(error);
          SendLogs({ severity: 40000, message: `POST Email or GET PromotionGuests error ${error.message}`, userSession: state.user_session });
          SendMetrics({ name: 'Email_PromotionGuests.Count.Metrics.Fail', value: 1, userSession: state.user_session });
        });
    }
  }, [validateEmailRetries, validateEmail]);

  const onPromoChange = (promo) => {
    setSelectedPromo(promo);
  };

  // eslint-disable-next-line consistent-return
  const currencyChange = (promo) => {
    if (state.countryInfo !== undefined) {
      if (_.findIndex(countrySpecificPromos, (o) => o === promo.programType) !== -1) {
        if (state.countryInfo
          && state.countryInfo.promos
          && state.countryInfo.promos[promo.programType]) {
          return state.countryInfo.promos[promo.programType];
        }
      } if (_.findIndex(countrySpecificPromos, (o) => o === promo.type) !== -1) {
        if (state.countryInfo
          && state.countryInfo.promos
          && state.countryInfo.promos[promo.type]) {
          return state.countryInfo.promos[promo.type];
        }
      }
    }
    return promo.promoAmount;
  };

  const promoLabel = (promo) => {
    const customer = _.isEmpty(promo.customerName) ? '' : `${promo.customerName} -`;
    const campaign = promo.campaignDisplayName === '' ? promo.type : promo.campaignDisplayName;
    const currency = `${promo.currency}${currencyChange(promo)}`;
    const days = promo.promoDurationInDays;

    return `${customer} For ${campaign} with ${currency} credits for ${days} days`;
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <RedwoodModal testId="promo-dialog-modal" onClose={(e) => { selectOffer(); }} show={show} title={t('promo.specialOfferTitle')} buttonName={t('promo.selectOfferButton')}>
      <p>
        {t('promo.welcome')}
        {' '}
        <strong>
          {state.username}
          .
        </strong>
      </p>
      <p>
        {t('promo.promoDialog1')}
        :
      </p>
      <div>
        {promoList.map((promo) => (
          <div key={promo.promoId}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <input type="radio" checked={selectedPromo.promoId === promo.promoId} onChange={(e) => onPromoChange(promo)} value={promo.promoId} />
              {promoLabel(promo)}
            </label>
            <br />
          </div>
        ))}
      </div>
    </RedwoodModal>
  );
};

export default PromoSelection;
