/*
eslint-disable react/destructuring-assignment,react/prop-types,
no-shadow,no-unused-vars,import/no-named-as-default,import/no-named-as-default-member
*/
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import RedwoodPassword from '../../common/Password/RedwoodPassword';
import { Context } from '../../state/Store';
import { navTrack } from '../../config/adobeAnalytics';

const PasswordComponent = (props) => {
  const [state, dispatch] = React.useContext(Context);
  const { t } = useTranslation();
  const [passwordValidation, setPasswordValidation] = React.useState({
    condition1: false,
    condition2: true,
    isPasswordInvalid: false,
    isMatchPasswordInvalid: false,
    showPasswordIcon: false,
  });

  const passwordCriteria = {
    minPasswordLen: null,
    maxPasswordLen: null,
    checkFirstName: null,
    checkLastName: null,
    checkEmail: null,
    checkLowerCase: null,
    checkUpperCase: null,
    checkSpecialChar: null,
    checkNumericChar: null,
  };

  const updateState = (name, value) => {
    passwordCriteria[name] = value;
  };

  const hasValidSpecialChars = (val) => {
    const validSpecialChars = "!@#$%^&*()_+-=[]{};':\"|,./?";
    let foundSpecialChar = false;
    const result = val.match(/[^a-zA-Z0-9]/g);
    if (result && result.length > 0) {
      for (let i = 0; i < result.length; i += 1) {
        if (validSpecialChars.indexOf(result[i]) === -1) {
          return false;
        }
        foundSpecialChar = true;
      }
    }
    return foundSpecialChar;
  };

  const validatePassword = (e) => {
    const { password } = e;
    let updatedCondition1 = false;
    let updatedCondition2 = false;
    props.updateTouched('password', true);
    dispatch({
      type: 'UPDATE_PASSWORD',
      payload: password,
    });
    const minPasswordLen = 8;
    const maxPasswordLen = 40;

    const passwordValue = password;
    if (passwordValue.length < minPasswordLen) {
      updateState('minPasswordLen', false);
    } else {
      updateState('minPasswordLen', true);
    }

    if (passwordValue.length > maxPasswordLen) {
      updateState('maxPasswordLen', false);
    } else {
      updateState('maxPasswordLen', true);
    }

    if (state.username !== '' && passwordValue.toLowerCase().indexOf(state.username.trim().toLowerCase()) >= 0) {
      updateState('checkEmail', false);
    } else {
      updateState('checkEmail', true);
    }

    if (state.firstName !== '' && passwordValue.toLowerCase().indexOf(state.firstName.trim().toLowerCase()) >= 0) {
      updateState('checkFirstName', false);
    } else {
      updateState('checkFirstName', true);
    }

    if (state.lastName !== '' && passwordValue.toLowerCase().indexOf(state.lastName.trim().toLowerCase()) >= 0) {
      updateState('checkLastName', false);
    } else {
      updateState('checkLastName', true);
    }

    if (!(/^.*[a-z].*$/.test(passwordValue))) {
      updateState('checkLowerCase', false);
    } else {
      updateState('checkLowerCase', true);
    }

    if (!(/^.*[A-Z].*$/.test(passwordValue))) {
      updateState('checkUpperCase', false);
    } else {
      updateState('checkUpperCase', true);
    }

    if (!(/^.*[0-9].*$/.test(passwordValue))) {
      updateState('checkNumericChar', false);
    } else {
      updateState('checkNumericChar', true);
    }

    if (!hasValidSpecialChars(passwordValue)) {
      updateState('checkSpecialChar', false);
    } else {
      updateState('checkSpecialChar', true);
    }

    if (passwordCriteria.checkEmail
        && passwordCriteria.maxPasswordLen
        && passwordCriteria.checkFirstName
        && passwordCriteria.checkLastName) {
      setPasswordValidation((passwordValidation) => ({ ...passwordValidation, condition2: true }));
      updatedCondition2 = true;
    } else if (passwordCriteria.checkEmail === false || passwordCriteria.maxPasswordLen === false
        || passwordCriteria.checkFirstName === false || passwordCriteria.checkLastName === false) {
      setPasswordValidation((passwordValidation) => ({ ...passwordValidation, condition2: false }));
      updatedCondition2 = false;
    }

    if (passwordCriteria.minPasswordLen && passwordCriteria.checkLowerCase
        && passwordCriteria.checkNumericChar && passwordCriteria.checkUpperCase
        && passwordCriteria.checkSpecialChar) {
      setPasswordValidation((passwordValidation) => ({
        ...passwordValidation,
        condition1: true,
        isPasswordInvalid: false,
      }));
      updatedCondition1 = true;
    } else if (passwordCriteria.minPasswordLen === false
        || passwordCriteria.checkLowerCase === false
        || passwordCriteria.checkNumericChar === false
        || passwordCriteria.checkUpperCase === false
        || passwordCriteria.checkSpecialChar === false) {
      setPasswordValidation((passwordValidation) => ({ ...passwordValidation, condition1: false }));
      updatedCondition1 = false;
    }

    if (updatedCondition1 && updatedCondition2) {
      if (password.length > 12) {
        e.score = 4;
      } else {
        e.score = 3;
      }
    } else {
      e.score = 0;
    }
  };

  const matchPassword = (e) => {
    props.updateTouched('confirmPassword', false);
    if (state.password !== state.confirmPassword) {
      props.updatePasswordErrorFlag('matchPassword', false);
      passwordValidation.isMatchPasswordInvalid = true;
      passwordValidation.showPasswordIcon = false;
    } else {
      props.updatePasswordErrorFlag('matchPassword', true);
      passwordValidation.isMatchPasswordInvalid = false;
      if (state.confirmPassword !== '') {
        passwordValidation.showPasswordIcon = true;
      }
      navTrack('oci-signup', state.language, 's1-entered-confirm-password', 'matched-password');
    }
  };

  useEffect((e) => {
    if (state.password.length !== 0 && state.confirmPassword.length !== 0) {
      matchPassword(e);
    }
  }, [state.password, state.confirmPassword]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <RedwoodPassword
        label={t('signup.pwLabel')}
        name="password"
        testId="passwordField"
        placeholder={t('signup.pwPlaceholder')}
        passwordStrengthIndicator="true"
        valid
        showError={false}
        validity={props.passwordError.passwordValid}
        condition1={passwordValidation.condition1}
        condition2={passwordValidation.condition2}
        isPasswordInvalid={passwordValidation.isPasswordInvalid}
        onChange={
          (e) => {
            validatePassword(e);
            if (passwordValidation.condition1 && passwordValidation.condition2) {
              passwordValidation.isPasswordInvalid = false;
            }
          }
}
        onBlur={(e) => {
          props.updateTouched('password', false);
          if (passwordValidation.condition1 && passwordValidation.condition2) {
            props.updatePasswordErrorFlag('passwordValid', true);
            passwordValidation.isPasswordInvalid = false;
            navTrack('oci-signup', state.language, 's1-entered-password', 'valid-password');
          } else {
            props.updatePasswordErrorFlag('passwordValid', false);
            if (state.password != null && state.password.length !== 0) {
              passwordValidation.isPasswordInvalid = true;
            }
            navTrack('oci-signup', state.language, 's1-entered-password', 'invalid-password');
          }
        }}
      />

      <RedwoodPassword
        label={t('signup.confirmPwLabel')}
        name="matchPassword"
        testId="matchPasswordField"
        placeholder={t('signup.confirmPwPlaceholder')}
        valid={false}
        showError
        validity={props.passwordError.matchPassword}
        isPasswordInvalid={passwordValidation.isMatchPasswordInvalid}
        showPasswordIcon={passwordValidation.showPasswordIcon}
        error={t('signup.confirmPwValidation')}
        onChange={(e) => {
          dispatch({
            type: 'UPDATE_CONFIRM_PASSWORD',
            payload: e.target.value,
          });
          props.updateTouched('confirmPassword', true);
        }}
        onBlur={(e) => matchPassword(e)}
      />
    </>
  );
};

export default PasswordComponent;
