// Email - https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript/1373724

// eslint-disable-next-line import/prefer-default-export
export const regExp = {
  identityDomainName: '^[a-z][a-z0-9]{1,25}$',
  /* eslint-disable no-useless-escape */
  personName: "^([ ]+)*[a-zA-Z0-9\\u00C0-\\uFFFF][' a-zA-Z0-9\\u00C0-\\uFFFF-]*([ ]+)*$",
  /* eslint-disable no-useless-escape */
  organization: "^[a-zA-Z0-9\\u00A0-\\uFFFF][a-zA-Z0-9\&\\u00A0-\\uFFFF',. ()@-]{0,199}[ ]*$",
  altEnglishCompanyName: '^[a-zA-Z0-9][a-zA-Z0-9 ]{0,199}[ ]*$',
  altEnglishName: '^[a-zA-Z][a-zA-Z ]{0,199}[ ]*$',
  letters: '^[a-zA-Z]*$',
  letterNumbers: '^[a-zA-Z][a-zA-Z0-9]*$',
  smsValidationCode: '^[0-9]{6,12}[ ]*$',
  phoneNumber: '^(?:[0-9] ?){6,14}[0-9]$',
  departmentName: '^.{0,60}$',
  /* eslint-disable no-useless-escape */
  email: /^(?=.{0,240}$)(([^<>'()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
