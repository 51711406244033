import * as React from 'react';
import * as _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Context } from '../../state/Store';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import Notifier from '../../common/Notifier/Notifier';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import RedwoodModal from '../../common/Modal/RedwoodModal';
import SendAction from '../../CourierService/SendAction';

const ThrottlingBanner = (props) => {
  // eslint-disable-next-line react/prop-types
  const { toggleCallback } = props;
  const { t } = useTranslation();
  const [state] = React.useContext(Context);
  const [showModal, setShowModal] = React.useState(true);

  const closeModal = () => {
    setShowModal(!showModal);
    const urlParams = new URLSearchParams(window.location.search);
    let queryString = urlParams.delete('verify_email');
    queryString = urlParams.toString();
    window.location = new URL(`${window.location.href.split('?')[0]}?${queryString}`);
    SendAction({ action: 'Error occurred due to Bad request', value: 'Reload signup page', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  if (state.api403Error === false) return null;

  if (_.startsWith(state.api403ErrorMsg, 'Too many requests') || _.startsWith(state.api403ErrorMsg, 'Retry request after')) {
    // eslint-disable-next-line react/jsx-filename-extension
    return <Notifier type="error" message={t('signup.api403Error', 'Forbidden')} position="api403Error" />;
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension,no-unused-vars
    <RedwoodModal onClose={(e) => closeModal()} title={t('emailVerification.api403ErrorModal')} show={showModal} buttonName={t('signup.retry', 'Retry')}>
      <p>
        <Trans i18nKey="emailVerification.api403ErrorModalDetails">
          An error has occurred.Please try again in a few minutes.
        </Trans>
      </p>
    </RedwoodModal>
  );
};

export default ThrottlingBanner;
