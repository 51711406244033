/*
eslint-disable no-console,no-unused-vars,no-shadow,camelcase,no-return-assign,
no-unused-expressions,react/prop-types,array-callback-return,no-use-before-define,
consistent-return,import/no-named-as-default,import/no-named-as-default-member,
react/jsx-one-expression-per-line
*/
import * as React from 'react';
import * as _ from 'lodash';
import * as Bowser from 'bowser';
import { useTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import './SignUpFormContainer.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Context } from '../../state/Store';
import TermsOfUseComponent from '../TermsOfUse/TermsOfUseComponent';
import RedwoodInputText from '../../common/Input/RedwoodInputText';
import RedwoodSelect from '../../common/Select/RedwoodSelect';
import Icon from '../../common/Icon/Icon';
import countries from '../../data/CountriesInfo.json';
import {
  generateRandomNumber, getOrgId, timer, getFeatureFlag, generateRandomLetter, is_numeric_char,
} from '../Helpers';
import { signup_environment } from '../../config/apiConfig';
import { regExp } from '../Validators';
import PasswordComponent from '../Password/PasswordComponent';
import promotionsData from '../../data/PromotionsInfo.json';
import Notifier from '../../common/Notifier/Notifier';
import SendAction from '../../CourierService/SendAction';
import SendLogs from '../../CourierService/SendLogs';
import SendMetrics from '../../CourierService/SendMetrics';
import { navTrack } from '../../config/adobeAnalytics';
import RedwoodModal from '../../common/Modal/RedwoodModal';
import Button from '../../common/Button/Button';
import ExistingEmailDialog from '../Dialogs/ExistingEmailDialog/ExistingEmailDialog';
import {
  fetchRegions,
  fetchEmailToken,
  fetchBanner,
  fetchValidateAccount,
  fetchSecurityLink,
  fetchCaptchaToken,
  fetchValidateAccountRecursively,
  fetchEmailTld,
} from '../../CourierService/FetchService';
import { constants } from '../Constants';
import PromoSelection from '../Dialogs/PromoSelection/PromoSelection';
import PromoLoading from '../Dialogs/PromoLoading/PromoLoading';
import TaxRegistrationComponent from '../TaxRegistration/TaxRegistrationComponent';
import OutageBanner from '../OutageBanner/OutageBanner';
import ThrottlingBanner from '../ThrottlingBanner/ThrottlingBanner';
import RedwoodCheckbox from '../../common/Checkbox/RedwoodCheckbox';

const SignUpFormContainer = (props) => {
  const {
    sourceType, referrer, programType, trialType, verifyEmailToken, toggleCallback, emailParam,
  } = props;
  const { t } = useTranslation();
  const [state, dispatch] = React.useContext(Context);
  const errorState = { isValid: true, value: '', errorMsg: '' };
  const [error, setError] = React.useState({
    email: errorState,
    accountName: errorState,
    organization: errorState,
    firstName: errorState,
    lastName: errorState,
    alternateName: errorState,
    altEnglishCompanyName: errorState,
    departmentName: errorState,
    taxFields: errorState,
  });
  const [touched, setTouched] = React.useState({});
  const [passwordError, setPasswordError] = React.useState({
    passwordValid: null,
    matchPassword: null,
  });
  const [accountName, setAccountName] = React.useState('');
  const [invalid, setInvalid] = React.useState(false); // Country Field
  const [loading, setLoading] = React.useState(false);
  const [verifyEmailLoading, setVerifyEmailLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [verifyEmailCount, setVerifyEmailCount] = React.useState(0);
  const [maxAttemptsError, setMaxAttemptsError] = React.useState(false);
  const accountNameLength = 25;
  const [bannerLevel, setBannerLevel] = React.useState('');
  const [bannerTitle, setBannerTitle] = React.useState('');
  const [bannerMessage, setBannerMessage] = React.useState('');
  const [bannerOpayOutageFlag, setBannerOpayOutageFlag] = React.useState('');
  const [bannerOrclEmpFlag, setBannerOrclEmpFlag] = React.useState('');
  const [accntNameRetries, setAccntNameRetries] = React.useState(0);
  const [recurseAccntNameRetries, setRecurseAccntNameRetries] = React.useState(0);
  const [taggedEmailInfo, setTaggedEmailInfo] = React.useState('');
  const [verifyEmailBtnClicked, setVerifyEmailBtnClicked] = React.useState(0);
  const [domainClaimed, setDomainClaimed] = React.useState(false);
  const [domainClaimedError, setDomainClaimedError] = React.useState(false);
  const [administrator_email, setAdministrator_email] = React.useState('');
  const [token, setToken] = React.useState('');
  const [captchaToken, setCaptchaToken] = React.useState(false);
  const [captchaJwtToken, setCaptchaJwtToken] = React.useState('');
  const [captchaTimer, setCaptchaTimer] = React.useState(0);
  const [emailsTld, setEmailsTld] = React.useState([]);
  const { featureFlags } = state;
  const [validAccountNameFlag, setValidAccountNameFlag] = React.useState(false);
  const [ackHomeRegion, setAckHomeRegion] = React.useState(false);
  const canContinue = () => {
    let flag = false;
    // eslint-disable-next-line no-unused-vars
    _.forIn(touched, (value, key) => {
      if (value === true) {
        flag = true;
      }
    });

    const deptNameFlag = state.country === 'JP' ? state.departmentName !== '' : state.departmentName === '';
    const altEnglishCompanyNameFlag = (state.country === 'JP' || state.country === 'CN' || state.country === 'TW' || state.country === 'KR') && state.companyName !== ''
      ? state.altEnglishCompanyName !== '' : state.altEnglishCompanyName === '';

    return flag ? true : !(state.country !== ''
      && (error.email.value !== '' || state.email !== '')
      && (error.accountName.isValid && state.accountName !== '' && validAccountNameFlag)
      && (error.firstName.value !== '' || state.firstName !== '')
      && (error.lastName.value !== '' || state.lastName !== '')
      && ((error.alternateName.value !== '' && error.alternateName.isValid) || _.get(state.countryInfo, 'personalInfo.alternateName', '') === '')
      && error.organization.isValid && (state.companyName !== '' || _.get(state, 'intendedUse', '') === 'PERSONAL')
      && !(_.isEmpty(state.homeRegion))
      && passwordError.passwordValid
      && passwordError.matchPassword
      && (deptNameFlag && error.departmentName.isValid)
      && (altEnglishCompanyNameFlag && error.altEnglishCompanyName.isValid)
      && error.taxFields.isValid && (!state.isPaid || error.taxFields.value !== '' || _.get(state.countryInfo, 'taxFields', '') === '')
      && ackHomeRegion
    );
  };

  const countriesList = () => {
    const keys = [];

    Object.keys(countries.countries).forEach((item) => {
      keys.push({ value: item, label: t(`countries.${item}`) });
    });

    keys.sort((a, b) => ((a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1));
    return keys;
  };

  // Countdown timer
  React.useEffect(() => {
    let isSubscribed = true;
    if (state.sendEmail === true) {
      const timer = isSubscribed
        ? counter > 0 && setInterval(() => setCounter(counter - 1), 1000) : null;
      if (verifyEmailCount === 3 && counter === 1) {
        setMaxAttemptsError(true);
      }
      return () => clearInterval(timer);
    }
    return () => (isSubscribed = false);
  }, [counter]);

  React.useEffect(() => {
    if (!isFirstNameFieldDisabled()) {
      fetchEmailTld().then(async (res) => {
        if (res.status === 200) {
          const text = await res.text();
          setEmailsTld(text.split('\n').slice(1));
        }
      });
    }
  }, [state]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (!_.isEmpty(token)) {
      const timer = isSubscribed
        ? captchaTimer > 0 && setInterval(() => setCaptchaTimer(captchaTimer - 1), 1000) : null;
      if (captchaTimer === 1) {
        setToken('');
        setCaptchaToken(false);
      }
      return () => clearInterval(timer);
    }
    return () => (isSubscribed = false);
  }, [captchaTimer]);

  const checkBanner = (bannerStartTime, bannerEndTime, bannerOpayOutageFlag, bannerOrclEmpFlag) => {
    const currentTime = new Date().getTime();
    if (currentTime >= bannerStartTime && currentTime <= bannerEndTime) {
      if (bannerOpayOutageFlag != null && bannerOpayOutageFlag === true) {
        dispatch({
          type: 'UPDATE_OPAY_OUTAGE',
          payload: true,
        });
      } else if (state.oracleEmployee === true || bannerOrclEmpFlag === true) {
        dispatch({
          type: 'UPDATE_ORCL_OUTAGE',
          payload: true,
        });
      } else {
        dispatch({
          type: 'UPDATE_SHOW_BANNER',
          payload: true,
        });
      }
    }
  };

  const setApiError = () => {
    dispatch({
      type: 'UPDATE_APIERROR',
      payload: true,
    });
  };

  const setApi403Error = (msg = '') => {
    dispatch({
      type: 'UPDATE_API403ERROR',
      payload: true,
    });
    dispatch({
      type: 'UPDATE_API403_ERROR_MSG',
      payload: msg,
    });
  };

  const setOracleEmployee = (value) => {
    dispatch({
      type: 'UPDATE_ORACLE_EMPLOYEE',
      payload: value,
    });
  };

  const emailVerification = (flag) => {
    setVerifyEmailLoading(flag);
  };
  const promoAvailablecheck = (flag, email) => {
    let oracleEmployee = false;
    if (_.split(email, '@')[1] === 'oracle.com' && !flag) {
      oracleEmployee = true;
      setOracleEmployee(oracleEmployee);
      SendLogs({ severity: '20000', message: `${state.username} is an Oracle Employee`, userSession: state.user_session });
      SendMetrics({ name: 'OracleEmployee.Count.Metrics', value: 1, userSession: state.user_session });
      navTrack('oci-signup', state.language, 's1-promotion-check', 'Oracle-Employee-Free-Trial');
      if (!_.isEmpty(state.featureFlags) && !getFeatureFlag('oracle_employee', state.featureFlags)) {
        setBannerLevel('error');
        setBannerTitle('Employee Free Tier is Unavailable');
        setBannerMessage('Due to unprecedented demand for Free-tier by our customers Employee Free Tier has been paused. You can get clarification to your questions on slack channel #oci_employee-always-freetier');
        checkBanner(Date.parse('13 Apr 2022 00:08:00 GMT'), Date.parse('31 Dec 2022 03:30:00 GMT'));
      }
    }
    // eslint-disable-next-line no-use-before-define
    getRegions(oracleEmployee);
  };

  // GET Home regions list
  const getRegions = (oracleEmployee) => {
    fetchRegions(oracleEmployee, programType).then(async (res) => {
      if (res.status === 200) {
        const result = await res.json();
        let list = [];
        result.items.map((homeRegion) => {
          const subList = homeRegion.homeRegions
            .sort((a, b) => (a.regionName > b.regionName ? 1 : -1))
            .map((subRegion) => ({
              label: subRegion.regionName,
              value: { id: subRegion.id },
              group: homeRegion.id,
            }));
          const dataRegion = {
            label: homeRegion.regionName,
            value: homeRegion.id,
            options: subList,
          };
          list = list.concat(dataRegion);
        });
        if (signup_environment === 'dev' || signup_environment === 'miglab') {
          const homeRegionList = [{ label: 'North America', options: [{ label: 'US Dev West (Seattle)', value: { id: 'us-seattle-1' }, group: 'NORTH_AMERICA_REGION' }] }];
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: homeRegionList,
          });
        } else if (signup_environment === 'pool2') {
          const homeRegionList = [{ label: 'North America', options: [{ label: 'US Dev West (Seattle)', value: { id: 'us-seattle-1' }, group: 'APAC_REGION' }] }];
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: homeRegionList,
          });
        } else if (signup_environment === 'pintlab' || signup_environment === 'pintlab2') {
          const homeRegionList = [{ label: 'North America', options: [{ label: 'US Dev West (Seattle)', value: { id: 'us-seattle-1' }, group: 'NORTH_AMERICA_REGION1' }] }];
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: homeRegionList,
          });
        } else {
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: list,
          });
        }
      }
      if (res.status >= 400) {
        if (res.status === 403) {
          const result = await res.json();
          if (_.startsWith(result.message, 'Too many requests')) {
            SendLogs({ severity: 20000, message: 'Too Many Requests', userSession: state.user_session });
            SendMetrics({ name: 'Region.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
            setApi403Error(result.message);
          }
        } else {
          SendMetrics({ name: 'Region.Count.Metrics.Error', value: 1, userSession: state.user_session });
          setApiError();
        }
      }
      SendLogs({ severity: 20000, message: 'GET Home regions list successful', userSession: state.user_session });
      SendMetrics({ name: 'Region.Count.Metrics', value: 1, userSession: state.user_session });
    }).catch((error) => {
      console.log(error);
      SendLogs({ severity: 40000, message: `GET Home regions list error: ${error.message}`, userSession: state.user_session });
      SendMetrics({ name: 'Region.Count.Metrics.Fail', value: 1, userSession: state.user_session });
    });
  };

  React.useEffect(() => {
    if (!_.isEmpty(emailParam)) {
      updateEmail({ target: { value: emailParam, name: 'email' }, detail: 'custom' });
    }
  }, [emailParam]);

  // Store SourceType and Referrer in global state
  React.useEffect(() => {
    dispatch({
      type: 'UPDATE_BASIC_DETAILS',
      payload: [sourceType, referrer, i18next.language],
    });
  }, [sourceType]);

  // To select the default promo from promotionsData
  React.useEffect(() => {
    if (_.toUpper(trialType) === 'PAID') {
      dispatch({
        type: 'UPDATE_PAID_PROMO',
        payload: true,
      });
    }
    promotionsData.items.filter((promoDefinition) => {
      if (_.toUpper(programType) === 'RESEARCH-STARTER') {
        if (promoDefinition.programType === 'RESEARCH-STARTER') {
          dispatch({
            type: 'UPDATE_DEFAULT_PROMO',
            payload: promoDefinition,
          });
        } SendLogs({ severity: 20000, message: 'Research Starter promotion successful', userSession: state.user_session });
      } else if (promoDefinition.default) {
        dispatch({
          type: 'UPDATE_DEFAULT_PROMO',
          payload: promoDefinition,
        });
      } SendLogs({ severity: 20000, message: 'promotion successful', userSession: state.user_session });
    });
  }, [promotionsData]);

  // Browser, OS and Platform Metrics
  React.useEffect(() => {
    const bowser = Bowser.getParser(window.navigator.userAgent);

    // Browser
    const browser = bowser.getBrowser();
    const browserName = browser.name;
    SendLogs({ severity: 20000, message: `Browser Name: ${browserName}`, userSession: state.user_session });
    browserName === 'Chrome' && SendMetrics({ name: 'Chrome.Count.Metrics', value: 1, userSession: state.user_session });
    browserName === 'Safari' && SendMetrics({ name: 'Safari.Count.Metrics', value: 1, userSession: state.user_session });
    browserName === 'Firefox' && SendMetrics({ name: 'Firefox.Count.Metrics', value: 1, userSession: state.user_session });
    browserName === 'Microsoft Edge' && SendMetrics({ name: 'Edge.Count.Metrics', value: 1, userSession: state.user_session });
    browserName === 'Internet Explorer' && SendMetrics({ name: 'IE.Count.Metrics', value: 1, userSession: state.user_session });
    const isUnknownBrowser = browserName !== 'Chrome' && browserName !== 'Safari' && browserName !== 'Firefox' && browserName !== 'Microsoft Edge' && browserName !== 'Internet Explorer';
    isUnknownBrowser && SendMetrics({ name: 'Unknown.Count.Metrics', value: 1, userSession: state.user_session });

    // OS
    const os = bowser.getOS();
    os.name === 'macOS' && SendMetrics({ name: 'Mac.Count.Metrics', value: 1, userSession: state.user_session });
    os.name === 'Linux' && SendMetrics({ name: 'Linux.Count.Metrics', value: 1, userSession: state.user_session });
    os.name === 'Windows' && SendMetrics({ name: 'Windows.Count.Metrics', value: 1, userSession: state.user_session });

    // Host
    window.location.hostname === 'signup.oraclecloud.com' && SendMetrics({ name: 'signup_oraclecloud_com.Count.Metrics', value: 1, userSession: state.user_session });
    window.location.hostname === 'signup.cloud.oracle.com' && SendMetrics({ name: 'signup_cloud_oracle_com.Count.Metrics', value: 1, userSession: state.user_session });

    // Platform
    let platform = 'Desktop';
    if (bowser.getPlatformType() === 'mobile') {
      platform = 'Mobile';
    } else if (bowser.getPlatformType() === 'tablet') {
      platform = 'Tablet';
    }

    SendMetrics({ name: `${platform}.Count.Metrics`, value: 1, userSession: state.user_session });
  }, [state.render]);

  React.useEffect(() => {
    if (!_.isEmpty(verifyEmailToken)) {
      fetchEmailToken(verifyEmailToken).then(async (res) => {
        navTrack('oci-signup', state.language, 's1-verification-link-clicked', 'verify-email-token');
        if (res.status === 200) {
          const result = await res.json();
          SendMetrics({ name: 'VerifyEmail.Count.Metrics.Success', value: 1, userSession: result.userSessionId });
          SendLogs({ severity: 20000, message: `GET VerifyEmailToken API successful, EmailID:${result.email}`, userSession: result.userSessionId });
          dispatch({ type: 'VERIFY_EMAIL_TOKEN', payload: result.token });
          dispatch({ type: 'UPDATE_NAME', payload: result.email });
          dispatch({ type: 'UPDATE_LAST_NAME', payload: result.lastName });
          dispatch({ type: 'UPDATE_FIRST_NAME', payload: result.firstName });
          dispatch({ type: 'UPDATE_USER_SESSION', payload: result.userSessionId });
          dispatch({ type: 'UPDATE_SELECTED_PROMO_ID', payload: result.selectedPromoId });
          dispatch({ type: 'UPDATE_CAPTCHA_JWT_TOKEN', payload: result.captchaJwtToken });
          dispatch({ type: 'ORACLE_EMPLOYEE', payload: result.oracleEmployee });
          dispatch({
            type: 'UPDATE_BASIC_DETAILS',
            payload: [sourceType, result.referrer, i18next.language],
          });
          promoAvailablecheck(!_.isEmpty(result.selectedPromoId), result.email);

          if (state.oracleEmployee === 'false' && (result.isOpayOutage === 'true' || state.isOpayOutage === true)) {
            dispatch({
              type: 'UPDATE_SHOW_BANNER',
              payload: true,
            });
            toggleCallback();
          }

          const countryId = result.country;
          const countryObject = { value: countryId, label: t(`countries.${countryId}`) };
          const countryInfo = countries.countries[countryId];
          if (countryInfo === undefined) {
            setInvalid(true);
            dispatch({
              type: 'UPDATE_COUNTRY',
              payload: ['', countryObject, {}, '', ''],
            });
          } else {
            const orgId = getOrgId(countryInfo.tc.OrgName);

            setInvalid(false);
            dispatch({
              type: 'UPDATE_COUNTRY',
              payload: [countryId, countryObject, countryInfo, orgId],
            });
          }
          updateAccountName(result.email);
          navTrack('oci-signup', state.language, 's1-verify-email-token', 'email-token-valid');
        } else if (res.status === 409) {
          const result = await res.json();
          setAdministrator_email(result.adminEmail);
          toggleDomainClaimedModal();
          SendLogs({ severity: 20000, message: `User Email Domain is claimed, EmailID:${state.username}`, userSession: state.user_session });
          SendMetrics({ name: 'VerifyEmail.Count.Metrics.ClaimedDomain', value: 1, userSession: state.user_session });
        } else if (res.status === 403) {
          const result = await res.json();
          if (_.startsWith(result.message, 'Too many requests') || _.startsWith(result.message, 'Retry request after')) {
            SendMetrics({ name: 'VerifyEmail.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
            SendLogs({ severity: 40000, message: `Too many requests, EmailID:${state.username}`, userSession: state.user_session });
            setApi403Error(result.message);
          } else {
            toggleEmailModal();
            SendMetrics({ name: 'VerifyEmail.Count.Metrics.Error', value: 1, userSession: state.user_session });
            SendLogs({ severity: 40000, message: `GET VerifyEmailToken API Failure, EmailID:${state.username}`, userSession: state.user_session });
          }
          navTrack('oci-signup', state.language, 's1-email-token-expired', 'user-clicked-on-link-after-30-mins');
        } else if (res.status === 417) {
          dispatch({
            type: 'UPDATE_SHOW_BANNER',
            payload: true,
          });
          toggleCallback();
          SendMetrics({ name: 'VerifyEmail.Count.Metrics.DomainBlocked', value: 1, userSession: state.user_session });
          SendLogs({ severity: 40000, message: `GET VerifyEmailToken API Failure, Domain is blocked in Opay outage, EmailID:${state.username}`, userSession: state.user_session });
        } else if (res.status === 401) {
          dispatch({
            type: 'UPDATE_DOMAIN_BLOCKED',
            payload: true,
          });
          toggleCallback();
          SendLogs({ severity: 40000, message: `GET VerifyEmailToken API Failure, Domain is blocked, EmailId: ${state.username}`, userSession: state.user_session });
          SendMetrics({ name: 'VerifyEmail.Count.Metrics.DomainBlocked', value: 1, userSession: state.user_session });
        } else {
          toggleErrorModal();
          SendMetrics({ name: 'VerifyEmail.Count.Metrics.Error', value: 1, userSession: state.user_session });
          SendLogs({ severity: 40000, message: `GET VerifyEmailToken API Failure Exception, EmailID:${state.username}`, userSession: state.user_session });
        }
        SendMetrics({ name: 'VerifyEmail.Count.Metrics', value: 1, userSession: state.user_session });
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [verifyEmailToken, state.isOpayOutage]);

  React.useEffect(() => {
    if ((!_.isEmpty(state.username)) && (!_.isEmpty(state.featureFlags)) && _.split(state.username, '@')[1] === 'oracle.com' && getFeatureFlag('oracle_employee', state.featureFlags) && (!_.isEmpty(verifyEmailToken))) {
      promotionsData.items.filter((promoDefinition) => {
        if (promoDefinition.programType === 'ORCL-EMPLOYEE-HACKATHON') {
          dispatch({ type: 'UPDATE_PROMO', payload: promoDefinition });
          SendLogs({ severity: 20000, message: 'Oracle Employee Hackathon promotion successful', userSession: state.user_session });
        } SendLogs({ severity: 20000, message: 'Promotion successful', userSession: state.user_session });
      });
    }
  }, [state.username, state.featureFlags, verifyEmailToken]);

  // GET banner
  React.useEffect(() => {
    fetchBanner().then(async (res) => {
      if (res.status === 200) {
        const result = await res.json();
        const banner = result.items[0];
        setBannerLevel(banner.level);
        setBannerTitle(banner.bannerTitle);
        setBannerMessage(banner.bannerDetails);
        setBannerOpayOutageFlag(banner.isOpayOutage);
        checkBanner(Date.parse(banner.startTime), Date.parse(banner.endTime),
          banner.isOpayOutage);
      }
      if (res.status >= 400) {
        SendMetrics({ name: 'Banner.Count.Metrics.Error', value: 1, userSession: state.user_session });
      }
      SendLogs({ severity: 20000, message: 'GET banner list successful', userSession: state.user_session });
      SendMetrics({ name: 'Banner.Count.Metrics', value: 1, userSession: state.user_session });
    }).catch((error) => {
      console.log(error);
      SendLogs({ severity: 40000, message: `GET banner list error: ${error.message}`, userSession: state.user_session });
      SendMetrics({ name: 'Banner.Count.Metrics.Fail', value: 1, userSession: state.user_session });
    });
  }, [state.render]);

  // Recursively validate cloud account name until it is unique
  React.useEffect(() => {
    if (accountName !== '') {
      fetchValidateAccountRecursively(JSON.stringify({ name: accountName })).then(async (res) => {
        if (res.status === 204) {
          dispatch({
            type: 'UPDATE_ACC_NAME',
            payload: accountName,
          });
          setValidAccountNameFlag(true);
          SendLogs({ severity: 20000, message: 'POST Recursively validate cloud account name successful', userSession: state.user_session });
          SendMetrics({ name: 'ValidateAccountName.Count.Metrics', value: 1, userSession: state.user_session });
        }
        let trimAccountName;
        if (res.status === 400) {
          if (accountName.length > accountNameLength - 2) {
            trimAccountName = accountName.substr(0, 24);
            setAccountName(trimAccountName);
          }
        }
        if (res.status === 409) {
          SendMetrics({ name: 'ValidateAccountName.Count.Metrics.Duplicate', value: 1, userSession: state.user_session });
          const random = generateRandomNumber(1, 100);
          if (accountName.length > accountNameLength - 2) {
            trimAccountName = accountName.substr(0, 22);
            setAccountName(trimAccountName + random);
          } else {
            setAccountName(accountName + random);
          }
        } else if (res.status >= 500) {
          SendMetrics({ name: 'ValidateAccountName.Count.Metrics.Error', value: 1, userSession: state.user_session });
          if (recurseAccntNameRetries === 3) {
            setApiError();
          } else {
            setRecurseAccntNameRetries(recurseAccntNameRetries + 1);
          }
        } else if (res.status === 403) {
          const result = await res.json();
          if (_.startsWith(result.message, 'Too many requests')) {
            SendMetrics({ name: 'ValidateAccountName.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
            SendLogs({ severity: 40000, message: `too many requests: ${error.message}`, userSession: state.user_session });
            setApi403Error(result.message);
          }
        }
      }).catch((error) => {
        console.log(error);
        SendLogs({ severity: 40000, message: `POST Recursively validate cloud account name error: ${error.message}`, userSession: state.user_session });
        SendMetrics({ name: 'ValidateAccountName.Count.Metrics.Fail', value: 1, userSession: state.user_session });
      });
    }
  }, [accountName, recurseAccntNameRetries]);

  // POST validate account name - When user modifies the Cloud account name
  React.useEffect(() => {
    if (error.accountName.isValid && state.accountName !== '' && state.accountName.length <= 25) {
      fetchValidateAccount(JSON.stringify({ name: state.accountName })).then(async (res) => {
        if (res.status === 409) {
          const result = await res.json();
          setError((error) => ({ ...error, ...{ accountName: { isValid: false, value: '', errorMsg: result.message } } }));
        } else if (res.status === 400) {
          const result = await res.json();
          if (_.startsWith(result.message, 'Cloud account name is invalid')) {
            setError((error) => ({ ...error, ...{ accountName: { isValid: false, value: '', errorMsg: result.message } } }));
          }
        } else if (res.status >= 500) {
          SendMetrics({ name: 'ValidateAccountName.Count.Metrics.Error', value: 1, userSession: state.user_session });
          if (accntNameRetries === 3) {
            setApiError();
          } else {
            setAccntNameRetries(accntNameRetries + 1);
          }
        }
        setValidAccountNameFlag(true);
        SendLogs({ severity: 20000, message: 'POST validate account name successful', userSession: state.user_session });
        SendMetrics({ name: 'ValidateAccountName.Count.Metrics', value: 1, userSession: state.user_session });
      }).catch((error) => {
        console.log(error);
        SendLogs({ severity: 40000, message: `POST validate account name error: ${error.message}`, userSession: state.user_session });
        SendMetrics({ name: 'ValidateAccountName.Count.Metrics.Fail', value: 1, userSession: state.user_session });
      });
    } else if (state.accountName !== '' && state.accountName.length > accountNameLength) {
      // eslint-disable-next-line react/jsx-filename-extension
      setError((error) => ({ ...error, ...{ accountName: { isValid: false, value: '', errorMsg: <Trans i18nKey="signup.accountNameLengthError">Account Name cannot be more than 25 characters</Trans> } } }));
    }
  },
  [error.accountName.value, accntNameRetries]);

  const updateTouched = (name, value) => {
    setTouched((touched) => ({ ...touched, ...{ [name]: value } }));
  };

  const passwordErrorFlag = (name, value) => {
    setPasswordError((passwordError) => ({ ...passwordError, ...{ [name]: value } }));
  };

  const updateEmailError = (emailFlag, value, msg) => {
    setError((error) => ({ ...error, ...{ email: { isValid: emailFlag, value, errorMsg: msg } } }));
  };

  const resetError = (name) => {
    setError((error) => ({ ...error, ...{ [name]: errorState } }));
  };
  const sendVerificationLink = () => {
    setSendEmail(true);
    setVerifyEmailCount(verifyEmailCount + 1);
  };
  // Create Cloud Account Name based upon username
  // replace all the non alphanumeric value
  // check if the accountName is not empty
  // check if first letter isNumeric
  const updateAccountName = (email) => {
    const name = _.split(email, '@', 1);
    let accName = name[0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    if (_.isEmpty(accName)) {
      accName = generateRandomLetter() + generateRandomNumber(1, 100);
    } else if (is_numeric_char(accName.charAt(0))) {
      accName = accName.replace(/^.{1}/g, generateRandomLetter());
    }
    accName = accName.substr(0, 24);
    dispatch({
      type: 'UPDATE_ACC_NAME',
      payload: accName,
    });
    setAccountName(accName);
  };

  const updateSETrialAccountName = (companyname) => {
    // similar check for company name as cloud account name
    let companyName = companyname.replace(/[^a-zA-Z0-9]/g, '');
    if (companyName === '') {
      const tempName = generateRandomLetter() + generateRandomNumber(1, 100);
      setAccountName(tempName);
    }
    if (is_numeric_char(companyName.charAt(0))) {
      companyName = companyName.replace(/^.{1}/g, generateRandomLetter());
    }
    setAccountName(companyName.toLowerCase().substr(0, 24));
  };

  const onChangeHandler = (e, action, payload) => {
    if (action === 'UPDATE_ACC_NAME') {
      setValidAccountNameFlag(false);
    }
    dispatch({
      type: `${action}`,
      payload,
    });
    setTouched({ ...touched, [e.target.name]: true });
  };

  const setTaxFieldError = (value, isValid = true, message = '') => {
    if (isValid) {
      setError((error) => ({
        ...error,
        taxFields: {
          isValid: true,
          errorMsg: '',
          value,
        },
      }));
    } else {
      setError((error) => ({
        ...error,
        taxFields: {
          isValid: false,
          errorMsg: message,
          value,
        },
      }));
    }
  };

  const onEmailChange = (e, action, payload, pattern) => {
    const val = _.trim(e.target.value);
    const { name } = e.target;
    const translatedName = t(`signup.${name}`);
    dispatch({
      type: `${action}`,
      payload: val.toLowerCase(),
    });

    if (verifyEmailBtnClicked >= 1 || e.detail || error.email.isValid === false) {
      if (val.length === 0) {
        setError((error) => ({
          ...error,
          ...{
            [name]: {
              isValid: false,
              value: '',
              errorMsg: /* eslint-disable-next-line */
              <Trans i18nKey="personalInfo.requiredMsg">
                A value for
                {translatedName}
                {' '}
                is required.
              </Trans>,
            },
          },
        }));
      } else if (!val.match(pattern) || val === '') {
        setError((error) => ({ ...error, ...{ [name]: { isValid: false, value: '', errorMsg: t('regExpr.email') } } }));
      } else {
        setError((error) => ({ ...error, ...{ [name]: { isValid: true, value: val, errorMsg: '' } } }));
      }
    }
  };

  const onBlurHandler = (e, pattern, action) => {
    let val = _.trim(e.target.value);
    const { name } = e.target;
    name === 'email'
      ? dispatch({
        type: `${action}`,
        payload: val.toLowerCase(),
      }) : dispatch({
        type: `${action}`,
        payload: val,
      });
    const translatedName = t(`signup.${name}`);

    setTouched({ ...touched, [e.target.name]: false });
    SendAction({ action: `input_${name}`, value: val, userSession: state.user_session });

    if (val.length === 0) {
      setError((error) => ({
        ...error,
        ...{
          [name]: {
            isValid: false,
            value: '',
            errorMsg: /* eslint-disable-next-line */
              <Trans i18nKey="personalInfo.requiredMsg">
                A value for
                {translatedName}
                {' '}
                is required.
              </Trans>,
          },
        },
      }));
    } else if (!val.match(pattern) || val === '') {
      navTrack('oci-signup', state.language, `s1-entered-${name}`, `invalid-${name}`);
      setError((error) => ({ ...error, ...{ [name]: { isValid: false, value: '', errorMsg: t(`regExpr.${name}`) } } }));
    } else if (name === 'email') {
      const match = val.match(/\.([^.]+)$/) || [];
      if (match.length > 1) {
        if (emailsTld.length > 0 && !emailsTld.includes(match[1].toUpperCase())) {
          navTrack('oci-signup', state.language, `s1-entered-${name}`, `invalid-${name}`);
          setError((error) => ({ ...error, ...{ [name]: { isValid: false, value: '', errorMsg: t('regExpr.emailTld') } } }));
        } else {
          const plusIndex = val.indexOf('+');
          const domainIndex = val.indexOf('@');
          if (plusIndex !== -1) {
            val = val.substring(0, plusIndex) + val.substring(domainIndex, val.length);
            dispatch({ type: 'UPDATE_NAME', payload: val.toLowerCase() });
            setTaggedEmailInfo(t('signup.taggedEmailInfo'));
          }
        }
      }
    } else {
      navTrack('oci-signup', state.language, `s1-entered-${name}`, `valid-${name}`);
      setError((error) => ({ ...error, ...{ [name]: { isValid: true, value: val, errorMsg: '' } } }));
    }
  };

  const toggleEmailModal = () => {
    setShow(!show);
    toggleCallback();
  };

  const toggleDomainClaimedModal = () => {
    setDomainClaimed(!domainClaimed);
    // Add class to body for overlay
    toggleCallback();
  };

  const toggleErrorModal = () => {
    setDomainClaimedError(!domainClaimedError);
    SendAction({ action: 'Get claimed Domain failed', value: 'Error Dialog Pop-up displayed', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  const organizationNameCheck = (e, isRequired = false) => {
    const val = e.target.value;
    const { name } = e.target;
    const translatedName = t(`signup.${name}`);
    SendAction({ action: `input_${name}`, value: val, userSession: state.user_session });
    setTouched((touched) => ({ ...touched, ...{ [name]: false } }));

    if (val === '') {
      if (isRequired) {
        setError((error) => ({
          ...error,
          ...{
            [name]: {
              isValid: false,
              val: '',
              errorMsg: /* eslint-disable-next-line react/jsx-indent */
                <Trans i18nKey="personalInfo.requiredMsg">
                  A value for
                  {translatedName}
                  {' '}
                  is required.
                </Trans>,
            },
          },
        }));
      } else {
        setError((error) => ({ ...error, ...{ [name]: { isValid: true, val: '', errorMsg: '' } } }));
      }
    } else if (!val.match(regExp.organization)) {
      setError((error) => ({ ...error, ...{ [name]: { isValid: false, val: '', errorMsg: t(`regExpr.${name}`) } } }));
    } else {
      setError((error) => ({ ...error, ...{ [name]: { isValid: true, val, errorMsg: '' } } }));
    }
  };

  const closeModal = () => {
    setShow(!show);
    const urlParams = new URLSearchParams(window.location.search);
    let queryString = urlParams.delete('verify_email');
    queryString = urlParams.toString();
    window.location = new URL(`${window.location.href.split('?')[0]}?${queryString}`);
    SendAction({ action: 'POST createAccount API failed', value: 'Reload signup page', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  const closeDomainErrorModal = () => {
    setShow(!domainClaimedError);
    const urlParams = new URLSearchParams(window.location.search);
    let queryString = urlParams.delete('verify_email');
    queryString = urlParams.toString();
    window.location = new URL(`${window.location.href.split('?')[0]}?${queryString}`);
    SendAction({ action: 'Domain Claim API failed', value: 'Reload signup page', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  const closeDomainClaimedModal = () => {
    setDomainClaimed(!domainClaimed);
    const urlParams = new URLSearchParams(window.location.search);
    let queryString = urlParams.delete('verify_email');
    queryString = urlParams.toString();
    window.location = new URL(`${window.location.href.split('?')[0]}?${queryString}`);
    SendAction({ action: 'Domain is claimed', value: 'Reload signup page', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  const closeBannerModal = () => {
    dispatch({
      type: 'UPDATE_SHOW_BANNER',
      payload: !state.showBanner,
    });
    // Add class to body for overlay
    toggleCallback();
  };

  const closeBlockedDomainModal = () => {
    dispatch({
      type: 'UPDATE_DOMAIN_BLOCKED',
      payload: !state.domainBlocked,
    });
    const urlParams = new URLSearchParams(window.location.search);
    let queryString = urlParams.delete('verify_email');
    queryString = urlParams.toString();
    window.location = new URL(`${window.location.href.split('?')[0]}?${queryString}`);
    SendAction({ action: 'Domain is Blocked', value: 'Reload signup page', userSession: state.user_session });
    // Add class to body for overlay
    toggleCallback();
  };

  const verifyEmail = (e) => {
    emailVerification(true);
    navTrack('oci-signup', state.language, 's1-clicked-verify-my-email-button', 'send-link-api-called');
    SendAction({ action: 'User clicked Verify my email button', value: 'GET SendSecurityLink API called', userSession: state.user_session });
    SendLogs({ severity: '20000', message: `User clicked Verify my email button, EmailID: ${state.username}`, userSession: state.user_session });

    let val = state.username;
    const name = 'email';
    const translatedName = t(`signup.${name}`);
    const pattern = regExp.email;
    if (val.length === 0) {
      setError((error) => ({
        ...error,
        ...{
          [name]: {
            isValid: false,
            value: '',
            errorMsg: /* eslint-disable-next-line react/jsx-indent */
              <Trans i18nKey="personalInfo.requiredMsg">
                A value for
                {translatedName}
                {' '}
                is required.
              </Trans>,
          },
        },
      }));
      emailVerification(false);
    } else if (!val.match(pattern) || val === '') {
      navTrack('oci-signup', state.language, `s1-entered-${name}`, `invalid-${name}`);
      SendLogs({ severity: '40000', message: `User entered invalid email, EmailID: ${state.username}`, userSession: state.user_session });
      setError((error) => ({ ...error, ...{ [name]: { isValid: false, value: '', errorMsg: t('regExpr.email') } } }));
      emailVerification(false);
    } else {
      navTrack('oci-signup', state.language, `s1-entered-${name}`, `valid-${name}`);
      SendLogs({ severity: '20000', message: `User entered valid email, EmailID: ${state.username}`, userSession: state.user_session });
      const plusIndex = val.indexOf('+');
      const domainIndex = val.indexOf('@');
      if (plusIndex !== -1) {
        val = val.substring(0, plusIndex) + val.substring(domainIndex, val.length);
        dispatch({ type: 'UPDATE_NAME', payload: val });
        setTaggedEmailInfo(t('signup.taggedEmailInfo'));
      }
      setError((error) => ({ ...error, ...{ [name]: { isValid: true, value: val, errorMsg: '' } } }));
    }

    setVerifyEmailBtnClicked(verifyEmailBtnClicked + 1);
  };

  React.useEffect(() => {
    if (sendEmail === true) {
      // eslint-disable-next-line max-len
      if (state.lastName.match(regExp.personName) && state.firstName.match(regExp.personName) && verifyEmailCount <= 3) {
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const defaultValues = urlParams.get('defaultValues');
        const uriDestination = urlParams.get('uriDestination');
        const body = JSON.stringify({
          email: state.username,
          country: state.country,
          firstName: state.firstName,
          lastName: state.lastName,
          programType: _.toUpper(programType),
          trialType: _.toUpper(trialType),
          sourceType,
          defaultValues: defaultValues === null ? '' : encodeURIComponent(defaultValues),
          uriDestination: uriDestination === null ? '' : encodeURIComponent(uriDestination),
          userSessionId: state.user_session,
          isOpayOutage: state.isOpayOutage,
          selectedPromoId: state.selectedPromo && state.selectedPromo.promoId,
          captchaJwtToken: state.captchaJwtToken,
          oracleEmployee: state.oracleEmployee,
          referrer: state.referrer,
        });
        fetchSecurityLink(body).then(async (res) => {
          setLoading(false);
          if (res.status === 200) {
            setCounter(115);
            SendMetrics({ name: 'SendEmail.Count.Metrics.Success', value: 1, userSession: state.user_session });
            SendLogs({ severity: 20000, message: `GET SendSecurityLink API successful, EmailID: ${state.username}`, userSession: state.user_session });
            navTrack('oci-signup', state.language, 's1-verification-link-sent', 'email-sent');
          } if (res.status === 403) {
            const result = await res.json();
            if (!_.startsWith(result.message, 'Forbidding the oracle employee flow')) {
              setApi403Error(result.message);
              if (_.startsWith(result.message, 'Too many requests')) {
                SendLogs({
                  severity: 20000,
                  message: `too many requests, EmailID: ${state.username}`,
                  userSession: state.user_session,
                });
                SendMetrics({ name: 'SendEmail.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
              } else {
                SendMetrics({ name: 'SendEmail.Count.Metrics.Error', value: 1, userSession: state.user_session });
                SendLogs({
                  severity: 40000,
                  message: `SendSecurityLink API Error, EmailID: ${state.username}`,
                  userSession: state.user_session,
                });
              }
            }
          } else if (res.status >= 400) {
            SendMetrics({ name: 'SendEmail.Count.Metrics.Error', value: 1, userSession: state.user_session });
            SendLogs({ severity: 20000, message: `GET SendSecurityLink API failure, EmailID: ${state.username}`, userSession: state.user_session });
            dispatch({
              type: 'SEND_EMAIL',
              payload: false,
            });
            setError((error) => ({ ...error, ...{ email: { isValid: false, val: '', errorMsg: 'Email configuration error.' } } }));
          }
          SendMetrics({ name: 'SendEmail.Count.Metrics', value: 1, userSession: state.user_session });
        });
      } else {
        emailVerification(false);
        dispatch({
          type: 'UPDATE_PROMO_ERROR',
          payload: true,
        });
      }
    }
  }, [verifyEmailCount]);

  React.useEffect(() => {
    if (token !== '' && getFeatureFlag('captcha', featureFlags)) {
      fetchCaptchaToken(JSON.stringify({ token })).then(async (res) => {
        if (res.status === 200) {
          const result = await res.json();
          SendMetrics({ name: 'verifyCaptchaToken.Count.Metrics.Success', value: 1, userSession: state.user_session });
          SendLogs({ severity: 20000, message: `POST verifyCaptchaToken API Success, EmailID: ${state.username}`, userSession: state.user_session });
          navTrack('oci-signup', state.language, 's1-Captcha', 'Token-verification-success');
          setCaptchaJwtToken(result.jwtToken);
          setCaptchaToken(true);
          dispatch({
            type: 'UPDATE_CAPTCHA_JWT_TOKEN',
            payload: result.jwtToken,
          });
        } else if (res.status === 400) {
          SendMetrics({ name: 'verifyCaptchaToken.Count.Metrics.Fail', value: 1, userSession: state.user_session });
          SendLogs({ severity: 40000, message: `POST verifyCaptchaToken API Fail, EmailID: ${state.username}`, userSession: state.user_session });
          navTrack('oci-signup', state.language, 's1-Captcha', 'Token-verification-fail');
        } else if (res.status === 403) {
          const result = await res.json();
          setApi403Error(result.message);
          if (_.startsWith(result.message, 'Too many requests')) {
            SendMetrics({ name: 'verifyCaptchaToken.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
            SendLogs({ severity: 40000, message: `too many requests, EmailID: ${state.username}`, userSession: state.user_session });
            navTrack('oci-signup', state.language, 's1-Captcha', 'Token-verification-fail');
          } else {
            SendMetrics({ name: 'verifyCaptchaToken.Count.Metrics.Error', value: 1, userSession: state.user_session });
            SendLogs({ severity: 40000, message: `verifyCaptchaToken Error, EmailID: ${state.username}`, userSession: state.user_session });
          }
        }
      }).catch((error) => {
        console.log(error);
        SendLogs({ severity: 40000, message: `POST verifyCaptchaToken API Fail error, EmailId: ${error.message}${state.username}`, userSession: state.user_session });
        navTrack('oci-signup', state.language, 's1-Captcha', 'Token-verification-fail');
        SendMetrics({ name: 'verifyCaptchaToken.Count.Metrics.Error', value: 1, userSession: state.user_session });
      });
    }
  }, [token]);

  const updateEmail = (e) => {
    onEmailChange(e, 'UPDATE_NAME', e.target.value.toLowerCase(), regExp.email);
    dispatch({
      type: 'SHOW_ACCOUNT_NOT_UNIQUE',
      payload: false,
    });
  };

  const handleVerificationSuccess = (token, ekey) => {
    SendLogs({ severity: 20000, message: `Puzzle completed successfully, Email:ID: ${state.username}`, userSession: state.user_session });
    SendMetrics({ name: 'CaptchaTokenGeneration.Count.Metrics.Success', value: 1, userSession: state.user_session });
    navTrack('oci-signup', state.language, 's1-Captcha', 'Puzzle-completed-successfully');
    setToken(token);
    setCaptchaTimer(120);
  };

  const handleCaptchaError = (err) => {
    SendLogs({ severity: 40000, message: `Captcha Modal Failure, Email:ID: ${state.username}`, userSession: state.user_session });
    SendMetrics({ name: 'CaptchaTokenGeneration.Count.Metrics.Fail', value: 1, userSession: state.user_session });
    navTrack('oci-signup', state.language, 's1-Captcha', 'Puzzle-modal-error');
    setCaptchaToken(false);
  };

  const onTokenExpiration = () => {
    SendLogs({ severity: 40000, message: `Captcha Token Expired, Email:ID: ${state.username}`, userSession: state.user_session });
    SendMetrics({ name: 'CaptchaTokenGeneration.Count.Metrics.Expire', value: 1, userSession: state.user_session });
    navTrack('oci-signup', state.language, 's1-Captcha', 'Token-expired');
  };

  const onCaptchaLoad = () => {
    SendLogs({ severity: 20000, message: `Captcha Modal load, Email:ID: ${state.username}`, userSession: state.user_session });
    SendMetrics({ name: 'CaptchaTokenGeneration.Count.Metrics.Load', value: 1, userSession: state.user_session });
  };

  const canVerifyEmail = () => {
    let flag = false;
    _.forIn(touched, (value, key) => {
      if (value === true) {
        flag = true;
      }
    });

    const basicFormValidation = state.country !== '' && (error.firstName.value !== '') && (error.lastName.value !== '') && !verifyEmailLoading && !loading;

    if (flag || verifyEmailCount >= 3) {
      return true;
    } if (getFeatureFlag('captcha', featureFlags)) {
      return !(captchaToken && basicFormValidation && error.email.isValid);
    } if (state.counter === 0 || !state.sendEmail) {
      return !basicFormValidation;
    }
    return !(basicFormValidation && error.email.isValid);
  };

  const getSiteKey = () => {
    const devEnvs = ['miglab', 'pool2', 'pintlab', 'pintlab2', 'dev'];
    if (_.includes(devEnvs, signup_environment)) {
      return '10000000-ffff-ffff-ffff-000000000001';
    } if (signup_environment === 'stage') {
      return '9ab1936f-0113-4849-a97a-3ac5c93962cd';
    } if (signup_environment === 'prod-oraclecloud') {
      return '0e70ac83-3771-423c-902c-c7866779e13c';
    } if (signup_environment === 'prod-oracle') {
      return 'd857545c-9806-4f9e-8e9d-327f565aeb46';
    }
  };

  const isCountryFieldDisabled = () => !!((!_.isEmpty(state.selectedPromo)
    && !_.isEmpty(state.selectedPromo.country))
    || !_.isEmpty(state.emailToken) || !_.isEmpty(verifyEmailToken));

  const isFirstNameFieldDisabled = () => !!((!_.isEmpty(state.selectedPromo)
    && !_.isEmpty(state.selectedPromo.firstName)) || !_.isEmpty(state.emailToken));

  const isLastNameFieldDisabled = () => !!((!_.isEmpty(state.selectedPromo)
    && !_.isEmpty(state.selectedPromo.lastName)) || !_.isEmpty(state.emailToken));

  const isOrganizationFieldDisabled = () => !!(!_.isEmpty(state.selectedPromo)
    && !_.isEmpty(state.selectedPromo.customerName));

  return (
    <div>
      <div className="row">
        <div className="outages">
          <OutageBanner />
        </div>
      </div>
      {state.nextStep ? <></>
        : (
          <div className="contentContainer">
            <div className="upperContentContainer">
              <ThrottlingBanner />
              { state.apiError && <Notifier type="error" message={t('signup.apiError')} position="apiError" />}
              <RedwoodModal onClose={(e) => closeBannerModal()} show={state.showBanner} buttonName={bannerLevel !== 'error' ? 'Close' : ''}>
                <div>
                  <Icon name={bannerLevel} />
                  {bannerTitle}
                </div>
                <div>
                  <h3>{bannerMessage}</h3>
                </div>
              </RedwoodModal>
              <RedwoodModal onClose={(e) => closeModal()} title={t('emailVerification.failureTitle')} show={show} buttonName={t('signup.retry', 'Retry')}>
                <p>{t('emailVerification.failureText')}</p>
                <p>{t('emailVerification.failureText1')}</p>
              </RedwoodModal>
              <RedwoodModal onClose={(e) => closeDomainClaimedModal()} title={t('emailVerification.failureTitle')} show={domainClaimed} buttonName={t('signup.retry', 'Retry')}>
                <p>
                  <Trans i18nKey="emailVerification.emailDomainClaimFailure">
                    {' '}
                    {/* eslint-disable-next-line max-len */}
                    Your email domain already has an Oracle Cloud Infrastructure tenancy. An email will be sent to
                    {{ administrator_email }}
                    {' '}
                    for them to contact you.
                  </Trans>
                </p>
              </RedwoodModal>
              <RedwoodModal onClose={(e) => closeDomainErrorModal()} title={t('agreement.errorTitle')} show={domainClaimedError} buttonName={t('agreement.tryMeButton')}>
                <p>{t('agreement.errorDialog1')}</p>
                <p>
                  <Trans i18nKey="agreement.errorDialog2">
                    <a href="https://signup.cloud.oracle.com/" target="_blank" rel="noopener noreferrer">Refresh</a>
                    {' '}
                    and start the sign-up process over, this problem may be temporary.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="agreement.errorDialog3">
                    Otherwise, if the problem persists you can
                    <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target="_blank" rel="noopener noreferrer">contact chat support</a>
                    {' '}
                    and we will make sure to get you through the sign-up process.
                  </Trans>
                </p>
              </RedwoodModal>
              <RedwoodModal onClose={(e) => closeBlockedDomainModal()} title={t('emailVerification.failureTitle')} show={state.domainBlocked} buttonName={t('signup.retry', 'Retry')}>
                <p><Trans i18nKey="emailVerification.emailBlockedDomainFailure"> The email address entered is not supported. Please try signing up with a different email address or email domain.</Trans></p>
              </RedwoodModal>
              <form autoComplete="off">
                <fieldset className="signup-fieldset" disabled={state.showBanner}>
                  <h2 className="title6">{t('signup.accountInformation', 'Account Information')}</h2>
                  <RedwoodSelect
                    label={t('signup.country', 'Country/Territory')}
                    name="country"
                    required
                    value={state.countryObject}
                    disabled={isCountryFieldDisabled()}
                    data={countriesList()}
                    error={invalid}
                    errorMsg={(
                      <Trans i18nKey="signup.countryError">
                        {/* eslint-disable-next-line max-len */}
                        Contact Sales Self-service for your country is currently unavailable; please contact
                        <a href="https://www.oracle.com/corporate/contact/global.html#americas" target="_blank" rel="noreferrer">Oracle Sales</a>
                        to assist you.
                      </Trans>
)}
                    onChange={(e) => {
                      SendMetrics({ name: 'Country.Selection.Count.Metrics', value: 1, userSession: state.user_session });
                      SendAction({ action: 'input_country', value: e.value, userSession: state.user_session });
                      const countryInfo = countries.countries[e.value];
                      if (countryInfo === undefined) {
                        setInvalid(true);
                        dispatch({
                          type: 'UPDATE_COUNTRY',
                          payload: ['', e, {}, '', ''],
                        });
                      } else {
                        const orgId = getOrgId(countryInfo.tc.OrgName);
                        setInvalid(false);
                        dispatch({
                          type: 'UPDATE_COUNTRY',
                          payload: [e.value, e, countryInfo, orgId],
                        });
                      }
                    }}
                    onBlur={(e) => {
                      navTrack('oci-signup', state.language, 's1-selected-country', state.country);
                    }}
                  />

                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <RedwoodInputText
                        type="text"
                        placeholder={t('signup.firstNamePlaceholder')}
                        label={t('signup.firstName', 'First Name')}
                        disabled={isFirstNameFieldDisabled()}
                        name="firstName"
                        id="firstName"
                        value={state.firstName}
                        maxLength={constants.nameCharLength}
                        required
                        error={error.firstName.errorMsg}
                        valid={error.firstName.isValid}
                        onChange={(e) => onChangeHandler(e, 'UPDATE_FIRST_NAME', e.target.value)}
                        onBlur={(e) => { onBlurHandler(e, regExp.personName, 'UPDATE_FIRST_NAME'); }}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <RedwoodInputText
                        type="text"
                        placeholder={t('signup.lastNamePlaceholder')}
                        label={t('signup.lastName', 'Last Name')}
                        disabled={isLastNameFieldDisabled()}
                        name="lastName"
                        id="lastName"
                        value={state.lastName}
                        maxLength={constants.nameCharLength}
                        required
                        error={error.lastName.errorMsg}
                        valid={error.lastName.isValid}
                        onChange={(e) => onChangeHandler(e, 'UPDATE_LAST_NAME', e.target.value)}
                        onBlur={(e) => onBlurHandler(e, regExp.personName, 'UPDATE_LAST_NAME')}
                      />
                    </div>
                    {
                      !_.isEmpty(verifyEmailToken)
                      && (_.get(state, 'countryInfo.personalInfo.alternateName', '') === 'R')
                      && (
                        <div className="col-xs-12">
                          <RedwoodInputText
                            type="text"
                            placeholder={t('signup.alternateNamePlaceholder', 'Enter your full name in English characters')}
                            label={t('signup.alternateName', 'Alternate name')}
                            name="alternateName"
                            id="alternateName"
                            value={state.alternateCustomerName}
                            required
                            error={error.alternateName.errorMsg}
                            valid={error.alternateName.isValid}
                            onChange={(e) => onChangeHandler(e, 'UPDATE_ALTERNATE_NAME', e.target.value)}
                            onBlur={(e) => onBlurHandler(e, regExp.altEnglishName, 'UPDATE_ALTERNATE_NAME')}
                            autoFocus
                          />
                        </div>
                      )
                    }
                  </div>

                  <RedwoodInputText
                    type="text"
                    placeholder={t('signup.emailPlaceholder', 'Enter the email address to use when signing in to the Console')}
                    label={t('signup.email', 'Email')}
                    name="email"
                    id="email"
                    disabled={!!(!_.isEmpty(state.selectedPromo) || !_.isEmpty(state.emailToken))}
                    required
                    value={state.username}
                    valid={error.email.isValid}
                    error={error.email.errorMsg}
                    info={!!_.isEmpty(taggedEmailInfo)}
                    infoMsg={taggedEmailInfo}
                    onChange={(e) => {
                      updateEmail(e);
                    }}
                    onBlur={(e) => {
                      onBlurHandler(e, regExp.email, 'UPDATE_NAME');
                    }}
                  />
                  {getFeatureFlag('captcha', featureFlags) && _.isEmpty(state.selectedPromo) && _.isEmpty(verifyEmailToken)
          && (
          <form>
            <br />
            <HCaptcha
              sitekey={getSiteKey()}
              onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
              onError={(err) => handleCaptchaError(err)}
              onExpire={() => onTokenExpiration()}
              onLoad={() => onCaptchaLoad()}
            />
          </form>
          )}
                  {!state.sendEmail && <Notifier type="error" message="Email verification link failed to send" position="sendEmailFailure" />}
                  <ExistingEmailDialog />

                  <PromoSelection
                    email={error.email}
                    updateEmailError={updateEmailError}
                    updateAccountName={updateAccountName}
                    emailVerification={emailVerification}
                    verifyEmailBtnClicked={verifyEmailBtnClicked}
                    sendVerificationLink={sendVerificationLink}
                    captchaJwtToken={captchaJwtToken}
                    promoAvailableCheck={promoAvailablecheck}
                    toggleCallBack={toggleCallback}
                    resetError={resetError}
                    updateSETrialAccountName={updateSETrialAccountName}
                    programType={programType}
                    trialType={trialType}
                  />

                  {!(counter === 0) && !state.validateEmail && <Notifier type="info" position="emailVerificationTimer" message={t('signup.emailVerifyLinkSent', 'Email verification link sent')} emailTimer={timer(counter)} count={3 - verifyEmailCount} />}
                  {verifyEmailCount === 3 && counter === 0 && maxAttemptsError && <Notifier type="error" position="emailMaxAttemptsError" message={t('emailVerification.emailMaxAttemptsTitle')} />}
                  {state.promoValidationError && <Notifier type="error" position="promoValidationError" message={t('emailVerification.emailPromoValidationTitle')} />}
                  {counter === 0 && (_.isEmpty(state.emailToken) && state.validateEmail === false) && <Button data-testid="btn-verify-email" id="verifyMyEmail" type="solid" disabled={canVerifyEmail()} onClick={(e) => verifyEmail(e)} text={t('signup.verifyMyEmail', 'Verify my email')} loading={verifyEmailLoading || loading} />}

                  {(state.validateEmail === true || !_.isEmpty(state.emailToken))
        && (
        <>
          <PromoLoading
            emailToken={state.emailToken}
            updateSETrialAccountName={updateSETrialAccountName}
          />
          <PasswordComponent
            updateTouched={updateTouched}
            updatePasswordErrorFlag={passwordErrorFlag}
            passwordError={passwordError}
          />

          <TaxRegistrationComponent
            onChangeHandler={onChangeHandler}
            onBlurHandler={onBlurHandler}
            organizationNameCheck={organizationNameCheck}
            taxFieldCheck={setTaxFieldError}
            error={error}
          />

          <RedwoodInputText
            type="text"
            placeholder={t('signup.accountNamePlaceholder')}
            label={t('signup.accountName')}
            name="accountName"
            id="accountNameInput"
            required
            value={state.accountName}
            valid={error.accountName.isValid}
            error={error.accountName.errorMsg}
            info={!error.accountName.isValid}
            infoMsg={(
              <Trans i18nKey="signup.accountNameInfoUpdated">
                {/* eslint-disable-next-line max-len, react/no-unescaped-entities */}
                Avoid including personal or confidential information when creating a cloud account name, since it cannot be changed later, and the name will be visible in the login URL. <br /><br />The Cloud Account Name must be lowercase, start with a letter, contain no spaces or special characters, and be 25 or less characters long. The name will be assigned to your company's or organization's environment when signing into the Console.
              </Trans>
              )}
            onChange={(e) => onChangeHandler(e, 'UPDATE_ACC_NAME', e.target.value.toLowerCase())}
            onBlur={(e) => onBlurHandler(e, regExp.identityDomainName, 'UPDATE_ACC_NAME')}
          />
          <RedwoodSelect
            placeholder={t('signup.homeRegionPlaceholder')}
            label={t('signup.homeRegion')}
            name="homeRegion"
            required
            value={state.homeRegion}
            data={state.homeRegions}
            enableArmCapacityWarning
            info
            warnMsg={(
              <Trans i18nKey="signup.armOCIInfo">
                {/* eslint-disable-next-line max-len */}
                Because of high demand for Arm Ampere A1 Compute capacity in the South Korea Central (Seoul) and Japan East (Tokyo), A1 instance availability in these regions is limited. If you plan to create A1 instances, we recommend choosing another region as your home region.
                <br />
              </Trans>
)}
            infoMsg={(
              <Trans i18nKey="homeregion.content">
                {/* eslint-disable-next-line max-len */}
                Your <a href="https://docs.oracle.com/en-us/iaas/Content/Identity/Tasks/managingregions.htm#The" target="_blank" rel="noopener noreferrer" aria-label={t('homeregion.opensInNewWindowAriaLabel', 'Opens in new window')}>home region</a> is the geographic location where your account, identity resources, and primary identity domain will be created. Your Home Region selection cannot be changed after this step in the sign-up process. Some cloud services may not be available in all regions. <a href="https://www.oracle.com/cloud/public-cloud-regions/service-availability/#northamerica" target="_blank" rel="noopener noreferrer" aria-label={t('homeregion.opensInNewWindowAriaLabel', 'Opens in new window')}>See Regions</a> for service availability.
              </Trans>
)}
            onChange={(e) => {
              SendAction({ action: 'input_homeRegion', value: e.value.id, userSession: state.user_session });
              dispatch({
                type: 'UPDATE_HOME_REGION',
                payload: e,
              });
            }}
            onBlur={(e) => {
              if (state.homeRegion !== null) {
                navTrack('oci-signup', state.language, 's1-selected-home-region', state.homeRegion.value.id);
              }
            }}
          />
          <div className="homeRegionAck">
            <RedwoodCheckbox
              checked={ackHomeRegion}
              onChange={() => setAckHomeRegion(!ackHomeRegion)}
            >
              { /* eslint-disable-next-line max-len */ }
              <Trans i18nKey="homeregion.checkbox">By selecting this box, you acknowledge that your home region cannot be changed after this step in the signup process.</Trans>
            </RedwoodCheckbox>
          </div>
        </>
        )}
                </fieldset>
              </form>

            </div>
            <TermsOfUseComponent status={canContinue()} />
          </div>
        )}

    </div>
  );
};

export default SignUpFormContainer;
