import React from "react";
import Button from "../Button/Button";
import "./RedwoodModal.css";
import Icon from "../Icon/Icon";

/* For implementation:
    const [show, setShow] = React.useState(false);

    <button  onClick={e => {toggleModal();}}>show Modal </button>

    <RedwoodModal onClose={e => {toggleModal();}} show={show} title="tile">
        Message in Modal
    </RedwoodModal>
*/

export default class RedwoodModal extends React.Component {

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div data-testid={this.props.testId} className={this.props.fullContainer ? 'modalContainer' : '' }>
                <div className={this.props.noChrome ? 'modalNoChrome' : 'modal' } id="modal">
                    <div className={!this.props.noPadding ? 'modalPadding' : ''}>

                        {!this.props.noChrome &&
                            <h2>{this.props.title}</h2>
                        }

                        {this.props.closeBtn &&
                            <Icon name="close" onClick={this.onClose} />
                        }

                        <div className="content">{this.props.children}</div>
                        <div className="actions">
                            {this.props.buttonName &&
                            <Button
                                type="solid"
                                onClick={this.props.btnFn ? this.props.btnFn : this.onClose}
                                text={this.props.buttonName}>
                            </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
