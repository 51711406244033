/*
eslint-disable no-shadow,camelcase,no-unused-vars,no-console,
react/prop-types,import/no-named-as-default,import/no-named-as-default-member
*/
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { base_url } from '../config/apiConfig';
import RedwoodModal from '../common/Modal/RedwoodModal';
import { timer } from './Helpers';

const KeepAlive = (props) => {
  const [counter, setCounter] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState();
  const [timeCounter, setTimeCounter] = React.useState(300);
  const { toggleCallback } = props;
  const { t } = useTranslation();

  // keepAlive API call is made at 0 min and 60 min

  const toggleModal = () => {
    setShow(!show);
    toggleCallback();
  };

  const reloadPage = () => {
    setShow(!show);
    window.location.reload();
    toggleCallback();
  };

  const onContinue = (e) => {
    setShow(!show);
    setCounter(0);
    toggleCallback();
    return () => clearInterval(intervalId);
  };

  React.useEffect(() => {
    const id = counter < 13 && setInterval(() => {
      setCounter((counter) => counter + 1);
      setIntervalId(id);
    }, 300000);
    return () => clearInterval(id);
  }, [counter]);

  React.useEffect(() => {
    if (counter < 13) {
      fetch(
        `${base_url}/keepAlive`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      )
        .then(async (res) => {
          if (counter === 11) {
            toggleModal();
          }
        }).catch((error) => {
          console.log(error);
        });
    }
  },
  [counter]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (counter === 1) {
      const timer = isSubscribed
        ? timeCounter > 0 && setInterval(() => setTimeCounter(timeCounter - 1), 1000) : null;
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line no-return-assign
    return () => (isSubscribed = false);
  }, [timeCounter, counter]);

  const displayTimer = timer(timeCounter);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {counter === 11
    && (
    <RedwoodModal onClose={(e) => onContinue()} show={show} title={t('keepAlive.dialogTitle1')} buttonName="Continue">
      <Trans i18nKey="keepAlive.dialogContent1">
        Your session will expire in
        {{ displayTimer }}
        {' '}
        minutes
      </Trans>
    </RedwoodModal>
    )}
      {counter >= 12
    && (
    <RedwoodModal onClose={(e) => reloadPage()} show={show} title={t('keepAlive.dialogTitle2')} buttonName="Close">
      {t('keepAlive.dialogContent2')}
    </RedwoodModal>
    )}
    </>
  );
};

export default KeepAlive;
