/*
eslint-disable camelcase
*/
import * as React from 'react';
import './OutageBanner.css';
import { useTranslation, Trans } from 'react-i18next';
import { outage_dashboard_url, is_pintlab_env } from '../../config/apiConfig';
import { fetchPlannedOutageBanner, fetchUnplannedOutageBanner } from '../../CourierService/FetchService';

const OutageBanner = () => {
  const { t } = useTranslation();
  const [plannedOutages, setPlannedOutages] = React.useState([]);
  const [unplannedOutages, setUnplannedOutages] = React.useState([]);

  React.useEffect(() => {
    if (is_pintlab_env) {
      fetchPlannedOutageBanner()
        .then(async (res) => {
          const today = new Date();
          if (res.status === 200) {
            const result = await res.json();
            setPlannedOutages(result.items
              .filter((it) => new Date(it.outage_end) <= today)
              .filter((it) => new Date(it.outage_start) <= today)
              .map((it) => ({
                id: it.jira_number, message: it.summary, start: it.outage_start, end: it.outage_end,
              })));
          }
        }).catch((e) => {
          // eslint-disable-next-line
          console.log('Failed to fetch planned outages: ', e);
        });
    }

    if (is_pintlab_env) {
      fetchUnplannedOutageBanner()
        .then(async (res) => {
          const today = new Date();
          if (res.status === 200) {
            const result = await res.json();
            setUnplannedOutages(result.items
              .filter((it) => new Date(it.outage_end) <= today)
              .filter((it) => new Date(it.outage_start) <= today)
              .map((it) => ({ id: it.jira_number, message: it.summary })));
          }
        }).catch((e) => {
          // eslint-disable-next-line
          console.log('Failed to fetch unplanned outages: ', e);
        });
    }
  }, []);

  if (plannedOutages.length === 0 && unplannedOutages.length === 0) return null;

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="row">
      {plannedOutages && plannedOutages.length > 0 && (
        <div className="col-xs-12 planned-outages">
          { t('signup.plannedOutagesDetected', 'There are active planned outages at this time') }
          <ul>
            {plannedOutages.map((out) => (
              <li key={out.id}>
                {out.id && (<a href={`https://jira.oci.oraclecorp.com/browse/${out.id}`}>{out.id}</a>)}
                {out.id ? ' - ' : ' '}
                {out.message}
              </li>
            ))}
          </ul>
        </div>
      )}
      {unplannedOutages && unplannedOutages.length > 0 && (
        <div className="col-xs-12 unplanned-outages">
          { t('signup.unplannedOutagesDetected', 'There are active unplanned outages at this time') }
          <ul>
            {unplannedOutages.map((out) => (
              <li key={out.id}>
                {out.id && (<a href={`https://jira.oci.oraclecorp.com/browse/${out.id}`}>{out.id}</a>)}
                {out.id ? ' - ' : ' '}
                {out.message}
              </li>
            ))}
          </ul>
        </div>
      )}
      {(plannedOutages || unplannedOutages) && outage_dashboard_url !== '' && (
        <div className="col-xs-12">
          <p>
            <Trans i18nKey="signup.outagesDashboard">
              You can view all outages
              <a href={outage_dashboard_url} target="_blank" rel="noopener noreferrer">here</a>
            </Trans>
          </p>
        </div>
      )}
    </div>
  );
};

export default OutageBanner;
