/*
eslint-disable no-console,react/prop-types,no-shadow,
import/no-named-as-default,import/no-named-as-default-member
*/
import * as React from 'react';
import * as _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { isCnpj, isCpf } from 'validator-brazil';
import PhoneInput from 'react-phone-input-2';
import { Context } from '../../state/Store';
import './PersonalInfoComponent.css';
import RedwoodInputText from '../../common/Input/RedwoodInputText';
import RedwoodSelect from '../../common/Select/RedwoodSelect';
import Button from '../../common/Button/Button';
import SendMetrics from '../../CourierService/SendMetrics';
import { regExp } from '../Validators';
import { getFeatureFlag } from '../Helpers';
import { fetchStateId } from '../../CourierService/FetchService';
import { constants } from '../Constants';

const PersonalInfoComponent = (props) => {
  const { t } = useTranslation();
  const {
    error, updateError, updateTouched, touched,
  } = props;
  const [state, dispatch] = React.useContext(Context);
  const { featureFlags } = state;
  const addressFields = state.countryInfo !== undefined ? state.countryInfo.addressFields : {};
  const taxInfo = state.countryInfo !== undefined ? state.countryInfo.taxFields : {};
  const showBrazilTaxFields = !getFeatureFlag('enable_taxfields', featureFlags) || state.isPaid === false;

  const verifyAddress = () => {
    let flag = false;
    let final = false;
    _.forIn(touched, (value) => {
      if (value === true) {
        flag = true;
      }
    });
    let errorFlag = true;

    if (state.country === 'BR' && showBrazilTaxFields) {
      errorFlag = (state.companyName === '' ? isCpf(state.taxPayerId) : isCnpj(state.taxPayerId)) && errorFlag;
    }
    _.forIn(addressFields, (value, key) => {
      const finalKey = key.toLowerCase();
      if (value[0] === 'R' || value[0] === 'R*') {
        errorFlag = (error[finalKey].val !== '') && errorFlag;
      }
      final = errorFlag;
    });

    final = getFeatureFlag('sms', featureFlags) ? final : final && error.phoneNumber.val !== '';
    return flag ? true : !final;
  };

  const isStateDisabled = () => state.selectedPromo
                                && state.selectedPromo.address
                                && state.selectedPromo.address.state
                                && !_.isEmpty(state.selectedPromo.address.state.trim());

  const isProvinceDisabled = () => state.selectedPromo
                                && state.selectedPromo.address
                                && state.selectedPromo.address.province
                                && !_.isEmpty(state.selectedPromo.address.province.trim());

  const isCountyDisabled = () => state.selectedPromo
                                && state.selectedPromo.address
                                && state.selectedPromo.address.county
                                && !_.isEmpty(state.selectedPromo.address.county.trim());
  const doClick = () => {
    dispatch({
      type: 'ADDRESS_VALIDATION',
      payload: false,
    });
    const index = _.findIndex(state.featureFlags, (o) => (_.isEmpty(state.featureFlags) ? false : o.featureName === 'sms'));
    // eslint-disable-next-line no-unused-expressions
    state.featureFlags[index].isActive
      ? props.openAccordionSection(1)
      : props.openAccordionSection(2);
  };

  // GET API Call for states of a given country

  React.useEffect(() => {
    let statesList = [];
    if (state.country !== '') {
      fetchStateId(state.country)
        .then(async (res) => {
          if (res.status === 200) {
            statesList = await res.json();
            const statesFromApi = statesList != null
              && statesList.items.map((state) => ({ value: { id: state.id }, label: state.name }));

            dispatch({
              type: 'UPDATE_STATES',
              payload: statesFromApi,
            });
            SendMetrics({
              name: 'States.Count.Metrics',
              value: 1,
              userSession: state.user_session,
            });
          }
        })
        .catch((error) => {
          SendMetrics({
            name: 'States.Count.Metrics.Fail',
            value: 1,
            userSession: state.user_session,
          });
          dispatch({
            type: 'UPDATE_STATES',
            payload: {},
          });
          console.log(error);
        });
    }
  }, [state.country]);

  React.useEffect(() => {
    if (state.selectedPromo && state.selectedPromo.address) {
      if (!_.isEmpty(state.selectedPromo.address.address1)) {
        updateError('address1', true, state.selectedPromo.address.address1, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.address2)) {
        updateError('address2', true, state.selectedPromo.address.address2, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.address3)) {
        updateError('address3', true, state.selectedPromo.address.address3, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.address4)) {
        updateError('address4', true, state.selectedPromo.address.address4, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.city)) {
        updateError('city', true, state.selectedPromo.address.city, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.state)) {
        updateError('state', true, state.selectedPromo.address.state, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.postalcode)) {
        updateError('postalcode', true, state.selectedPromo.address.postalcode, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.province)) {
        updateError('province', true, state.selectedPromo.address.province, '');
      }
      if (!_.isEmpty(state.selectedPromo.address.county)) {
        updateError('county', true, state.selectedPromo.address.county, '');
      }
    }
  }, [state.selectedPromo.address]);

  const onChangeHandler = (action, payload) => {
    updateTouched(payload[0], true);
    dispatch({
      type: `${action}`,
      payload,
    });
  };

  const getCountyLabel = () => {
    // Check for edge cases and display if necessary.
    // This is required because you can't store translated words in translation files and they're
    // not intended to translate further.
    if (addressFields.County[1] !== undefined) {
      if (state.country === 'MX') {
        return 'Municipio';
      }

      if (state.country === 'CL') {
        return 'Comuna';
      }

      return t(`personalInfo.${addressFields.County[1]}`);
    }
    return t('personalInfo.county', 'County');
  };

  const onBlurHandler = (e, pattern) => {
    const val = _.trim(e.target.value);
    const { name } = e.target;
    dispatch({ type: 'UPDATE_TAX_FIELDS', payload: [name, val] });
    updateTouched(name, false);
    const translatedName = t(`personalInfo.${name}`);

    if (val === '') {
      updateError(
        name,
        false,
        '',
        // eslint-disable-next-line react/jsx-filename-extension
        <Trans i18nKey="personalInfo.requiredMsg">
          A value for
          {translatedName}
          {' '}
          is required.
        </Trans>,
      );
    } else if ((pattern === 'CPF' && !isCpf(val)) || (pattern === 'CNPJ' && !isCnpj(val))) {
      updateError(name, false, '', t(`regExpr.${name}`));
    } else {
      updateError(name, true, val, '');
    }
  };

  const addressFieldsCheck = (e, addressInfo) => {
    const val = _.trim(e.target.value);
    const { name } = e.target;
    dispatch({ type: 'UPDATE_ADDRESS', payload: [name, val] });
    updateTouched(name, false);
    const translatedName = t(`personalInfo.${name}`);

    if (addressInfo[0] === 'R' && val === '') {
      updateError(
        name,
        false,
        '',
        <Trans i18nKey="personalInfo.requiredMsg">
          A value for
          {translatedName}
          {' '}
          is required.
        </Trans>,
      );
    } else if (name === 'postalcode' && !val.match(addressInfo[1])) {
      updateError(name, false, '', t(`regExpr.${addressInfo[2]}`));
    } else {
      updateError(name, true, val, '');
    }
  };

  const stateCheck = (val) => {
    updateError('state', true, val, '');
  };

  const provinceCheck = (val) => {
    updateError('province', true, val, '');
  };

  const handleOnChange = (value, data) => {
    updateTouched('phoneNumber', true);
    dispatch({
      type: 'UPDATE_SMS_NUMBER',
      payload: value.slice(data.dialCode.length),
    });
    dispatch({
      type: 'UPDATE_PHONE_COUNTRY_CODE',
      payload: data.countryCode,
    });
  };

  const validateMobileNum = (value, data) => {
    const val = value.slice(data.dialCode.length);
    updateTouched('phoneNumber', false);
    if (val === '') {
      updateError('phoneNumber', false, '', '');
    } else if (!val.match(regExp.phoneNumber)) {
      updateError('phoneNumber', false, '', '');
    } else {
      updateError('phoneNumber', true, val, '');
    }
    dispatch({
      type: 'VALID_PHONE_NUMBER',
      payload: error.phoneNumber.isValid,
    });
  };

  const onPhoneNumBlur = () => {
    const val = state.smsNumber;
    if (val === '') {
      updateError('phoneNumber', false, '', '');
    } else if (!val.match(regExp.phoneNumber)) {
      updateError('phoneNumber', false, '', '');
    } else {
      updateError('phoneNumber', true, val, '');
    }
    dispatch({
      type: 'VALID_PHONE_NUMBER',
      payload: error.phoneNumber.isValid,
    });
  };

  return (
    <>
      <form autoComplete="off">
        {addressFields !== undefined ? (
          <>
            {addressFields.Address1 !== undefined && (
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.addressPlaceholder')}
                label={`${
                  addressFields.Address1[1] !== undefined
                    ? t(`personalInfo.${addressFields.Address1[1]}`)
                    : t('personalInfo.address1', 'Address Line 1')
                }`}
                name="address1"
                id="address1"
                autoFocus
                disabled={
                  !!(!_.isEmpty(state.selectedPromo)
                  && !_.isEmpty(state.selectedPromo.address)
                  && !_.isEmpty(state.selectedPromo.address.address1))
                }
                valid={
                  error.address1 !== undefined ? error.address1.isValid : true
                }
                value={state.address.address1}
                maxLength={constants.addressLinesCharLength}
                required={addressFields.Address1[0] === 'R' ? 'required' : null}
                error={error.address1 !== undefined ? error.address1.msg : ''}
                onChange={(e) => onChangeHandler('UPDATE_ADDRESS', [
                  e.target.name,
                  e.target.value,
                ])}
                onBlur={(e) => addressFieldsCheck(e, addressFields.Address1)}
              />
            )}

            {addressFields.Address2 !== undefined && (
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.addressPlaceholder')}
                label={`${
                  addressFields.Address2[1] !== undefined
                    ? t(`personalInfo.${addressFields.Address2[1]}`)
                    : t('personalInfo.address2', 'Address Line 2')
                }`}
                name="address2"
                id="address2"
                disabled={
                  !!(!_.isEmpty(state.selectedPromo)
                  && !_.isEmpty(state.selectedPromo.address)
                  && !_.isEmpty(state.selectedPromo.address.address2))
                }
                valid={
                  error.address2 !== undefined ? error.address2.isValid : true
                }
                value={state.address.address2}
                maxLength={constants.addressLinesCharLength}
                required={addressFields.Address2[0] === 'R' ? 'required' : null}
                error={error.address2 !== undefined ? error.address2.msg : ''}
                onChange={(e) => onChangeHandler('UPDATE_ADDRESS', [
                  e.target.name,
                  e.target.value,
                ])}
                onBlur={(e) => addressFieldsCheck(e, addressFields.Address2)}
              />
            )}

            {addressFields.Address3 !== undefined && (
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.addressPlaceholder')}
                label={`${
                  addressFields.Address3[1] !== undefined
                    ? t(`personalInfo.${addressFields.Address3[1]}`)
                    : t('personalInfo.address3', 'Address Line 3')
                }`}
                name="address3"
                id="address3"
                disabled={
                  !!(!_.isEmpty(state.selectedPromo)
                  && !_.isEmpty(state.selectedPromo.address)
                  && !_.isEmpty(state.selectedPromo.address.address3))
                }
                valid={
                  error.address3 !== undefined ? error.address3.isValid : true
                }
                value={state.address.address3}
                maxLength={constants.addressLinesCharLength}
                required={addressFields.Address3[0] === 'R' ? 'required' : null}
                error={error.address3 !== undefined ? error.address3.msg : ''}
                onChange={(e) => onChangeHandler('UPDATE_ADDRESS', [
                  e.target.name,
                  e.target.value,
                ])}
                onBlur={(e) => addressFieldsCheck(e, addressFields.Address3)}
              />
            )}

            {addressFields.Address4 !== undefined && (
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.addressPlaceholder')}
                label={`${
                  addressFields.Address4[1] !== undefined
                    ? t(`personalInfo.${addressFields.Address4[1]}`)
                    : t('personalInfo.address4', 'Address Line 4')
                }`}
                name="address4"
                id="address4"
                disabled={
                  !!(!_.isEmpty(state.selectedPromo)
                  && !_.isEmpty(state.selectedPromo.address)
                  && !_.isEmpty(state.selectedPromo.address.address4))
                }
                valid={
                  error.address4 !== undefined ? error.address4.isValid : true
                }
                value={state.address.address4}
                maxLength={constants.addressLinesCharLength}
                required={addressFields.Address4[0] === 'R' ? 'required' : null}
                error={error.address4 !== undefined ? error.address4.msg : ''}
                onChange={(e) => onChangeHandler('UPDATE_ADDRESS', [
                  e.target.name,
                  e.target.value,
                ])}
                onBlur={(e) => addressFieldsCheck(e, addressFields.Address4)}
              />
            )}

            {addressFields.City !== undefined && (
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.cityPlaceholder')}
                label={`${
                  // eslint-disable-next-line no-nested-ternary
                  addressFields.City[1] !== undefined
                    ? state.country === 'CR'
                      ? 'Canton'
                      : t(`personalInfo.${addressFields.City[1]}`)
                    : t('personalInfo.city', 'City')
                }`}
                name="city"
                id="city"
                disabled={
                  !!(!_.isEmpty(state.selectedPromo)
                  && !_.isEmpty(state.selectedPromo.address)
                  && !_.isEmpty(state.selectedPromo.address.city))
                }
                valid={error.city !== undefined ? error.city.isValid : true}
                value={state.address.city}
                maxLength={constants.otherAddressDetailsCharLength}
                required={addressFields.City[0] === 'R' ? 'required' : null}
                error={error.city !== undefined ? error.city.msg : ''}
                onChange={(e) => onChangeHandler('UPDATE_ADDRESS', [
                  e.target.name,
                  e.target.value,
                ])}
                onBlur={(e) => addressFieldsCheck(e, addressFields.City)}
              />
            )}

            <div className="row">
              <div
                className={
                  addressFields.State !== undefined
                  || addressFields.Province !== undefined
                    ? 'col-md-6 col-xs-12'
                    : 'col-xs-0'
                }
              >
                {addressFields.State !== undefined
                  && Object.keys(state.states).length !== 0 && (
                    <RedwoodSelect
                      type="text"
                      placeholder={t('personalInfo.statePlaceholder')}
                      label={`${
                        addressFields.State[1] !== undefined
                          ? t(`personalInfo.${addressFields.State[1]}`)
                          : t('personalInfo.state', 'State')
                      }`}
                      name="state"
                      value={state.address.selectedState}
                      data={state.states}
                      required={
                        addressFields.State[0] === 'R'
                        || addressFields.State[0] === 'R*'
                          ? 'required'
                          : null
                      }
                      onChange={(e) => {
                        dispatch({
                          type: 'UPDATE_ADDRESS',
                          payload: ['selectedState', e],
                        });
                        dispatch({
                          type: 'UPDATE_ADDRESS',
                          payload: ['state', e.label],
                        });
                        stateCheck(e.value, addressFields.State);
                      }}
                    />
                )}
                {addressFields.State !== undefined
                  && Object.keys(state.states).length === 0 && (
                    <RedwoodInputText
                      type="text"
                      placeholder={t('personalInfo.statePlaceholder')}
                      label={`${
                        addressFields.State[1] !== undefined
                          ? t(`personalInfo.${addressFields.State[1]}`)
                          : t('personalInfo.state', 'State')
                      }`}
                      name="state"
                      id="state"
                      valid={
                        error.state !== undefined ? error.state.isValid : true
                      }
                      value={state.address.state}
                      maxLength={constants.otherAddressDetailsCharLength}
                      disabled={isStateDisabled()}
                      required={
                        addressFields.State[0] === 'R'
                        || addressFields.State[0] === 'R*'
                          ? 'required'
                          : null
                      }
                      error={error.state !== undefined ? error.state.msg : ''}
                      onChange={(e) => {
                        // eslint-disable-next-line no-unused-expressions
                        addressFields.State[0] === 'R*'
                          ? onChangeHandler('UPDATE_ADDRESS', [
                            e.target.name,
                            e.target.value.toUpperCase(),
                          ])
                          : onChangeHandler('UPDATE_ADDRESS', [
                            e.target.name,
                            e.target.value,
                          ]);
                      }}
                      onBlur={(e) => addressFieldsCheck(e, addressFields.State)}
                    />
                )}

                {addressFields.Province !== undefined
                  && (state.country === 'CA'
                    || state.country === 'IT'
                    || state.country === 'PK'
                    || state.country === 'ES'
                    || state.country === 'AR'
                    || state.country === 'CR'
                    || state.country === 'PS') && (
                    <RedwoodSelect
                      type="text"
                      placeholder={t('personalInfo.provincePlaceholder')}
                      label={`${
                        addressFields.Province[1] !== undefined
                          ? t(`personalInfo.${addressFields.Province[1]}`)
                          : t('personalInfo.province', 'Province')
                      }`}
                      name="province"
                      id="province"
                      data={state.states}
                      value={state.address.selectedProvince}
                      required={
                        addressFields.Province[0] === 'R'
                        || addressFields.Province[0] === 'R*'
                          ? 'required'
                          : null
                      }
                      onChange={(e) => {
                        dispatch({
                          type: 'UPDATE_ADDRESS',
                          payload: ['selectedProvince', e],
                        });
                        dispatch({
                          type: 'UPDATE_ADDRESS',
                          payload: ['province', e.label],
                        });
                        provinceCheck(e.value, addressFields.Province);
                      }}
                    />
                )}
                {addressFields.Province !== undefined
                  && !(
                    state.country === 'CA'
                    || state.country === 'IT'
                    || state.country === 'PK'
                    || state.country === 'ES'
                    || state.country === 'AR'
                    || state.country === 'CR'
                    || state.country === 'PS'
                  ) && (
                    <RedwoodInputText
                      type="text"
                      placeholder={t('personalInfo.provincePlaceholder')}
                      label={`${
                        addressFields.Province[1] !== undefined
                          ? t(`personalInfo.${addressFields.Province[1]}`)
                          : t('personalInfo.province', 'Province')
                      }`}
                      name="province"
                      id="province"
                      disabled={isProvinceDisabled()}
                      valid={
                        error.province !== undefined
                          ? error.province.isValid
                          : true
                      }
                      value={state.address.province}
                      maxLength={constants.otherAddressDetailsCharLength}
                      required={
                        addressFields.Province[0] === 'R'
                        || addressFields.Province[0] === 'R*'
                          ? 'required'
                          : null
                      }
                      error={
                        error.province !== undefined ? error.province.msg : ''
                      }
                      onChange={(e) => {
                        // eslint-disable-next-line no-unused-expressions
                        addressFields.Province[0] === 'R*'
                          ? onChangeHandler('UPDATE_ADDRESS', [
                            e.target.name,
                            e.target.value.toUpperCase(),
                          ])
                          : onChangeHandler('UPDATE_ADDRESS', [
                            e.target.name,
                            e.target.value,
                          ]);
                      }}
                      onBlur={(e) => addressFieldsCheck(e, addressFields.Province)}
                    />
                )}
              </div>

              <div
                className={
                  addressFields.State !== undefined
                  || addressFields.Province !== undefined
                    ? 'col-md-6 col-xs-12'
                    : 'col-xs-12'
                }
              >
                {addressFields.PostalCode !== undefined && (
                  <RedwoodInputText
                    type="text"
                    placeholder={t('personalInfo.zipPlaceholder')}
                    label={t('personalInfo.zip', 'Zip/Postal Code')}
                    name="postalcode"
                    id="postalcode"
                    disabled={
                      !!(!_.isEmpty(state.selectedPromo)
                      && !_.isEmpty(state.selectedPromo.address)
                      && !_.isEmpty(state.selectedPromo.address.postalcode))
                    }
                    valid={
                      error.postalcode !== undefined
                        ? error.postalcode.isValid
                        : true
                    }
                    value={state.address.postalcode}
                    maxLength={constants.otherAddressDetailsCharLength}
                    required={
                      addressFields.PostalCode[0] === 'R'
                      || addressFields.PostalCode[0] === 'R*'
                        ? 'required'
                        : null
                    }
                    error={
                      error.postalcode !== undefined ? error.postalcode.msg : ''
                    }
                    onChange={(e) => {
                      // eslint-disable-next-line no-unused-expressions
                      addressFields.PostalCode[0] === 'R*'
                        ? onChangeHandler('UPDATE_ADDRESS', [
                          e.target.name,
                          e.target.value.toUpperCase(),
                        ])
                        : onChangeHandler('UPDATE_ADDRESS', [
                          e.target.name,
                          e.target.value,
                        ]);
                    }}
                    onBlur={(e) => addressFieldsCheck(e, addressFields.PostalCode)}
                  />
                )}
              </div>
            </div>
            {addressFields.County !== undefined && (
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.countyPlaceholder')}
                label={getCountyLabel()}
                name="county"
                id="county"
                disabled={isCountyDisabled()}
                valid={error.county !== undefined ? error.county.isValid : true}
                value={state.address.county}
                maxLength={constants.otherAddressDetailsCharLength}
                required={addressFields.County[0] === 'R' ? 'required' : null}
                error={error.county !== undefined ? error.county.msg : ''}
                onChange={(e) => onChangeHandler('UPDATE_ADDRESS', [
                  e.target.name,
                  e.target.value,
                ])}
                onBlur={(e) => addressFieldsCheck(e, addressFields.County)}
              />
            )}

            {state.country === 'BR' && taxInfo !== undefined && showBrazilTaxFields && (
              <RedwoodInputText
                type="text"
                placeholder={state.companyName === ''
                  ? t('personalInfo.cpfPlaceholder', 'Enter the CPF number such as xxxxxxxx-xx')
                  : t('personalInfo.cnpjPlaceholder', 'Enter the CNPJ number such as xxxxxxxx/xxxx-xx')}
                label={`${state.companyName === '' ? 'CPF' : 'CNPJ'}`}
                name="taxPayerId"
                value={state.taxPayerId}
                valid={
                  error.taxPayerId !== undefined
                    ? error.taxPayerId.isValid
                    : true
                }
                required={taxInfo.TaxPayerID[1] === 'R' ? 'required' : null}
                error={
                  state.companyName === ''
                    ? t('regExpr.brCpfMsg')
                    : t('regExpr.brCnpjMsg')
                }
                onChange={(e) => onChangeHandler('UPDATE_TAX_FIELDS', [
                  e.target.name,
                  e.target.value,
                ])}
                onBlur={(e) => {
                  // eslint-disable-next-line no-unused-expressions
                  state.companyName === ''
                    ? onBlurHandler(e, taxInfo.TaxPayerID[4])
                    : onBlurHandler(e, taxInfo.TaxPayerID[0]);
                }}
              />
            )}
            <br />
            {!getFeatureFlag('sms', featureFlags)
            && (
            <div className="phoneNumberContainer">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="phoneNumber">{t('mobileVerify.phone', 'Phone Number')}</label>
              <PhoneInput
                inputProps={{
                  name: t('mobileVerify.phone', 'Phone Number'),
                  required: true,
                  id: 'phoneNumber',
                  'aria-describedby': 'phoneNumber-validation',
                }}
                enableAreaCodes={['ci']}
                masks={{ ci: ['.. .... ....'] }}
                areaCodes={{ ci: ['27'] }}
                enableTerritories
                isValid={
                  !_.isEmpty(error.phoneNumber.val)
                  || _.isEmpty(state.smsNumber)
                }
                country={_.lowerCase(state.country)}
                value={state.phoneNumber}
                onChange={(phone, data) => {
                  dispatch({
                    type: 'UPDATE_PHONE',
                    payload: phone,
                  });
                  handleOnChange(phone, data);
                  validateMobileNum(phone, data);
                }}
                onBlur={() => onPhoneNumBlur()}
              />
              <div className="finePrint">
                {' '}
                {t('mobileVerify.mobileTrunkMessage')}
                {' '}
              </div>
              <div id="phoneNumber-validation" className="finePrint">
                {' '}
                {t('mobileVerify.mobileNumberInfo')}
                {' '}
              </div>
            </div>
            )}

            <Button
              type="solid"
              disabled={verifyAddress()}
              onClick={(e) => doClick(e)}
              text={t('termsOfUse.continue', 'Continue')}
            />
          </>
        ) : (
          <></>
        )}
      </form>
    </>
  );
};

export default PersonalInfoComponent;
