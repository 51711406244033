/* eslint-disable react/prop-types,
    react/jsx-filename-extension,
    react/jsx-one-expression-per-line */
import * as React from 'react';
import './HomeRegionContainer.css';
import { Trans, useTranslation } from 'react-i18next';
import { Context } from '../../state/Store';

const HomeRegionContainer = () => {
  const [state] = React.useContext(Context);
  const { t } = useTranslation();

  if (state.nextStep || state.emailToken === '' || state.operationId !== '') {
    return null;
  }

  return (
    <div className="homeRegionContainer">
      <h2>{t('homeregion.title', 'About Home Regions')}</h2>
      <p><Trans i18nKey="homeregion.content">Your <a href="https://docs.oracle.com/en-us/iaas/Content/Identity/Tasks/managingregions.htm#The" target="_blank" rel="noopener noreferrer" aria-label={t('homeregion.opensInNewWindowAriaLabel', 'Opens in new window')}>home region</a> is the geographic location where your account, identity resources, and primary identity domain will be created. Your home region selection cannot be changed after this step in the sign-up process. Some cloud services may not be available in all regions. <a href="https://www.oracle.com/cloud/public-cloud-regions/service-availability/#northamerica" target="_blank" rel="noopener noreferrer" aria-label={t('homeregion.opensInNewWindowAriaLabel', 'Opens in new window')}>See Regions</a> for service availability.</Trans></p>
    </div>
  );
};

export default HomeRegionContainer;
