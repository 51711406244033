/*
eslint-disable camelcase,no-console,react/prop-types,import/no-cycle
*/
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Reducer from './Reducer';
import { base_url } from '../config/apiConfig';

export const initialState = {
  user_session: `signup_${uuidv4()}`,
  oracleEmployee: false,
  render: true,
  country: '', // country ID
  username: '',
  password: '',
  confirmPassword: '',
  accountName: '',
  homeRegion: null,
  homeRegions: {},
  nextStep: false,
  firstName: '',
  lastName: '',
  alternateName: '',
  countries: [{ value: {}, label: '' }],
  countryObject: null, // getCountries API call
  countryInfo: {}, // Static Config file
  companyName: '',
  departmentName: '',
  altEnglishCompanyName: '',
  jobTitle: '',
  address: {
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    selectedState: null,
    selectedProvince: null,
    state: '',
    province: '',
    postalcode: '',
    county: '',
  },
  states: {},
  intendedUse: 'PERSONAL',
  taxPayerId: '',
  taxRegNumber: '',
  taxInfoStatus: { value: 'REGISTERED' },
  paidCPF: '',
  paidCNPJ: '',
  phoneNumber: '',
  promotions: [],
  selectedPromo: {},
  defaultPromo: {},
  verificationCode: '',
  orgId: '',
  miniBobEnabled: true,
  sourceType: '',
  referrer: '',
  billingAddress: {},
  paymentOptions: {},
  paymentError: {},
  paymentResponse: {},
  smsNumber: '',
  operationId: '',
  consoleUri: '',
  language: '',
  paymentStatus: '',
  addressValidation: true,
  mobileValidation: true,
  showThankYou: false,
  numPaymentVerifyBtnClicked: 0,
  phoneCountryCode: '',
  validPhoneNumber: false,
  apiError: false,
  api403Error: false,
  api403ErrorMsg: '',
  emailToken: '',
  validateEmail: false,
  sendEmail: true,
  defaultValues: '',
  showBanner: false,
  isOpayOutage: false,
  captchaJwtToken: '',
  isPaid: false,
  featureFlags: [],
  addressContinueBtn: true,
  domainBlocked: false,
  accountNotUnique: false,
  promoTitle: '',
  promoValidationError: false,
};

const Store = ({ children }) => {
  const [state, dispatch] = React.useReducer(Reducer, initialState);
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch({ type: 'UPDATE_TAX_INFO_STATUS', payload: { value: 'REGISTERED', label: t('personalInfo.taxRegisteredOption', 'Registered') } });
  }, [t]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    try {
      const defaultValues = JSON.parse(urlParams.get('defaultValues'));
      dispatch({
        type: 'SET_QUERY_STATE',
        payload: defaultValues,
      });
      const values = JSON.stringify(defaultValues);
      dispatch({
        type: 'SET_DEFAULT_VALUES',
        payload: values,
      });
    } catch (e) {
      console.error('Unable to parse query params', e);
    }
  }, []);

  React.useEffect(() => {
    fetch(`${base_url}/featureFlags`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (res) => {
        if (res.status === 200) {
          const result = await res.json();
          dispatch({
            type: 'SET_FEATURE_FLAGS',
            payload: result.items,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = React.createContext(initialState);

export default Store;
