import * as React from 'react';
import './Icon.css';

// Icons Supported: info, error

class Icon extends React.Component {
  render() {
    return (
      <div data-testid={this.props.testId} className={`icon ${this.props.name}`} onClick={this.props.onClick} />
    );
  }
}

export default Icon;
