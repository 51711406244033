/* eslint-disable camelcase,no-unused-vars,no-console */
import { courier_service_url } from '../config/apiConfig';

const SendMetrics = (props) => {
  fetch(
    `${courier_service_url}/sendMetrics`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        source: 'oci_signup_client',
        type: 'metrics',
        timestamp: Date.now(),
        metadata: [],
        sampling: 1,
        ...props,
      }),
    },
  ).then(async (res) => {

  }).catch((error) => {
    console.log(error);
  });
};

export default SendMetrics;
