import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getLocaleLanguage } from "../../components/Helpers";

const detectionOptions = {
  order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
  lookupFromPathIndex: 0,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    detection: detectionOptions,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json',
    },
  })
  .then(() => {
    const lang = getLocaleLanguage(i18n.language);
    i18n.changeLanguage(lang);
  });

export default i18n;
