import * as React from 'react';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line camelcase
import { signup_environment } from './config/apiConfig';
import ProgressContainer from './components/ProgressBar/ProgressContainer';
import PromoContainer from './components/Promos/PromoContainer';
import SignUpFormContainer from './components/SignupForm/SignUpFormContainer';
import Store from './state/Store';
import UserInfoComponent from './components/UserInfo/UserInfoComponent';
import KeepAlive from './components/KeepAlive';
import './common/flexboxgrid.css';
import './App.css';
import WaitingRoom from './components/WaitingRoom/WaitingRoom';
import ThankYou from './components/ThankYou/ThankYou';
import TrusteComponent from './common/Truste/TrusteComponent';
import useScript from './customHooks/useScript';
import HomeRegionContainer from './components/HomeRegion/HomeRegionContainer';

const Home = (props) => {
  /* eslint-disable react/destructuring-assignment,react/prop-types,no-nested-ternary,camelcase */
  const { sourceType } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const { source } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const promoSourceType = sourceType !== undefined ? sourceType : source !== undefined ? source : '';
  const promoReferrer = document.referrer;
  const { programType } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const { trialType } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const verifyEmailToken = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).verify_email;
  const emailParam = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).email;

  let resourceUrl = '';
  // eslint-disable-next-line no-unused-expressions
  signup_environment === 'prod-oraclecloud' || signup_environment === 'prod-oracle'
    ? resourceUrl = '//c.oracleinfinity.io/acs/account/wh3g12c3gg/js/oracle/odc.js?_ora.context=analytics:production'
    : resourceUrl = '//c.oracleinfinity.io/acs/account/wh3g12c3gg/js/oracle/odc.js?_ora.context=analytics:development';
  useScript(resourceUrl);

  React.useEffect(() => {
    const iframes = document.getElementsByTagName('iframe');
    for (let i = 0; i < iframes.length; i += 1) {
      iframes[i].ariaHidden = true;
    }
  }, []);

  const toggleModal = () => {
    if (document.getElementById('main').classList.contains('modal-open')) {
      document.getElementById('main').classList.remove('modal-open');
    } else {
      document.getElementById('main').classList.add('modal-open');
    }
  };

  const { t } = useTranslation();

  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <div>
      <header>
        <div className="brandingBar">
          <div className="oLogo" />
          <div className="oLogoWords title7">Cloud Infrastructure</div>
        </div>
      </header>
      <main id="main">
        <Store>
          <div className="row">
            <div className="col-md-4 col-xs-12 sidebar">
              <ProgressContainer programType={programType} />
              <PromoContainer programType={programType} />
              <HomeRegionContainer />
            </div>
            <div className="col-md-8 col-xs-12 mainContainer">
              <KeepAlive toggleCallback={toggleModal} />
              <SignUpFormContainer
                sourceType={promoSourceType}
                referrer={promoReferrer}
                programType={programType}
                trialType={trialType}
                verifyEmailToken={verifyEmailToken}
                emailParam={emailParam}
                toggleCallback={toggleModal}
              />
              <UserInfoComponent
                toggleCallback={toggleModal}
                programType={programType}
              />
              <ThankYou />
            </div>
            <WaitingRoom programType={programType} />
          </div>
        </Store>
      </main>

      <footer>
        <div className="row footer-len-container">
          <div>
            <a href="https://www.oracle.com/legal/copyright.html">
              {t('footer.copyright')}
            </a>
          </div>
          <span className="pipe"> | </span>
          <div>
            <a href="https://www.oracle.com/legal/privacy/index.html">
              {t('footer.terms', 'Privacy')}
            </a>
            <span>/</span>
            <a href="https://www.oracle.com/legal/privacy/privacy-choices.html">
              {t('footer.info', 'Do Not Sell My Info')}
            </a>
          </div>
          <TrusteComponent />
          <div>
            <a href="https://www.oracle.com/legal/privacy/marketing-cloud-data-cloud-privacy-policy.html#13">
              {t('footer.adChoices', 'Ad Choices')}
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
