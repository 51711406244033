/* eslint-disable camelcase */
import * as _ from 'lodash';
import OrgIdList from '../data/OrgIdName.json';
import { signup_environment } from '../config/apiConfig';
import promotionsData from '../data/PromotionsInfo.json';

export const generateRandomNumber = (min, max) => Math.floor(Math.random() * Math.floor(max));

export const generateRandomLetter = () => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  return alphabet[Math.floor(Math.random() * alphabet.length)];
};

// eslint-disable-next-line camelcase
export const is_numeric_char = (c) => {
  const re = /^\d$/;
  return re.test(c);
};

export const getOrgId = (orgName) => {
  let orgId = '';
  // eslint-disable-next-line array-callback-return
  OrgIdList.OrgMapping.map((item) => {
    if (item.orgName === orgName) {
      orgId = item.orgId;
    }
  });
  return orgId;
};

export const randomPaddedIntString = (min, max, padString, length) => {
  let str = Math.floor(Math.random() * (max - min + 1) + min);

  while (str.length < length) {
    str = padString + str;
  }
  return str;
};

export const removeSpace = (output) => {
  let value;
  if (typeof output === 'string') {
    return _.trim(output);
  }
  /* eslint-disable guard-for-in,no-restricted-syntax,no-param-reassign */
  for (const key in output) {
    value = output[key];
    if (typeof value === 'string') {
      output[key] = _.trim(value);
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      output[key] = removeSpace(value);
    }
  }
  return output;
};

export const timer = (counter) => {
  let mins = Math.floor(counter / 60);
  let secs = counter - mins * 60;
  if (mins < 10) {
    mins = `0${mins}`;
  }
  if (secs < 10) {
    secs = `0${secs}`;
  }
  return `${mins}:${secs}`;
};

export const getFeatureFlag = (featureName, featureFlags) => {
  if (!_.isEmpty(featureFlags)) {
    const index = _.findIndex(featureFlags, (o) => o.featureName === featureName);
    return index >= 0 ? featureFlags[index].isActive : false;
  }
  return false;
};

export const getUriDestination = () => {
  let result = null;
  const windowURL = new URLSearchParams(window.location.search);
  const uriDestination = windowURL.get('uriDestination');
  if (uriDestination === null) return result;
  try {
    result = decodeURIComponent(uriDestination);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('URI Destination is not encoded: ', e);
    result = uriDestination;
  }
  return new URL(result);
};

export const getWindowHost = () => new URL(window.location).host;

export const getWindowHostName = () => window.location.hostname;

export const redirectTo = (url) => {
  window.location.assign(url);
};

export const isMiglabEnv = () => _.isEqual(signup_environment, 'miglab');

export const isPintlabEnv = () => _.isEqual(signup_environment, 'pintlab') || _.isEqual(signup_environment, 'pintlab2');

export const getLocaleLanguage = (locale) => {
  locale = locale.toLowerCase();
  const map = new Map([
    ['ar', 'ar-XB'],
    ['en-xa', 'en-XA'],
    ['en-xc', 'en-XC'],
    ['fr-ca', 'fr-CA'],
    ['pt-pt', 'pt-PT'],
    ['sr-latn', 'sr-Latn'],
    ['zh-hans', 'zh-Hans'],
    ['zh-hant', 'zh-Hant'],
    ['zh-cn', 'zh-Hans'],
    ['zh-sg', 'zh-Hans'],
    ['zh-hk', 'zh-Hant'],
    ['zh-tw', 'zh-Hant'],
    ['zh-mo', 'zh-Hant'],
    ['zh-hans-cn', 'zh-Hans'],
    ['zh-hans-sg', 'zh-Hans'],
    ['zh-hant-hk', 'zh-Hant'],
    ['zh-hant-tw', 'zh-Hant'],
    ['zh-hant-mo', 'zh-Hant'],
    ['zh', 'zh-Hans'],
    ['nb', 'no'],
    ['nn', 'no'],
    ['id', 'ms'],
  ]);

  if (map.has(locale)) {
    return map.get(locale);
  }
  if (locale.length > 2 && locale.substring(0, 3) === 'zh-') {
    return 'zh-Hans';
  }
  if (map.has(locale.substring(0, 2))) {
    return map.get(locale.substring(0, 2));
  }

  return locale.substring(0, 2);
};

export const transformPromos = (promotions, currency) => {
  const promoResult = [];

  promotions.forEach((promo) => {
    const partNumber = promo && promo.promotion && promo.promotion.partNumber;
    const index = !_.isEmpty(partNumber) ? _.findIndex(promotionsData.items,
      (o) => o.partNumber === partNumber) : -1;
    if (index > -1) {
      const definition = promotionsData.items[index];
      const promotion = JSON.parse(JSON.stringify(definition));
      const { firstName, lastName } = promo.guest;
      const promoAttributes = _.isEmpty(promo.operationItems)
        ? [] : promo.operationItems[0].attributes;
      const customerName = _.findIndex(promoAttributes, (o) => o.name === 'customerName');
      const campaignDisplayName = _.findIndex(promoAttributes, (o) => o.name === 'campaignDisplayName');
      const programType = _.findIndex(promoAttributes, (o) => o.name === 'programType');
      const promoDaysIndex = _.findIndex(promoAttributes, (o) => o.name === 'promoDurationInDays');
      const promoAmtIndex = _.findIndex(promoAttributes, (o) => o.name === 'promoAmount');
      const promoCampaignId = _.findIndex(promoAttributes, (o) => o.name === 'campaignId');
      const creditCardRequired = _.findIndex(promoAttributes, (o) => o.name === 'creditCardRequired');
      const validationCodeRequired = _.findIndex(promoAttributes, (o) => o.name === 'validationCodeRequired');
      const country = _.findIndex(promoAttributes, (o) => o.name === 'country');
      const intendedUse = _.findIndex(promoAttributes, (o) => o.name === 'cloudUI.default.intendedUse');
      const customerNameNotDefault = _.findIndex(promoAttributes, (o) => o.name === 'customerNameNotCloudUiDefault');
      const address = {};

      _.map(promoAttributes, (attribute) => {
        if (attribute.name === 'address1') {
          address.address1 = attribute.value;
        } else if (attribute.name === 'address2') {
          address.address2 = attribute.value;
        } else if (attribute.name === 'address3') {
          address.address3 = attribute.value;
        } else if (attribute.name === 'address4') {
          address.address4 = attribute.value;
        } else if (attribute.name === 'city') {
          address.city = attribute.value;
        } else if (attribute.name === 'state') {
          address.state = attribute.value;
        } else if (attribute.name === 'province') {
          address.province = attribute.value;
        } else if (attribute.name === 'postalCode') {
          address.postalcode = attribute.value;
        } else if (attribute.name === 'county') {
          address.county = attribute.value;
        }
      });

      promotion.promoId = promo.id;
      promotion.campaignId = promoCampaignId === -1 ? '' : promoAttributes[promoCampaignId].value;
      promotion.promoAmount = promoAmtIndex === -1
        ? definition.promoAmount : promoAttributes[promoAmtIndex].value;
      promotion.promoDurationInDays = promoDaysIndex === -1
        ? definition.promoDurationInDays : promoAttributes[promoDaysIndex].value;
      promotion.creditCardRequired = creditCardRequired === -1
        ? definition.creditCardRequired : _.toLower(promoAttributes[creditCardRequired].value) !== 'false';
      promotion.campaignDisplayName = campaignDisplayName === -1
        ? '' : promoAttributes[campaignDisplayName].value;
      promotion.customerName = customerName === -1
        ? '' : promoAttributes[customerName].value;
      promotion.validationCodeRequired = validationCodeRequired === -1 ? definition.validationCodeRequired : _.toLower(promoAttributes[validationCodeRequired].value) !== 'false';
      promotion.address = _.isEmpty(address) ? {} : address;
      promotion.programType = programType === -1 ? '' : promoAttributes[programType].value;
      promotion.country = country === -1 ? '' : promoAttributes[country].value;
      promotion.currency = currency;
      promotion.firstName = _.isEmpty(firstName) ? '' : firstName;
      promotion.lastName = _.isEmpty(lastName) ? '' : lastName;
      promotion.intendedUse = intendedUse === -1 ? '' : promoAttributes[intendedUse].value;
      promotion.customerNameNotDefault = customerNameNotDefault === -1 ? '' : promoAttributes[customerNameNotDefault].value;
      promotion.promoAmount = promotion.promoAmount === '0' ? definition.promoAmount : promotion.promoAmount;
      promotion.promoDurationInDays = promotion.promoDurationInDays === '0' ? definition.promoDurationInDays : promotion.promoDurationInDays;

      promoResult.push(removeSpace(promotion));
    }
  });

  return promoResult;
};
