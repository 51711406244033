import React from 'react';
import './RedwoodSelect.css';
import '../react-redwood.css';
import Select, { components } from 'react-select';
import Icon from '../Icon/Icon';
import CircularLoader from '../CircularLoader/CircularLoader';

const NewValueContainer = (props) => {
  const shouldFloatLabel = props.selectProps.menuIsOpen || props.hasValue || props.touched;

  return (
    <components.ValueContainer {...props}>
      {shouldFloatLabel && <div className="floatedLabel">{props.selectProps.placeholder}</div>}
      {props.children}
    </components.ValueContainer>
  );
};

const CustomLoadingContainer = (props) => (
  <CircularLoader className="loader-sm" />
);

const CustomInput = (props) => {
  return (
    <div className="inputContainer">
      <components.Input {...props} aria-describedby={props.selectProps['aria-describedby']}/>
    </div>
  );
};

class RedwoodSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
      touched: false,
    };
  }

  render() {
    return (
      <div>
        <label className={`redwoodSelect ${this.props.error || (this.state.touched && this.props.value === null) ? 'redBorder' : ''}`}>
          <Select
            inputProps={{ autoComplete: 'none', touched: this.state.touched }}
            isLoading={this.props.loading}
            options={this.props.data}
            ariaLabel={this.props.ariaLabel}
            value={this.props.value}
            placeholder={this.props.label}
            aria-describedby={this.props.infoMsg !== '' ? `${this.props.name}-info-msg` : `${this.props.name}-error-msg`}
            components={{
              LoadingIndicator: CustomLoadingContainer,
              Input: CustomInput,
              ValueContainer: NewValueContainer,
            }}
            styles={{
              groupHeading: (provided, state) => ({
                ...provided,
                fontSize: 14,
                fontWeight: '700',
              }),
              option: (provided, {
                data, isDisabled, isFocused, isSelected,
              }) => ({
                ...provided,
                marginLeft: 20,
                width: '90%',
                color: 'rgba(22, 21, 19, 0.6)',
                backgroundColor: isFocused
                  ? 'rgba(105, 158, 103, 0.5)'
                  : '#fff',
              }),
              input: (provided, state) => ({
                ...provided,
              }),
              container: (provided, state) => ({
                ...provided,
              }),
              valueContainer: (provided, {
                data, isDisabled, isFocused, isSelected,
              }) => ({
                ...provided,
                overflow: 'visible',
                backgroundColor: isSelected
                  ? 'purple'
                  : '#fff',
              }),
              placeholder: (base, state) => ({
                ...base,
                display:
                      state.isFocused || state.isSelected || state.selectProps.inputValue !== ''
                        ? 'none'
                        : 'block',
                top: state.hasValue || state.selectProps.inputValue ? -8 : 0,
                transition: 'top 0.1s, font-size 0.1s',
                fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                color: 'rgba(22, 21, 19, 0.6)',
                marginTop: 25,
              }),
            }}
            onChange={this.props.onChange}
            onFocus={(e) => {
              this.setState({ showInfo: true });
              if (e.target.autocomplete) {
                e.target.autocomplete = 'no';
              }
            }}
            onBlur={(e) => {
              this.setState({ touched: true });
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            }}
            isDisabled={this.props.disabled}
          />
          <span>{}</span>
        </label>
        {this.state.showInfo
      && (
      <>
        <div id={`${this.props.name}-error-msg`} className={this.props.error === true ? '' : 'hide'}>
          <Icon name="warning" />
          {this.props.errorMsg}
        </div>
        <div id={`${this.props.name}-info-msg`} className={this.props.info === true ? '' : 'hide'}>
          <Icon name="info" />
          {this.props.infoMsg}
        </div>
      </>
      )}
        {/* Warning for Arm OCI Text */}
        {this.props.enableArmCapacityWarning
      && (
      <div className="marginForWarning">
        <Icon name="warning" />
        {this.props.warnMsg}
      </div>
      )}
      </div>
    );
  }
}

export default RedwoodSelect;
