/* eslint-disable react/prop-types */
import * as React from 'react';
import './ProgressContainer.css';
import { useTranslation } from 'react-i18next';
import { Context } from '../../state/Store';

const ProgressContainer = ({ programType }) => {
  const [state, dispatch] = React.useContext(Context);
  const { t } = useTranslation();
  React.useEffect(() => {
    let promoTitle = t('progress.progressTitle', 'Oracle Cloud Free Tier');

    if (state.defaultPromo.programType === 'STARTUP' || state.selectedPromo.programType === 'STARTUP') {
      promoTitle = t('progress.startupTitle', 'Oracle for Startups');
    }
    if (programType === 'RESEARCH-STARTER' || state.defaultPromo.programType === 'RESEARCH-STARTER' || state.selectedPromo.programType === 'RESEARCH-STARTER') {
      promoTitle = t('progress.ResearchStarterTitle', 'Oracle for Research');
    }
    if (programType === 'MYSQLDSAWS' && state.isPaid) {
      promoTitle = t('progress.paidTitle', 'Oracle Cloud Sign-up');
    }

    dispatch({
      type: 'UPDATE_PROMO_TITLE',
      payload: promoTitle,
    });
  }, [programType, state.selectedPromo, state.defaultPromo]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {state.operationId === ''
    && (
    <div className="progressContainer">
      <h1 className="title8" aria-level="1">{state.promoTitle}</h1>
      <hr aria-hidden="true" />
      {state.nextStep
        && (
        <div className="progressContent">

          <dl>
            <dt>{t('signup.country', 'Country/Territory')}</dt>
            <dd>
              {state.countryObject.label}
            </dd>

            <dt>{t('signup.username', 'Username')}</dt>
            <dd>{state.username}</dd>

            <dt>{t('signup.accountName', 'Cloud Account Name')}</dt>
            <dd>{state.accountName}</dd>

            <dt>{t('signup.homeRegion', 'Home Region')}</dt>
            <dd>{state.homeRegion.label}</dd>
          </dl>
        </div>
        )}
    </div>
    )}
    </>
  );
};

export default ProgressContainer;
