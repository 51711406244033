/* eslint-disable
import/no-named-as-default,
import/no-named-as-default-member,
react/jsx-filename-extension */
import * as React from 'react';
import './ThankYou.css';
import { useTranslation } from 'react-i18next';
import RedwoodModal from '../../common/Modal/RedwoodModal';
import { Context } from '../../state/Store';

const ThankYou = () => {
  const [show] = React.useState(true);
  const [state] = React.useContext(Context);
  const { t } = useTranslation();
  return (
    <>
      {((state.operationId !== '' && state.paymentStatus === 'REVIEW') || state.showThankYou)
        && (
        <RedwoodModal show={show} title={t('thankYou.title')}>
          <p>{t('thankYou.creating')}</p>
          <p><a href="https://www.oracle.com/cloud/" target="_blank" rel="noopener noreferrer">{t('thankYou.read')}</a></p>
        </RedwoodModal>
        )}
    </>
  );
};

export default ThankYou;
