/*
eslint-disable camelcase,no-shadow,no-console,no-unused-vars,
import/no-named-as-default,import/no-named-as-default-member,react/prop-types
*/
import * as React from 'react';
import PhoneInput from 'react-phone-input-2';
import * as _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Context } from '../../state/Store';
import 'react-phone-input-2/lib/style.css';
import './MobileVerifyComponent.css';
import Button from '../../common/Button/Button';
import RedwoodInputText from '../../common/Input/RedwoodInputText';
import { base_url } from '../../config/apiConfig';
import { regExp } from '../Validators';
import Notifier from '../../common/Notifier/Notifier';
import SendAction from '../../CourierService/SendAction';
import SendLogs from '../../CourierService/SendLogs';
import SendMetrics from '../../CourierService/SendMetrics';
import { navTrack } from '../../config/adobeAnalytics';
import { removeSpace } from '../Helpers';

const MobileVerifyComponent = (props) => {
  const [state, dispatch] = React.useContext(Context);
  const [counter, setCounter] = React.useState(115);
  const { t } = useTranslation();
  // Verify Code Button and Text Code Button State, Phone Number and Verification Code Validation
  const [localState, setLocalState] = React.useState({
    textCodeBtnClicked: false,
    verifyCodeBtnClicked: false,
    verificationCode: { isValid: true, value: '', msg: '' },
    phoneNumber: { isValid: true, value: '', msg: '' },
  });
  // Unique identifier to make sendSMSCode API call
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const setApiError = () => {
    dispatch({
      type: 'UPDATE_APIERROR',
      payload: true,
    });
  };

  // POST SendSMSCode API Call
  React.useEffect(() => {
    if (state.smsNumber !== '' && localState.textCodeBtnClicked && localState.phoneNumber.isValid && count <= 5) {
      fetch(
        `${base_url}/sendSMSCode`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(removeSpace({
            smsNumber: state.phoneNumber,
            userName: state.username,
          })),
        },
      )
        .then(async (res) => {
          SendLogs({ severity: 20000, message: 'POST SendSMSCode API Call successful', userSession: state.user_session });
          SendMetrics({ name: 'SendSMSCode.Count.Metrics', value: 1, userSession: state.user_session });
        }).catch((error) => {
          console.log(error);
          SendLogs({ severity: 40000, message: `POST SendSMSCode API Call error: ${error.message}`, userSession: state.user_session });
          SendMetrics({ name: 'SendSMSCode.Count.Metrics.Fail', value: 1, userSession: state.user_session });
        });
    }
  },
  [count]);

  // POST VerifySMSCode API Call
  // Each time user blurs out of Verification Code with a valid code, API is called.
  React.useEffect(() => {
    if (localState.verificationCode.isValid === true && localState.verificationCode.value !== '' && localState.verifyCodeBtnClicked === true) {
      setLoading(true);
      fetch(
        `${base_url}/verifySMSCode`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(removeSpace({
            smsNumber: state.phoneNumber,
            couponCode: localState.verificationCode.value,
            validationType: 'SMS',
            userName: state.username,
          })),
        },
      )
        .then(async (res) => {
          setLoading(false);
          if (res.status === 204) {
            dispatch({
              type: 'MOBILE_VALIDATION',
              payload: false,
            });
            navTrack('oci-signup', state.language, 's2-verify-sms-code', 'valid-code-entered');
            if (_.isEmpty(state.selectedPromo)
                || (!_.isEmpty(state.selectedPromo) && state.selectedPromo.creditCardRequired)) {
              props.openAccordionSection(2);
            }
          } else if (res.status === 400) {
            setLocalState((localState) => ({ ...localState, ...{ verificationCode: { isValid: false, value: '', msg: t('mobileVerify.smsValidationCodeErrorDetail') } } }));
          } else if (res.status >= 500) {
            SendMetrics({ name: 'VerifySMSCode.Count.Metrics.Error', value: 1, userSession: state.user_session });
            setApiError();
          }
          SendLogs({ severity: 20000, message: 'POST VerifySMSCode API Call successful', userSession: state.user_session });
          SendMetrics({ name: 'VerifySMSCode.Count.Metrics', value: 1, userSession: state.user_session });
        }).catch((error) => {
          console.log(error);
          SendLogs({ severity: 40000, message: `POST VerifySMSCode API Call error: ${error.message}`, userSession: state.user_session });
          SendMetrics({ name: 'VerifySMSCode.Count.Metrics.Fail', value: 1, userSession: state.user_session });
        });
    }
  },
  [localState.verifyCodeBtnClicked]);

  // Countdown timer
  React.useEffect(() => {
    let isSubscribed = true;
    if (localState.textCodeBtnClicked === true) {
      const timer = isSubscribed
        ? counter > 0 && count < 6 && setInterval(() => setCounter(counter - 1), 1000) : null;
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line no-return-assign
    return () => (isSubscribed = false);
  }, [counter, count]);

  const sendCode = (e) => {
    SendAction({ action: 'send_code_button', value: 'clicked', userSession: state.user_session });
    navTrack('oci-signup', state.language, 's2-clicked-Text-me-a-code-button', 'verification-code-sent');
    if (localState.phoneNumber.isValid === true) {
      setCounter(115);
      setLocalState((localState) => ({ ...localState, textCodeBtnClicked: true }));
      setCount(count + 1);
    }
  };

  const verifyCode = () => {
    setLocalState((localState) => ({ ...localState, verifyCodeBtnClicked: true }));
  };

  const resendCode = () => {
    SendAction({ action: 'resend_code_button', value: 'clicked', userSession: state.user_session });
    setCounter(115);
    setCount(count + 1);
  };

  const handleOnChange = (value, data) => {
    dispatch({
      type: 'UPDATE_SMS_NUMBER',
      payload: value.slice(data.dialCode.length),
    });
    dispatch({
      type: 'UPDATE_PHONE_COUNTRY_CODE',
      payload: data.countryCode,
    });
  };

  const onVerificationCodeChange = (e) => {
    const val = e.target.value;
    if (val === '') {
      setLocalState((localState) => ({ ...localState, ...{ verificationCode: { isValid: false, value: '', msg: t('regExpr.smsValidationCodeErrorFormat') } } }));
    } else if (!val.match(regExp.smsValidationCode)) {
      setLocalState((localState) => ({ ...localState, ...{ verificationCode: { isValid: false, value: '', msg: t('regExpr.smsValidationCodeErrorFormat') } } }));
    } else {
      setLocalState((localState) => ({ ...localState, ...{ verificationCode: { isValid: true, value: val.trim(), msg: '' } } }));
    }
  };

  const validateMobileNum = (value, data) => {
    const val = value.slice(data.dialCode.length);
    if (val === '') {
      setLocalState((localState) => ({ ...localState, ...{ phoneNumber: { isValid: false, value: '' } } }));
    } else if (!val.match(regExp.phoneNumber)) {
      setLocalState((localState) => ({ ...localState, ...{ phoneNumber: { isValid: false, value: '' } } }));
    } else {
      setLocalState((localState) => ({
        ...localState,
        ...{ phoneNumber: { isValid: true, value: val.trim() } },
      }));
    }
    dispatch({
      type: 'VALID_PHONE_NUMBER',
      payload: localState.phoneNumber.isValid,
    });
  };

  const timer = () => {
    let mins = Math.floor(counter / 60);
    let secs = counter - mins * 60;
    if (mins < 10) {
      mins = `0${mins}`;
    }
    if (secs < 10) {
      secs = `0${secs}`;
    }
    return `${mins}:${secs}`;
  };

  const onBlurHandler = () => {
    if (localState.phoneNumber.isValid
        && !_.isEmpty(state.selectedPromo)
        && !state.selectedPromo.validationCodeRequired) {
      props.openAccordionSection(2);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="mobileVerificationContainer">
      <div className="mobileVerification">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>{t('mobileVerify.phone', 'Phone Number')}</label>

        <PhoneInput
          inputProps={{
            name: t('mobileVerify.phone', 'Phone Number'),
            required: true,
            autoFocus: true,
          }}
          enableAreaCodes={['ci']}
          areaCodes={{ ci: ['27'] }}
          masks={{ ci: ['.. .... ....'] }}
          enableTerritories
          isValid={localState.phoneNumber.isValid}
          country={_.lowerCase(state.country)}
          value={state.phoneNumber}
          onChange={(phone, data) => {
            if (localState.textCodeBtnClicked === true) {
              setLocalState((localState) => ({ ...localState, textCodeBtnClicked: false }));
            }
            dispatch({
              type: 'UPDATE_PHONE',
              payload: removeSpace(phone),
            });
            handleOnChange(phone, data);
            validateMobileNum(phone, data);
          }}
          onBlur={() => onBlurHandler()}
        />
      </div>
      {count > 5 && <Notifier type="error" message={t('mobileVerify.maxAttemptsTitle')} position="mobileMaxAttemptsError" /> }

      {(state.selectedPromo.validationCodeRequired === false && !_.isEmpty(state.selectedPromo))
      && (
      <>
        <div className="finePrint">
          {' '}
          {t('mobileVerify.mobileTrunkMessage')}
          {' '}
        </div>
        <div className="finePrint">
          {' '}
          {t('mobileVerify.mobileNumberInfo')}
          {' '}
        </div>
      </>
      )}
      {(state.selectedPromo.validationCodeRequired === true || _.isEmpty(state.selectedPromo))
      && (
      <>
        <div className="finePrint">
          {' '}
          {t('mobileVerify.mobileTrunkMessage')}
          {' '}
        </div>
        <div className="finePrint">
          {' '}
          {t('mobileVerify.mobileNumberDescription')}
          {' '}
        </div>
        {localState.textCodeBtnClicked
          ? (
            <>
              <div className="">
                {t('mobileVerify.pleaseVerify')}
                {' '}
              </div>
              <RedwoodInputText
                type="text"
                label={t('mobileVerify.verificationCode', 'Verification code')}
                name="verificationCode"
                placeholder={t('mobileVerify.verificationCodeInfo')}
                required
                value={state.verificationCode}
                valid={localState.verificationCode.isValid}
                error={localState.verificationCode.msg}
                onChange={(e) => {
                  dispatch({
                    type: 'UPDATE_VERIFICATION_CODE',
                    payload: e.target.value,
                  });
                  onVerificationCodeChange(e);
                }}
                onBlur={(e) => {
                  dispatch({
                    type: 'UPDATE_VERIFICATION_CODE',
                    payload: removeSpace(state.verificationCode),
                  });
                  setLocalState((localState) => ({ ...localState, verifyCodeBtnClicked: false }));
                }}
              />
              <Button type="solid" disabled={_.isEmpty(state.verificationCode) ? true : !localState.verificationCode.isValid} onClick={(e) => verifyCode(e)} text={t('mobileVerify.verifymycode', 'Verify my code')} loading={loading} />
              {counter === 0
        && <Button type="outline" disabled={count > 5} onClick={(e) => resendCode(e)} text="Request another code" />}
              {counter !== 0
        && <Notifier type="info" message={t('mobileVerify.mobileVerificationCode')} position="mobile" timer={timer()} />}
            </>
          )
          : <Button type="solid" disabled={_.isEmpty(state.phoneNumber) || count > 5 ? true : !localState.phoneNumber.isValid} onClick={(e) => sendCode(e)} text="Text me a code" />}
        <p className="finePrint">
          <Trans i18nKey="mobileVerify.codeRequestOpenDialogOnly">
            If you already have a verification code for the above mobile number
            and based on your email:
            {state.username}
            ,
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">click here</a>
            {' '}
            to verify code.
          </Trans>
        </p>
      </>
      )}
    </div>
  );
};

export default MobileVerifyComponent;
