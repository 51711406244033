import * as React from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../state/Store';
import { fetchAssociatedAccounts } from '../../../CourierService/FetchService';
import SendLogs from '../../../CourierService/SendLogs';
import SendMetrics from '../../../CourierService/SendMetrics';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import Notifier from '../../../common/Notifier/Notifier';

const ExistingEmailDialog = () => {
  const [state] = React.useContext(Context);
  const [sendAccounts, setSendAccount] = React.useState(false);
  const [sentAssociatedAccounts, setSentAssociatedAccounts] = React.useState(false);
  const { t } = useTranslation();

  const sendAccountsForUsername = () => {
    if (sendAccounts) return;
    setSendAccount(true);
  };

  React.useEffect(() => {
    if (state.accountNotUnique && _.isString(_.trim(state.username))) {
      const timeout = setTimeout(() => {
        setSentAssociatedAccounts(true);
        SendLogs({ severity: 20000, message: 'GET sendAssociatedAccountsByEmail timeout', userSession: state.user_session });
        SendMetrics({ name: 'SendAssociatedAccountsByEmail.Count.Metrics.Timeout', value: 1, userSession: state.user_session });
      }, 5000);
      fetchAssociatedAccounts(encodeURIComponent(state.username)).then((res) => {
        if (res.status === 204) {
          clearTimeout(timeout);
          setSentAssociatedAccounts(true);
        }
        SendLogs({ severity: 20000, message: 'GET sendAssociatedAccountsByEmail successful', userSession: state.user_session });
        SendMetrics({ name: 'SendAssociatedAccountsByEmail.Count.Metrics', value: 1, userSession: state.user_session });
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        SendLogs({ severity: 40000, message: `GET sendAssociatedAccountsByEmail error: ${error.message}`, userSession: state.user_session });
        SendMetrics({ name: 'SendAssociatedAccountsByEmail.Count.Metrics.Fail', value: 1, userSession: state.user_session });
      });
    }
  }, [sendAccounts]);

  React.useEffect(() => {
    setSendAccount(false);
    setSentAssociatedAccounts(false);
  }, [state.username]);

  if (state.accountNotUnique === false) {
    return null;
  }

  if (sentAssociatedAccounts === false) {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Notifier
        type="warning"
        position="email"
        message={t('signup.forgotAcctEmail3')}
        closeButton
        loading={sendAccounts}
        action={sendAccountsForUsername}
      />
    );
  }
  return (<Notifier type="greenCheck" position="normal" message={t('signup.requestSent')} detail={t('signup.forgotAcctEmail4')} />);
};

export default ExistingEmailDialog;
