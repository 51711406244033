/* eslint-disable no-console */
import * as React from 'react';
import './WaitingRoom.css';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Context } from '../../state/Store';
import SendMetrics from '../../CourierService/SendMetrics';
import { navTrack } from '../../config/adobeAnalytics';
import SendLogs from '../../CourierService/SendLogs';
import {
  getFeatureFlag,
  getUriDestination, isPintlabEnv, redirectTo,
} from '../Helpers';
import { fetchAccountStatusByWorkRequest, fetchPollLocationUrl } from '../../CourierService/FetchService';
import ThrottlingBanner from '../ThrottlingBanner/ThrottlingBanner';

const WaitingRoom = (props) => {
  // eslint-disable-next-line react/prop-types
  const { programType } = props;
  const { t } = useTranslation();
  const MAX_COUNTER = isPintlabEnv() ? 15 : 9;
  const DEFAULT_DECREMENT = 1;
  const [state, dispatch] = React.useContext(Context);
  const [counter, setCounter] = React.useState(MAX_COUNTER);
  const [decrement, setDecrement] = React.useState(0);
  const [pollLocationAPI, setPollLocationAPI] = React.useState(false);
  const { featureFlags } = state;

  const isPollLocationDelayed = (fn) => (getFeatureFlag('enable_heatwave', featureFlags) && _.toUpper(programType) === 'MYSQLDSAWS'
    ? setTimeout(fn, 100000)
    : setTimeout(fn, 15000));

  const canPollLocationUrl = () => {
    if (_.isEmpty(state.operationId)
        || !_.isEmpty(state.paymentStatus)
        || !_.isEmpty(state.consoleUri)
        || pollLocationAPI === false) {
      return false;
    }
    return (!state.operationId.includes('accountsworkrequest'));
  };

  const setApi403Error = (msg = '') => {
    dispatch({
      type: 'UPDATE_API403ERROR',
      payload: true,
    });
    dispatch({
      type: 'UPDATE_API403_ERROR_MSG',
      payload: msg,
    });
  };

  const setApiError = () => {
    dispatch({
      type: 'UPDATE_APIERROR',
      payload: true,
    });
  };

  const canPollAccountStatus = () => {
    if (_.isEmpty(state.operationId)
        || !_.isEmpty(state.paymentStatus)
        || !_.isEmpty(state.consoleUri)
        || pollLocationAPI === false) {
      return false;
    }
    return (state.operationId.includes('accountsworkrequest'));
  };

  React.useEffect(() => {
    if (canPollLocationUrl()) {
      fetchPollLocationUrl(encodeURIComponent(state.operationId), programType)
        .then(async (res) => {
          if (res.status === 200) {
            const result = await res.json();
            let consoleUrl = new URL(result.oneConsoleUri);
            const params = new URLSearchParams(consoleUrl.search.slice(1));
            params.append('region', state.homeRegion.value.id);
            if (getUriDestination() !== null) {
              consoleUrl = getUriDestination();
              SendLogs({ severity: '20000', message: `Console redirect to: ${consoleUrl}`, userSession: state.user_session });
            }
            consoleUrl.search = params;
            SendLogs({ severity: '20000', message: `Account Provisioned: ${consoleUrl}`, userSession: state.user_session });
            dispatch({
              type: 'UPDATE_URI',
              payload: consoleUrl,
            });
          } else if (res.status === 204) {
            SendLogs({ severity: '20000', message: 'No console url received', userSession: state.user_session });
          } else if (res.status === 403) {
            const result = await res.json();
            if (_.startsWith(result.message, 'Too many requests')) {
              SendLogs({ severity: '20000', message: 'too many requests', userSession: state.user_session });
              SendMetrics({ name: 'PollLocation.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
              setApi403Error(result.message);
            }
          } else {
            SendMetrics({ name: 'Region.Count.Metrics.Error', value: 1, userSession: state.user_session });
            setApiError();
          }
        }).catch((error) => {
          SendMetrics({ name: 'PollLocation.Count.Metrics.Error', value: 1, userSession: state.user_session });
          SendLogs({ severity: '40000', message: `fetchPollLocationUrl error : ${error}`, userSession: state.user_session });
        });
    }
  }, [counter]);

  React.useEffect(() => {
    if (canPollAccountStatus()) {
      fetchAccountStatusByWorkRequest(encodeURIComponent(state.operationId), programType,
        state.homeRegion.value.id)
        .then(async (res) => {
          if (res.status === 200) {
            const result = await res.json();
            const consoleUri = _.replace(result.oneConsoleUri, '<region>', state.homeRegion.value.id);
            let consoleUrl = new URL(consoleUri);
            const params = new URLSearchParams(consoleUrl.search.slice(1));
            params.append('region', state.homeRegion.value.id);
            if (getUriDestination() !== null) {
              consoleUrl = getUriDestination();
              SendLogs({ severity: '20000', message: `URL redirect to: ${consoleUrl}`, userSession: state.user_session });
            }
            consoleUrl.search = params;
            SendLogs({ severity: '20000', message: `Account Provisioned: ${consoleUrl}`, userSession: state.user_session });
            dispatch({
              type: 'UPDATE_URI',
              payload: consoleUrl,
            });
          } else if (res.status === 403) {
            const result = await res.json();
            if (_.startsWith(result.message, 'Too many requests')) {
              SendLogs({ severity: '20000', message: 'too many requests', userSession: state.user_session });
              SendMetrics({ name: 'Region.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
              setApi403Error(result.message);
            }
          } else {
            SendMetrics({ name: 'Region.Count.Metrics.Error', value: 1, userSession: state.user_session });
            setApiError();
          }
        }).catch((error) => {
          console.log(error);
        });
    }
  }, [counter]);

  React.useEffect(() => {
    if (pollLocationAPI) {
      setDecrement(DEFAULT_DECREMENT);
    }
  }, [pollLocationAPI]);

  React.useEffect(() => {
    if (counter === MAX_COUNTER && state.operationId !== '') {
      isPollLocationDelayed(() => {
        setPollLocationAPI(true);
      });
    }
    if (counter === 0) {
      if (getUriDestination() !== null) {
        console.log(`Window would have been redirected to ${getUriDestination()}`);
        SendLogs({ severity: '20000', message: `Redirection to ${getUriDestination()} failed after counter timed out.`, userSession: state.user_session });
      }
      dispatch({
        type: 'SHOW_THANK_YOU',
        payload: true,
      });
      SendMetrics({ name: 'PollLocation.Count.Metrics.Failed', value: 1, userSession: state.user_session });
      navTrack('oci-signup', state.language, 'provisioning-timeout-90s', 'thankyou-screen-shown');
    }

    const timer = state.operationId !== '' && counter > 0 && setInterval(() => setCounter(counter - decrement), 10000);
    return () => clearInterval(timer);
  }, [state.operationId, counter, decrement]);

  const renderRedirect = () => {
    if (state.consoleUri !== '') {
      navTrack('oci-signup', state.language, 's2-account-created', 'account-provisioned-by-oci-signup');
      redirectTo(state.consoleUri);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {renderRedirect()}
      {(state.operationId !== '' && state.paymentStatus === '' && !state.showThankYou)
         && (
         <div className="waitingRoomContainer">
           <ThrottlingBanner />
           <div className="waitingRoomHeader">
             <div className="waitingRoomLoadingGraphic" />
             <div className="waitingRoomMessage">{t('waitingroom.message')}</div>
             <div className="waitingRoomHelp">
               {t('waitingroom.needHelp')}
               <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target="_blank" rel="noopener noreferrer">{t('waitingroom.contactSupport')}</a>
             </div>
           </div>
           <div className="waitingRoomCarousel">
             <h3 className="title6">{t('waitingroom.slide1Header')}</h3>
             <hr className="" />
             <h2>{t('waitingroom.slide1Title')}</h2>
             <p>{t('waitingroom.slide1Content')}</p>
           </div>
         </div>
         )}
    </>
  );
};

export default WaitingRoom;
