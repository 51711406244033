import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Home from './Home';

function App() {
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <Router>
      <Route exact path="/" component={Home} />
    </Router>
  );
}

export default App;
