const hostname = window.location.hostname;

/* eslint-disable no-nested-ternary,camelcase */
export const base_url = (hostname === 'signup-localhost.oraclecloud.com' && process.env.REACT_APP_ENV !== 'PINTLAB') ? 'http://localhost:24000/20200828'
  : hostname === 'signup-stage.oraclecloud.com' ? 'https://signup-api-stage.oraclecloud.com:8443/20200828'
    : hostname === 'signup-miglab.oraclecloud.com' ? 'https://signup-api-stage.oraclecloud.com:7443/20200828'
      : hostname === 'signup.oraclecloud.com' ? 'https://signup-api.oraclecloud.com/20200828'
        : hostname === 'signup-pool2.oraclecloud.com' ? 'https://signup-api-stage.oraclecloud.com:9443/20200828'
          : (hostname === 'signup-pintlab.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB') ? 'https://signup-api-stage.oraclecloud.com:443/20200828'
            : hostname === 'signup-pintlab2.oraclecloud.com' ? 'https://signup-api-stage.oraclecloud.com:6443/20200828'
              : hostname === 'signup.cloud.oracle.com' ? 'https://signup-api.cloud.oracle.com/20200828' : '';

/* eslint-disable no-nested-ternary */
export const env = (hostname === 'signup-localhost.oraclecloud.com' && process.env.REACT_APP_ENV !== 'PINTLAB') ? 'uat'
  : hostname === 'signup-stage.oraclecloud.com' ? 'uat'
    : hostname === 'signup-miglab.oraclecloud.com' ? 'sitoci'
      : hostname === 'signup-pool2.oraclecloud.com' ? 'uat3'
        : (hostname === 'signup-pintlab.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB') ? 'uat'
          : hostname === 'signup-pintlab2.oraclecloud.com' ? 'sitoci'
            : hostname === 'signup.oraclecloud.com' ? 'prd'
              : hostname === 'signup.cloud.oracle.com' ? 'prd' : '';

/* eslint-disable no-nested-ternary,camelcase */
export const signup_environment = (hostname === 'signup-localhost.oraclecloud.com' && process.env.REACT_APP_ENV !== 'PINTLAB') ? 'dev'
  : hostname === 'signup-stage.oraclecloud.com' ? 'stage'
    : hostname === 'signup-miglab.oraclecloud.com' ? 'miglab'
      : hostname === 'signup-pool2.oraclecloud.com' ? 'pool2'
        : (hostname === 'signup-pintlab.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB') ? 'pintlab'
          : hostname === 'signup-pintlab2.oraclecloud.com' ? 'pintlab2'
            : hostname === 'signup.oraclecloud.com' ? 'prod-oraclecloud'
              : hostname === 'signup.cloud.oracle.com' ? 'prod-oracle' : '';

/* eslint-disable no-nested-ternary,camelcase */
export const courier_service_url = (hostname === 'signup-localhost.oraclecloud.com' && process.env.REACT_APP_ENV !== 'PINTLAB') ? 'https://courier-service-stage.oraclecloud.com/20200828'
  : hostname === 'signup-stage.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
    : hostname === 'signup-miglab.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
      : hostname === 'signup-pool2.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
        : (hostname === 'signup-pintlab.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB') ? 'https://courier-service-stage.oraclecloud.com/20200828'
          : hostname === 'signup-pintlab2.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
            : hostname === 'signup.oraclecloud.com' ? 'https://courier-service.oraclecloud.com/20200828'
              : hostname === 'signup.cloud.oracle.com' ? 'https://courier-service.oraclecloud.com/20200828' : '';

export const planned_outage_banner_url
  = (hostname === 'signup-pintlab.oraclecloud.com' || hostname === 'signup-pintlab2.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB')
  ? 'http://dashboard.pint.oraclecorp.com/ords/pint/outage/list/planned_outage' : '';

export const unplanned_outage_banner_url
  = (hostname === 'signup-pintlab.oraclecloud.com' || hostname === 'signup-pintlab2.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB')
  ? 'http://dashboard.pint.oraclecorp.com/ords/pint/outage/list/unplanned_outage' : '';

export const outage_dashboard_url
  = (hostname === 'signup-pintlab.oraclecloud.com' || hostname === 'signup-pintlab2.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB')
  ? 'http://dashboard.pint.oraclecorp.com/ords/pint/r/pintlab-dashboard/home' : '';

export const is_pintlab_env = hostname === 'signup-pintlab.oraclecloud.com' || hostname === 'signup-pintlab2.oraclecloud.com' || process.env.REACT_APP_ENV === 'PINTLAB';

export const tld_url = 'https://data.iana.org/TLD/tlds-alpha-by-domain.txt';
