import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Context } from '../../state/Store';
import TermsOfUseBody from './Helper';
import TermsOfUseLinks from '../../Constants/legalLinks';
import './TermsOfUseComponent.css';
import Button from '../../common/Button/Button';
import RedwoodCheckbox from '../../common/Checkbox/RedwoodCheckbox';
import SendAction from '../../CourierService/SendAction';
import { navTrack } from '../../config/adobeAnalytics';

const TermsOfUseComponent = (props) => {
  const [state, dispatch] = React.useContext(Context);
  const [checked, setChecked] = React.useState(false);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const { t } = useTranslation();

  const doClick = () => {
    SendAction({
      action: 'termsOfUse_continue_button',
      value: 'clicked',
      userSession: state.user_session,
    });
    navTrack(
      'oci-signup',
      state.language,
      's1-clicked-continue-button',
      'completed-account-information-section',
    );
    dispatch({
      type: 'NEXT_STEP',
      payload: true,
    });
  };

  const setContinueButton = () => {
    let flag = true;
    if (state.country === 'RU' || state.country === 'TR') {
      /* eslint-disable no-unused-expressions,react/prop-types */
      !checked1 === false
      && !checked2 === false
      && !checked3 === false
      && props.status === false
        ? (flag = false)
        : (flag = true);
    } else if (state.country === 'KR') {
      !checked === false
      && !checked1 === false
      && !checked2 === false
      && !checked3 === false
      && !checked4 === false
      && props.status === false
        ? (flag = false)
        : (flag = true);
    } else if (state.country === 'PH') {
      !checked1 === false && props.status === false
        ? (flag = false)
        : (flag = true);
    } else if (state.country === 'NG') {
      !checked === false && props.status === false
        ? (flag = false)
        : (flag = true);
    } else {
      flag = props.status;
    }
    return flag;
  };

  return (
    /* eslint-disable react/jsx-filename-extension,react/no-unescaped-entities */
    <div className="termsComponent">
      <h2 className="title6">{t('termsOfUse.termsOfUseLabel', 'Terms of Use')}</h2>

      <div className="body2 terms">
        {state.country === 'RU' || state.country === 'TR' ? (
          <div className="margin-top-10">
            <>
              <RedwoodCheckbox
                checked={checked1}
                onChange={() => setChecked1(!checked1)}
              >
                {t('termsOfUse.ruTrTerms1')}
              </RedwoodCheckbox>
              <RedwoodCheckbox
                checked={checked2}
                onChange={() => setChecked2(!checked2)}
              >
                <Trans i18nKey="termsOfUse.ruTrTerms2">
                  Use of Oracle's web site is subject to the
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={TermsOfUseLinks.termsOfUseLink}
                  >
                    Oracle.com Terms of Use
                  </a>
                  and
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={TermsOfUseLinks.privacyLink}
                  >
                    Oracle Japan Privacy Statement
                  </a>
                </Trans>
              </RedwoodCheckbox>
              <RedwoodCheckbox
                checked={checked3}
                onChange={() => setChecked3(!checked3)}
              >
                <Trans i18nKey="termsOfUse.ruTrTerms3">
                  Oracle may transfer you personal information collected in
                  connection with your registration on this website to its
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={TermsOfUseLinks.affiliatesGloballyLink}
                  >
                    affiliates globally
                  </a>
                  and to
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={TermsOfUseLinks.thirdPartyLink}
                  >
                    third party entities
                  </a>
                  that provide services to Oracle, including to affiliates and
                  entities located in countries which are not considered to have
                  an adequate level of data protection.
                </Trans>
              </RedwoodCheckbox>
            </>
          </div>
        ) : (
          <>
            {state.country === 'JP' ? (
              <>
                <p>
                  <Trans i18nKey="termsOfUse.jpTerms">
                    By clicking on the button, you understand and agree that the use of Oracle's web
                    site is subject to the
                    <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.termsOfUseLink}>
                      Oracle.com Terms of Use
                    </a>
                    and
                    <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.japanPrivacyLink}>
                      Oracle Japan Privacy Statement
                    </a>
                    and
                    <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.japanPrivacyLink}>
                      Oracle privacy policy
                    </a>
                    . Additional details regarding Oracle's collection and use of your personal
                    information, including information about access, retention, rectification,
                    deletion, security, cross-border transfers and other topics, is available
                    in the Oracle Privacy Policy.
                  </Trans>
                </p>
              </>
            ) : (
              <>
                {state.country === 'PH' ? (
                  <>
                    <RedwoodCheckbox
                      checked={checked1}
                      onChange={() => setChecked1(!checked1)}
                    >
                      {t('termsOfUse.phTerms1')}
                    </RedwoodCheckbox>
                    <p>
                      <Trans i18nKey="termsOfUse.phTerms2">
                        You understand and agree that use of Oracle's web site
                        is subject to the
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={TermsOfUseLinks.termsOfUseLink}
                        >
                          Oracle.com Terms of Use
                        </a>
                        and
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={TermsOfUseLinks.privacyLink}
                        >
                          Oracle Privacy Policy
                        </a>
                        .
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="termsOfUse.phTerms3">
                        You understand and agree that Oracle may transfer your
                        personal information collected in connection with your
                        registration on this website to its
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={TermsOfUseLinks.affiliatesGloballyLink}
                        >
                          affiliates globally
                        </a>
                        and to
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={TermsOfUseLinks.thirdPartyLink}
                        >
                          third party entities
                        </a>
                        that provide services to Oracle, including to affiliates
                        and entities located in countries which are not
                        considered by Philippine law to have an adequate level
                        of data protection.
                      </Trans>
                    </p>
                  </>
                ) : (
                  <>
                    {state.country === 'KR' ? (
                      <>
                        <RedwoodCheckbox
                          checked={checked1}
                          onChange={() => setChecked1(!checked1)}
                        >
                          {t('termsOfUse.krTerms2')}
                          <p>{t('termsOfUse.krTerms2Sub1')}</p>

                          <p>
                            <Trans i18nKey="termsOfUse.krTerms2Sub2">
                              I understand that additional details regarding
                              Oracle's collection and use of my personal
                              information are available in the
                              <a
                                href={TermsOfUseLinks.privacyLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Oracle Privacy Policy
                              </a>
                              .
                            </Trans>
                          </p>
                        </RedwoodCheckbox>
                        <RedwoodCheckbox
                          checked={checked2}
                          onChange={() => setChecked2(!checked2)}
                        >
                          <p>
                            <Trans i18nKey="termsOfUse.krTerms3">
                              I agree that Oracle may provide my personal
                              information as collected above to its corporate
                              affiliates for use and retention as specified
                              above. A list of corporate affiliates is available
                              <a
                                href="https://www.oracle.com/corporate/oracle-affiliates.html"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                here
                              </a>
                              .
                            </Trans>
                          </p>
                        </RedwoodCheckbox>
                        <RedwoodCheckbox
                          checked={checked3}
                          onChange={() => setChecked3(!checked3)}
                        >
                          <p>
                            <Trans i18nKey="termsOfUse.krTerms4">
                              I agree that Oracle may transfer my personal
                              information as collected above internationally to
                              its corporate affiliates and delegates in those
                              countries where Oracle has operations for use and
                              retention as specified above. When you subscribe
                              for the online membership, subsequent to the
                              collection of personal information, such personal
                              information may be transferred via
                              telecommunication network or other electronic
                              based means. A list of corporate affiliates is
                              available
                              <a
                                href="https://www.oracle.com/corporate/oracle-affiliates.html"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                here
                              </a>
                              . A list of these delegates and its delegated work
                              scope is available
                              <a href={TermsOfUseLinks.thirdPartyLink} target="_blank" rel="noopener noreferrer">
                                here
                              </a>
                              . A list of these countries is available
                              <a
                                href={TermsOfUseLinks.affiliatesGloballyLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                here
                              </a>
                              .
                            </Trans>
                          </p>
                        </RedwoodCheckbox>
                        <RedwoodCheckbox
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        >
                          <p>
                            <Trans i18nKey="termsOfUse.krTerms5">
                              I agree that Oracle may delegate my personal
                              information for processing to third party service
                              providers that perform functions on Oracle’s behalf
                              consistent with the above. A list of these delegates
                              and its delegated work scope is available
                              <a
                                href={TermsOfUseLinks.thirdPartyLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                here
                              </a>
                              .
                            </Trans>
                          </p>
                        </RedwoodCheckbox>
                        <RedwoodCheckbox
                          checked={checked4}
                          onChange={() => setChecked4(!checked4)}
                        >
                          <p>
                            <Trans i18nKey="termsOfUse.krDataTerms">
                              {/* eslint-disable-next-line max-len */}
                              I understand that the following personal information may be transferred overseas:
                            </Trans>
                            <ul>
                              <li>
                                <Trans i18nKey="termsOfUse.krDataTerms1">
                                  {/* eslint-disable-next-line max-len */}
                                  Personal information transferred - Name, email address, address, phone.
                                </Trans>
                              </li>
                              <li>
                                <Trans i18nKey="termsOfUse.krDataTerms2">
                                  Name of target country - U.S.
                                </Trans>
                              </li>
                              <li>
                                <Trans i18nKey="termsOfUse.krDataTerms3">
                                  {/* eslint-disable-next-line max-len */}
                                  Method and Timing of Transfer -  Real time transfer through networks.
                                </Trans>
                              </li>
                              <li>
                                <Trans i18nKey="termsOfUse.krDataTerms4">
                                  Name of the personal information transferee - Oracle Corporation.
                                </Trans>
                              </li>
                              <li>
                                <Trans i18nKey="termsOfUse.krDataTerms5">
                                  {/* eslint-disable-next-line max-len */}
                                  Purpose of the transfer - To create and manage Oracle Cloud accounts in order to provide Oracle Cloud services.
                                </Trans>
                              </li>
                              <li>
                                <Trans i18nKey="termsOfUse.krDataTerms6">
                                  {/* eslint-disable-next-line max-len */}
                                  Retention Period - For as long as the cloud account remains active. After service termination, minimal account information will be held for records retention purposes.
                                </Trans>
                              </li>
                            </ul>
                          </p>
                        </RedwoodCheckbox>
                        <p>{t('termsOfUse.krFinalTerms')}</p>
                      </>
                    ) : (
                      <>
                        {state.country === 'NG' ? (
                          <>
                            <RedwoodCheckbox
                              checked={checked}
                              onChange={() => setChecked(!checked)}
                            >
                              {t('termsOfUse.ngTerms1')}
                            </RedwoodCheckbox>
                            <p>
                              <Trans i18nKey="termsOfUse.ngTerms2">
                                Oracle may transfer your personal information
                                collected in connection with your registration
                                on this website to its
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={TermsOfUseLinks.affiliatesGloballyLink}
                                >
                                  affiliates globally
                                </a>
                                and to
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={TermsOfUseLinks.thirdPartyLink}
                                >
                                  third party entities
                                </a>
                                that provide services to Oracle, including to
                                affiliates and entities located in countries
                                which are not considered to have an adequate
                                level of data protection.
                              </Trans>
                            </p>
                            <p>
                              <Trans i18nKey="termsOfUse.ngTerms3">
                                By clicking on the button, you understand and
                                agree that the use of Oracle's web site is
                                subject to the
                                <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.termsOfUseLink}>
                                  Oracle.com Terms of Use
                                </a>
                                . Additional details regarding Oracle's
                                collection and use of your personal information,
                                including information about access, retention,
                                rectification, deletion, security, cross-border
                                transfers and other topics, is available in the
                                <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.privacyLink}>
                                  Oracle privacy policy
                                </a>
                                .
                              </Trans>
                            </p>
                          </>
                        ) : (
                          <>
                            {state.country === 'KE' ? (
                              <>
                                <p>
                                  <Trans i18nKey="termsOfUse.keTerms1">
                                    Oracle may transfer your personal
                                    information collected in connection with
                                    your registration on this website to its
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        TermsOfUseLinks.affiliatesGloballyLink
                                      }
                                    >
                                      affiliates globally
                                    </a>
                                    and to
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={TermsOfUseLinks.thirdPartyLink}
                                    >
                                      third party entities
                                    </a>
                                    that provide services to Oracle, including
                                    to affiliates and entities located in
                                    countries which are not considered to have
                                    an adequate level of data protection.
                                  </Trans>
                                </p>
                                <p>
                                  <Trans i18nKey="termsOfUse.keTerms2">
                                    By clicking on the button, you understand
                                    and agree that the use of Oracle's web site
                                    is subject to the
                                    <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.termsOfUseLink}>
                                      Oracle.com Terms of Use
                                    </a>
                                    . Additional details regarding Oracle's collection and use of
                                    your personal information, including information about access,
                                    retention, rectification, deletion, security, cross-border
                                    transfers and other topics, is available in the
                                    <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.privacyLink}>
                                      Oracle privacy policy
                                    </a>
                                    .
                                  </Trans>
                                </p>
                              </>
                            ) : (
                              <TermsOfUseBody
                                country={state.country}
                                termsOfUseLink={TermsOfUseLinks.termsOfUseLink}
                                privacyPolicyLink={TermsOfUseLinks.privacyLink}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {(state.validateEmail === true || state.emailToken !== '') && (
        <Button
          type="solid"
          disabled={setContinueButton()}
          onClick={(e) => doClick(e)}
          text={t('termsOfUse.continue', 'Continue')}
        />
      )}
    </div>
  );
};

export default TermsOfUseComponent;
