import * as React from 'react';
import './CircularLoader.css';

const CircularLoader = (props) => {
  const { ...rest } = props;
  return (
    <div
      {...rest}
      className={`loader ${props.className}`}
      data-testid={'circular-loader'}
    >
      <div className="loader-well" />
      <div className="loader-track" />
    </div>
  );
};

export default CircularLoader;
