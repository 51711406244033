/*
eslint-disable no-console,camelcase,react/prop-types,
import/no-named-as-default,import/no-named-as-default-member
*/
import * as React from 'react';
import * as _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { openPS } from '@os/os-payment-service/lib/PSCommunicationApi';
import { Context } from '../../state/Store';
import './SecurityVerificationComponent.css';
import Button from '../../common/Button/Button';
import Icon from '../../common/Icon/Icon';
import Notifier from '../../common/Notifier/Notifier';
import { env } from '../../config/apiConfig';
import SendLogs from '../../CourierService/SendLogs';
import SendAction from '../../CourierService/SendAction';
import SendMetrics from '../../CourierService/SendMetrics';
import { navTrack } from '../../config/adobeAnalytics';
import PSD2OrgIDList from '../../data/PSD2OrgID.json';
import { fetchPaymentsByEmail } from '../../CourierService/FetchService';
import { getFeatureFlag, getWindowHost } from '../Helpers';

const SecurityVerificationComponent = (props) => {
  const { t } = useTranslation();
  const [state, dispatch] = React.useContext(Context);
  const [openModal, setOpenModal] = React.useState(false);
  const {
    showPaymentDetails, displayPaymentDetails, updateCreateAccountStatus,
    updateCheckbox, programType,
  } = props;
  const [disableButton, setDisableButton] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [clicked, setClicked] = React.useState(state.numPaymentVerifyBtnClicked);
  const address_state = _.isEmpty(state.address.selectedState) ? state.address.state : _.split(state.address.selectedState.value.id, '-', 2)[1];
  // const address_province = _.isEmpty(state.address.selectedProvince) ? state.address.province
  // : _.split(state.address.selectedProvince.value.id, '-', 2)[1];
  const [paymentRetries, setPaymentRetries] = React.useState(0);
  const altEngCompanyFlag = !!((state.country === 'JP' || state.country === 'CN' || state.country === 'TW' || state.country === 'KR'));
  const { featureFlags } = state;
  const heatwavePaid = getFeatureFlag('enable_taxfields', featureFlags) && state.isPaid && programType && programType.toUpperCase() === 'MYSQLDSAWS';
  const address_province = (address) => {
    if (_.isEmpty(address.selectedProvince)) {
      return address.province;
    }
    if (address.selectedProvince.value.id.includes('-')) {
      return _.split(address.selectedProvince.value.id, '-', 2)[1];
    }
    return address.selectedProvince.value.id;
  };
  const setApiError = () => {
    dispatch({
      type: 'UPDATE_APIERROR',
      payload: true,
    });
  };

  const setApi403Error = (msg = '') => {
    dispatch({
      type: 'UPDATE_API403ERROR',
      payload: true,
    });
    dispatch({
      type: 'UPDATE_API403_ERROR_MSG',
      payload: msg,
    });
  };

  const openOPayModal = (tokens) => {
    SendLogs({ severity: '20000', message: `OPay window opened, EmailID:${state.username}`, userSession: state.user_session });
    const tfa = getWindowHost();
    openPS({
      paymentId: tokens.id,
      params: {
        tfa,
        apiToken: tokens.paymentToken.jwtToken,
        userToken: tokens.paymentToken.userToken, // ps-token
      },
      env,
      mode: 'modal',
      onInit: () => {
        navTrack('oci-signup', state.language, 's2-clicked-Add-payment-verification-method-button', 'Cybersource-Window-opened');
        SendLogs({ severity: '20000', message: `openWindow init, EmailID:${state.username}`, userSession: state.user_session });
      },
      success: (payload) => {
        navTrack('oci-signup', state.language, 's2-clicked-close-btn-payment-modal', 'payment-verification-successful');
        updateCheckbox(false);
        displayPaymentDetails(true);
        SendAction({ action: 'OPay payment success', value: 'OPay payment success', userSession: state.user_session });
        SendMetrics({ name: 'OPayModal.Count.Metrics', value: 1, userSession: state.user_session });
        SendLogs({ severity: '20000', message: `OPay payment success, EmailID:${state.username}, CloudAccountName:${state.accountName}`, userSession: state.user_session });
        dispatch({
          type: 'UPDATE_BILLING_INFO',
          payload: [payload.address, payload.paymentOptions[0]],
        });
      },
      failure: (reason) => {
        if (state.numPaymentVerifyBtnClicked < 5) {
          setDisableButton(false);
        }
        navTrack('oci-signup', state.language, 's2-clicked-close-btn-payment-modal', 'payment-verification-failure');
        SendLogs({ severity: '40000', message: `${JSON.stringify(reason)} EmailID:${state.username}`, userSession: state.user_session });
        SendMetrics({ name: 'OPayModal.Count.Metrics.Fail', value: 1, userSession: state.user_session });
      },
      cancel: () => {
        SendAction({ action: 'OPay window closed', value: 'user closed the OPay window', userSession: state.user_session });
        SendMetrics({ name: 'OPayModal.Count.Metrics.Closed', value: 1, userSession: state.user_session });
        SendLogs({ severity: '40000', message: `OPay window closed, EmailID:${state.username}`, userSession: state.user_session });
        if (state.numPaymentVerifyBtnClicked < 5) {
          setDisableButton(false);
        }
        navTrack('oci-signup', state.language, 's2-clicked-close', 'payment-verification-not-completed-by-user');
      },
    });
  };

  const address = !_.isEmpty(state.selectedPromo) && state.selectedPromo.programType === 'SE-TRIALS'
    ? {
      line1: ' ',
      line2: ' ',
      line3: ' ',
      line4: ' ',
      city: ' ',
      postalCode: ' ',
      state: address_state,
      county: ' ',
      province: address_province(state.address),
      country: state.country,
      emailAddress: state.username,
      companyName: altEngCompanyFlag === true ? state.altEngCompanyFlag : state.companyName,
      firstName: state.firstName,
      lastName: state.lastName,
    } : {
      line1: state.address.address1,
      line2: state.address.address2,
      line3: state.address.address3,
      line4: state.address.address4,
      city: state.address.city,
      postalCode: state.address.postalcode,
      state: address_state,
      county: state.address.county,
      province: address_province(state.address),
      country: state.country,
      emailAddress: state.username,
      companyName: altEngCompanyFlag === true ? state.altEngCompanyFlag : state.companyName,
      firstName: state.firstName,
      lastName: state.lastName,
    };

  React.useEffect(() => {
    if (openModal === true) {
      setShowError(false);
      setLoading(true);
      if (state.country === 'MX' && getFeatureFlag('enable_paypal', featureFlags) && _.isEmpty(state.selectedPromo) && state.defaultPromo.type === 'Standard') {
        state.orgId = '189915';
      }
      if (getFeatureFlag('enable_local_payment', featureFlags)) {
        if (state.country === 'CO') {
          state.orgId = '1000258';
          state.countryInfo.tc.URL = 'https://www.oracle.com/goto/oraclecsa-co';
        }
      }

      const paidOrFree = (getFeatureFlag('enable_paid', featureFlags) && state.isPaid === true ? 'CLOUD_SIGNUP_PAID' : 'CLOUD_SIGNUP');
      const body = JSON.stringify({
        config: {
          clientId: 'OCI_ACCMGMT',
          clientProfile: paidOrFree,
          country: state.country,
          language: 'en',
          currency: state.countryInfo.currency.code,
          organizationId: state.orgId,
          paymentGateway: {
            merchantDefinedData: {
              skuList: _.isEmpty(state.selectedPromo)
                ? state.defaultPromo.partNumber : state.selectedPromo.partNumber,
              promoType: _.isEmpty(state.selectedPromo)
                ? state.defaultPromo.type : state.selectedPromo.type,
              campaignId: _.isEmpty(state.selectedPromo) ? '' : state.selectedPromo.campaignId,
              cloudAccountName: state.accountName,
              phoneCountryCode: state.phoneCountryCode,
              phoneNumber: state.phoneNumber,
            },
          },
        },
        items: [
          {
            title: state.promoTitle,
          },
        ],
        address,
      });

      fetchPaymentsByEmail(encodeURIComponent(state.username), body).then(async (res) => {
        setLoading(false);
        if (res.status === 201) {
          const result = await res.json();
          SendLogs({ severity: '20000', message: `POST payments API successful: EmailID:${state.username}, CloudAccountName:${state.accountName}`, userSession: state.user_session });
          SendMetrics({ name: 'Payments.Count.Metrics', value: 1, userSession: state.user_session });
          dispatch({
            type: 'PAYMENT_RESPONSE',
            payload: result,
          });
          openOPayModal(result);
        } else if (res.status === 400) {
          setShowError(true);
          const result = await res.json();
          SendLogs({ severity: '40000', message: `: POST payments API failed:${JSON.stringify(result)} EmailID:${state.username}, CloudAccountName:${state.accountName}`, userSession: state.user_session });
          SendMetrics({ name: 'Payments.Count.Metrics.Error', value: 1, userSession: state.user_session });
          if (clicked < 5) {
            setDisableButton(false);
          }
        } else if (res.status === 403) {
          const result = await res.json();
          setApi403Error(result.message);
          if (_.startsWith(result.message, 'Too many requests')) {
            setShowError(false);
            SendLogs({ severity: '40000', message: `Too many requests, EmailID:${state.username}, CloudAccountName:${state.accountName}`, userSession: state.user_session });
            SendMetrics({ name: 'Payments.Count.Metrics.TooManyRequests', value: 1, userSession: state.user_session });
          }
        } else if (res.status >= 500) {
          setShowError(true);
          SendLogs({ severity: '40000', message: `POST payments API failed, EmailID:${state.username}, CloudAccountName:${state.accountName}`, userSession: state.user_session });
          SendMetrics({ name: 'Payments.Count.Metrics.Error', value: 1, userSession: state.user_session });
          if (paymentRetries === 3) {
            setApiError();
          } else {
            setPaymentRetries(paymentRetries + 1);
          }
        }
      }).catch((error) => {
        console.log(error);
        SendLogs({ severity: 40000, message: `POST payments error: ${error.message} EmailID:${state.username}, CloudAccountName:${state.accountName}`, userSession: state.user_session });
        SendMetrics({ name: 'Payments.Count.Metrics.Fail', value: 1, userSession: state.user_session });
      });
    }
  }, [clicked, paymentRetries]);

  const addPayment = () => {
    SendAction({ action: 'User clicked on Add Payment Verification Button', value: 'Payments API is called', userSession: state.user_session });
    SendLogs({ severity: 20000, message: `Payments API is called,  EmailID: ${state.username}`, userSession: state.user_session });
    if (clicked < 5) {
      setClicked((prev) => {
        dispatch({
          type: 'PAYMENT_VERIFICATION_TIMES',
          payload: prev + 1,
        });
        return prev + 1;
      });
      displayPaymentDetails(false);
      dispatch({
        type: 'UPDATE_BILLING_INFO',
        payload: [{}, {}],
      });
      updateCreateAccountStatus(false, false);
      setDisableButton(true);
      dispatch({ type: 'PAYMENT_ERROR', payload: {} });
      setOpenModal(true);
      setPaymentRetries(0);
    } else {
      SendAction({ action: 'User clicked on Add Payment Verification Button', value: 'User tried to Add Credit Card more than 5 times', userSession: state.user_session });
      SendLogs({ severity: 40000, message: `User tried to Add Credit Card more than 5 times,  EmailID: ${state.username}, CloudAccountName:${state.accountName}`, userSession: state.user_session });
    }
  };

  let errorType = '';
  let creditCardErrorTitle = '';
  const errorDisplay = () => {
    if (!_.isEmpty(state.paymentError)) {
      const errorCode = state.paymentError.paymentGatewayAuthReasonCode;
      const avs = state.paymentError.paymentGatewayAuthAVSCode;
      const activeRule = state.paymentError.paymentGatewayTopDecisionManagerActiveRule;

      if (activeRule === 'CSDM002') {
        errorType = 'multiplePromos';
        creditCardErrorTitle = t('cyberSourceErrMsg.rejectTitle');
      } else if (activeRule === 'CSDM003') {
        errorType = 'prepaidCard';
        creditCardErrorTitle = t('cyberSourceErrMsg.rejectTitle');
      } else if ((avs === 'C' || avs === 'N') && (errorCode === '481' || activeRule === 'CSDM000')) {
        errorType = 'avs';
        creditCardErrorTitle = t('cyberSourceErrMsg.rejectTitle');
      } else if (errorCode === '481' && !(avs === 'C' || avs === 'N')) {
        errorType = '481';
        creditCardErrorTitle = t('cyberSourceErrMsg.rejectTitle');
      } else if (errorCode === '231') {
        errorType = '231';
        creditCardErrorTitle = t('cyberSourceErrMsg.2xxTitle');
      } else if (errorCode === '202') {
        errorType = '202';
        creditCardErrorTitle = t('cyberSourceErrMsg.2xxTitle');
        // eslint-disable-next-line no-prototype-builtins
      } else if ((errorCode === '203' || errorCode === '233') && PSD2OrgIDList.hasOwnProperty(state.orgId)) {
        errorType = 'psd2';
        creditCardErrorTitle = t('cyberSourceErrMsg.2xxTitle');
      } else {
        errorType = '2xx';
        creditCardErrorTitle = t('cyberSourceErrMsg.2xxTitle');
      }
    }
  };

  const dateFormat = (date) => {
    const [year, month] = _.split(date, '-', 2);
    return `${year}/${month}`;
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {heatwavePaid
      && (
      <>
        <p>
          <Trans i18nKey="securityVerification.paygo">
            You are signing up for
            <strong>Pay As You Go</strong>
            . There&apos;s no minimum term and no prepayment required.
            Usage will be billed monthly and charged to the credit card on file.
            Pay As You Go is for accounts where the monthly spending is up to a few thousand dollars
            . For larger accounts, we recommend discussing options with our sales team.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="securityVerification.actualUsage">
            You will be charged for the actual usage of all services that
            you activate within Your Cloud Services Account, based on Oracle&apos;s
            pricing at the time of usage for such services (“Pay As You Go”). See
            <a href="https://www.oracle.com/cloud/price-list.html">https://www.oracle.com/cloud/price-list.html</a>
            .
          </Trans>
        </p>
        <p>
          <Trans i18nKey="securityVerification.usdHold">
            A $100 USD hold (or the equivalent in your local currency) will be placed on your card.
            The hold is temporary and will be removed and never charged to you.
          </Trans>
        </p>
      </>
      )}
      {!heatwavePaid
      && (
      <>
        <p>
          <Trans i18nKey="securityVerification.nocharge">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <strong>You won’t be charged for using OCI Free Tier.</strong>
            {' '}
            {/* eslint-disable-next-line max-len */}
            If you choose to upgrade your account to Paid tier, you will only be charged for services used over your Always Free limits. If you don’t upgrade your account to Paid tier, you will continue to have access to Always Free services.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="securityVerification.txtCreditCard">
            {/* eslint-disable-next-line max-len */}
            You may see a small, temporary charge on your payment method. This is a verification hold that will be removed automatically. See the
            <a href="https://www.oracle.com/cloud/free/faq.html" target="_blank" rel="noopener noreferrer">FAQ</a>
            {' '}
            for more information
          </Trans>
          .
        </p>
      </>
      )}

      {!showPaymentDetails
      && (
      <>
        {state.numPaymentVerifyBtnClicked === 5
          ? <Notifier type="error" message={t('securityVerification.maxAttemptsTitle')} position="maxAttemptsError" />
          : (
            <>
              {showError && (<Notifier type="error" message={t('signup.apiError')} position="apiError" />)}
              <Button
                type="solid"
                disabled={disableButton}
                onClick={(e) => addPayment(e)}
                text={heatwavePaid ? t('securityVerification.addPaymentLatest', 'Add payment method') : t('securityVerification.addPayment', 'Add payment verification method')}
                loading={loading}
              />
            </>
          )}

        <p>
          <Trans i18nKey="securityVerification.txtCreditCard4">
            {/* eslint-disable-next-line max-len,react/no-unescaped-entities */}
            Oracle uses third-party payment processor CyberSource for Oracle Store payment processing. CyberSource will request and collect certain information as part of the payment processing. Please refer to CyberSource's privacy statement at:
            <a href="http://www.cybersource.com/privacy" target="_blank" rel="noopener noreferrer">http://www.cybersource.com/privacy</a>
            {' '}
            for the terms applicable to the data collected
          </Trans>
          .
        </p>
      </>
      )}
      {showPaymentDetails
    && (
    <>

      <div className="thankYouVerify">{t('securityVerification.thankyou')}</div>
      <div className={`row paymentInfo ${!_.isEmpty(state.paymentError) ? 'err' : ''}`}>
        {state.paymentOptions.paymentMethod === 'PAYPAL' ? (
          <>
            <div className="col-xs-3">
              <Icon testId="paypal-icon" name="paypal" />
            </div>
            <div className="col-xs-8">
              {state.paymentOptions.paypalId}
            </div>
          </>
        )
          : (
            <>
              <div className="col-xs-3">
                <Icon testId="card-type-icon" name={_.lowerCase(state.paymentOptions.creditCardType)} />
              </div>

              <div className="col-xs-4">
                ************
                {state.paymentOptions.creditCardLastDigits}
              </div>

              <div className="col-xs-4 expiresOn">
                <span>
                  {t('securityVerification.expireson')}
                  {' '}
                </span>
                {dateFormat(state.paymentOptions.creditCardExpirationDate)}
              </div>
            </>
          )}

        <div className="col-xs-1">
          <Icon testId="payment-status-icon" name={`${!_.isEmpty(state.paymentError) ? 'redCheck' : 'greenCheck'}`} />
        </div>
      </div>

      {!_.isEmpty(state.paymentError)
      && (
      <>
        {errorDisplay()}
        <Notifier position="payment" type="error" message={creditCardErrorTitle} errorType={errorType} action={addPayment} />
      </>
      )}
      <p>
        <Trans i18nKey="securityVerification.txtCreditCard4">
          {/* eslint-disable-next-line max-len,react/no-unescaped-entities */}
          Oracle uses third-party payment processor CyberSource for Oracle Store payment processing. CyberSource will request and collect certain information as part of the payment processing. Please refer to CyberSource's privacy statement at:
          <a href="http://www.cybersource.com/privacy" target="_blank" rel="noopener noreferrer">http://www.cybersource.com/privacy</a>
          {' '}
          for the terms applicable to the data collected
        </Trans>
        .
      </p>
    </>
    )}

    </>
  );
};
export default SecurityVerificationComponent;
