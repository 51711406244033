import * as React from 'react';
import './Notifier.css';
import Icon from "./../Icon/Icon";
import { Trans } from "react-i18next";
import CircularLoader from "../CircularLoader/CircularLoader";

/*
    vars: type, message, detail
*/

class Notifier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotifier: true
        };
    }


    closeNotifier = e => {
        this.setState({showNotifier: false});
    };

    onClickHandler = e => {
        window.location.reload();
    };


    render() {
        const displayEmailTimer= this.props.emailTimer;
        const displayMobileTimer = this.props.timer;
        return (
            <>
            { this.state.showNotifier &&
                <div className="notifierContainer">
                    <div className="row">
                        <div className="col-xs-11">
                            <Icon name={this.props.type}/>
                            <h4>{this.props.message}</h4>
                        </div>
                        <div className="col-xs-1">
                            {this.props.closeButton &&
                            <div className="closeBtn">
                                <Icon name="close" onClick={this.closeNotifier}/>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {this.props.position === 'email' &&
                            <p>
                                <Trans i18nKey='signup.forgotAcctEmail2'><a href="#" onClick={e => this.props.action()}>Click here</a>, and we'll send you an email with the account details</Trans>
                            </p>
                            }
                            {this.props.position === 'emailVerificationTimer' &&
                                <p><Trans i18nKey='signup.emailVerifyTimer'>If you don't receive the emailed verification link in {{ displayEmailTimer }}, you can try again. The link is valid only for 30 minutes.</Trans></p>

                            }
                            {this.props.position === 'sendEmailFailure' &&
                                <p><Trans i18nKey='signup.emailSendFailure'>Your verification link wasn't delivered to the email provided</Trans>.</p>
                            }
                            {this.props.position === 'normal' &&
                                <p> {this.props.detail} </p>
                            }
                            {this.props.position === 'mobile' &&
                                <p><Trans i18nKey='mobileVerify.mobileVerifyTimer'>If you don't receive the mobile verification code in {{ displayMobileTimer }} minutes , then you may request another code.</Trans></p>
                            }
                            {this.props.errorType === 'multiplePromos' &&
                                <p><Trans i18nKey='cyberSourceErrMsg.multiplePromos'>It looks like you already have an account using a different email address. Oracle has a policy of one promotion per person. Please check <a href="https://myaccount.cloud.oracle.com/mycloud/faces/getinfo.jspx" target="_blank" rel="noopener noreferrer">here</a> to see existing accounts or see our FAQ for more information on eligibility. If you believe this is in error, please contact <a href="https://store-oracle.custhelp.com/app/CP_CP" target='_blank' rel="noopener noreferrer">Oracle Customer Service</a></Trans></p>
                            }
                            {this.props.errorType === 'prepaidCard' &&
                                <p><Trans i18nKey='cyberSourceErrMsg.prepaidCard'>Your bank has identified this card as a prepaid, virtual, or single use card. This is not an acceptable form of payment, please <a href='#' onClick={e => this.props.action()}>edit your payment method</a> and add a new card. See <a href="https://www.oracle.com/cloud/free/faq/" target="_blank" rel="noopener noreferrer">FAQ</a> for more information. If you believe this is in error, please contact <a href="https://store-oracle.custhelp.com/app/CP_CP" target='_blank' rel="noopener noreferrer">Oracle Customer Service</a></Trans></p>
                            }
                            {this.props.errorType === '481' &&
                                <p>
                                    <Trans i18nKey="cyberSourceErrMsg.481Updated1">We're unable to complete your sign up. Common errors are due to:</Trans><br/>
                                    &emsp;<Trans i18nKey="cyberSourceErrMsg.481Updated2">a) Entering incomplete or inaccurate information</Trans><br/>
                                    &emsp;<Trans i18nKey="cyberSourceErrMsg.481Updated3">b) Intentionally or unintentionally masking one's location or identity</Trans><br/>
                                    &emsp;<Trans i18nKey="cyberSourceErrMsg.481Updated4">c) Attempting to create multiple accounts.</Trans><br/>
                                    <Trans i18nKey="cyberSourceErrMsg.481Updated5">Please <a href="#" onClick={e => this.onClickHandler()}>try again</a> if this applies to you. Otherwise, reach out to <a href="https://store-oracle.custhelp.com/app/CP_CP" target='_blank' rel="noopener noreferrer">Oracle Customer Service</a> for assistance.</Trans>
                                </p>
                            }
                            {this.props.errorType === '2xx' &&
                                <p>
                                    <Trans i18nKey="cyberSourceErrMsg.2xx">Please <a href='#' onClick={e => this.props.action()}>edit your payment method</a> to provide a valid credit card. If you continue to encounter the error, please reach out to your issuing bank.</Trans>
                                </p>
                            }
                            {this.props.errorType === 'psd2' &&
                            <p>
                                <Trans i18nKey="cyberSourceErrMsg.psd2">Please <a href="#" onClick={e => this.props.action()}>edit your payment method</a> to provide a valid credit card. If you believe this decline is in error, please reach out to <a href="https://go.oracle.com/LP=108555?elqCampaignId=260306" target="_blank" rel="noopener noreferrer">Oracle Customer Service</a>.</Trans>
                            </p>
                            }
                            {this.props.errorType === '202' &&
                                <p>
                                    <Trans i18nKey="cyberSourceErrMsg.202">Your credit card has expired. Please <a href='#' onClick={e => this.props.action()}>edit your payment method</a>, to update expiration date or provide a valid credit card. If you continue to encounter the error, please reach out to your issuing bank.</Trans>
                                </p>
                            }
                            {this.props.errorType === '231' &&
                                <p>
                                    <Trans i18nKey="cyberSourceErrMsg.231">You have provided an invalid account number. Please <a href='#' onClick={e => this.props.action()}>edit your payment method</a> to provide a valid credit card. If you continue to encounter the error, please reach out to your issuing bank.</Trans>
                                </p>
                            }
                            {this.props.errorType === 'avs' &&
                                <p>
                                    <Trans i18nKey='cyberSourceErrMsg.avs'>Billing address doesn't match information from your credit card provider. Please <a href='#' onClick={e => this.props.action()}>edit your payment method</a>, to edit your billing address. If you continue to encounter the error, please reach out to your issuing bank.</Trans>
                                </p>
                            }
                            {this.props.position === 'maxAttemptsError' &&
                                <p><Trans i18nKey="securityVerification.maxAttemptsInfo">If you wish to try again, please return to <a href= "https://signup.cloud.oracle.com/" target='_blank'>signup</a> and provide a valid credit card.</Trans></p>
                            }
                            {this.props.position === 'mobileMaxAttemptsError' &&
                                <p><Trans i18nKey="mobileVerify.maxAttemptsInfo">If you wish to try again, please return to <a href= "https://signup.cloud.oracle.com/" target='_blank'>signup</a> and provide a valid contact number.</Trans></p>
                            }
                            {this.props.position === 'emailMaxAttemptsError' &&
                                <p><Trans i18nKey="emailVerification.emailMaxAttemptsInfo">If you wish to try again, please <a href= "https://signup.cloud.oracle.com/" target='_blank'>try again</a> and provide a valid email address.</Trans></p>
                            }
                            {this.props.position === 'apiError' &&
                                <p><Trans i18nKey="signup.apiErrorDetails">Some data on the Oracle Cloud Account Signup may not be available. Reload page or retry the operation and if the problem persists, then <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target='_blank' rel="noopener noreferrer">contact Oracle Support</a></Trans></p>
                            }
                            {this.props.position === 'api403Error' &&
                                <p><Trans i18nKey="signup.api403ErrorDetails">The number of requests has been exceeded. Reload the page or retry the operation. If the problem persists, then <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target='_blank' rel="noopener noreferrer">contact Oracle Support</a></Trans></p>
                            }
                            {this.props.position === 'promoValidationError' &&
                              <p><Trans i18nKey="emailVerification.emailPromoValidationFailure">The personal information entered through the promotion is not supported. Please try again with another promotion. <a href='#'>Refresh</a></Trans></p>
                            }
                            {this.props.loading && <CircularLoader className="loader-sm center-xs" />}
                        </div>
                    </div>
                </div>
            }
            </>
        )
    }
}

export default Notifier;
