import * as React from 'react';

class TrusteDiv extends React.Component {
  constructor() {
    super();
    this.myRef = React.createRef();
  }

  componentDidUpdate() {
    if (
      !this.myRef.current.childNodes.length
      && window.truste
      && window.truste.eu
      && window.truste.eu.icon
      && window.truste.eu.icon.initialize
    ) {
      window.truste.eu.icon.initialize();
    }
  }

  render() {
    return <div id="teconsent" ref={this.myRef} aria-hidden="true"/>;
  }
}
export default TrusteDiv;
