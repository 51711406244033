import * as React from 'react';
import { Trans } from 'react-i18next';

export const termsOfUseTitle = 'Terms of use';

/* eslint-disable react/prop-types,react/destructuring-assignment,max-len,react/no-unescaped-entities,react/jsx-filename-extension */
const TermsOfUseBody = (props) => (
  <>
    <div>
      <Trans i18nKey="termsOfUse.defaultTerms">
        By clicking on the “Continue” button below, you understand and agree that the use of Oracle's web site
        is subject to the
        <a target="_blank" rel="noopener noreferrer" href={props.termsOfUseLink}> Oracle.com Terms of Use</a>
        . Additional details regarding Oracle's collection and use of your personal information, including information
        about access, retention, rectification, deletion, security, cross-border transfers and other topics, is
        available in the
        <a target="_blank" rel="noopener noreferrer" href={props.privacyPolicyLink}> Oracle Privacy Policy</a>
        .
      </Trans>
    </div>

  </>
);
export default TermsOfUseBody;
