import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Context } from '../../state/Store';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import RedwoodInputText from '../../common/Input/RedwoodInputText';
import { constants } from '../Constants';
import { regExp } from '../Validators';
import RedwoodSelect from '../../common/Select/RedwoodSelect';
import { getFeatureFlag } from '../Helpers';

// eslint-disable-next-line no-unused-vars
const TaxRegistrationComponent = (props) => {
  const { t } = useTranslation();
  const [state, dispatch] = React.useContext(Context);
  const { featureFlags } = state;
  const {
    // eslint-disable-next-line react/prop-types
    onChangeHandler, onBlurHandler, organizationNameCheck, taxFieldCheck,
    // eslint-disable-next-line react/prop-types, max-len
    error: { departmentName: errorDptName, organization: orgError, altEnglishCompanyName: altCompanyError },
  } = props;
  const taxInfo = _.get(state.countryInfo, 'taxFields', null);

  const [customerType, setCustomerType] = React.useState('corporate');
  const [taxRegError, setTaxRegError] = React.useState('');
  const [taxPayerError, setTaxPayerError] = React.useState('');
  const [brazilTaxCNError, setBrazilTaxCNError] = React.useState('');

  const isOrganizationFieldDisabled = () => !!(!_.isEmpty(state.selectedPromo)
      && !_.isEmpty(state.selectedPromo.customerName));

  const changeTaxStatus = (e) => {
    const { value } = e;
    if (value !== 'REGISTERED') {
      setTaxRegError('');
      dispatch({ type: 'UPDATE_TAX_REG_NUM', payload: ['taxRegNumber', ''] });
    }
    taxFieldCheck(value);
    dispatch({ type: 'UPDATE_TAX_INFO_STATUS', payload: e });
  };

  const changeCustomerType = (e) => {
    const { value } = e.currentTarget;
    if (value === 'individual') {
      organizationNameCheck({ target: { name: 'organization', value: '' } }, false);
      dispatch({ type: 'UPDATE_USE', payload: 'PERSONAL' });
    } else {
      dispatch({ type: 'UPDATE_USE', payload: 'COMPANY' });
    }
    setCustomerType(value);
  };

  const shouldShowTaxFields = getFeatureFlag('enable_taxfields', featureFlags) && state.isPaid === true;

  const taxPayerRequired = taxInfo
                           && _.get(taxInfo, 'TaxPayerID[0]', '') === 'R' // TaxPayerID is required field in this country
                           && (_.get(taxInfo, 'TaxPayerID[4]', 'C') === 'B' // TaxPayerID is required for both Corps and Individuals
                           || (_.get(taxInfo, 'TaxPayerID[4]', 'C') === 'C' && customerType === 'corporate')); // TaxPayerID is only required for Corps

  const taxRegNumberRequired = taxInfo
                               && _.get(taxInfo, 'TaxRegNumber[0]', '') === 'R' // TaxRegistrationNumber is required in this country
                               && (_.get(taxInfo, 'TaxRegNumber[4]', 'C') === 'B' // TaxRegistrationNumber is required for both Corps and Individuals
                               || (_.get(taxInfo, 'TaxRegNumber[4]', 'C') === 'C' && customerType === 'corporate')); // TaxRegistrationNumber is only required for Corps

  const shouldShowCompanyNameFieldset = () => customerType === 'corporate';

  const isCompanyRequired = shouldShowCompanyNameFieldset;

  const isAlternativeCompanyNameNeeded = (state.country === 'JP' || state.country === 'CN' || state.country === 'TW' || state.country === 'KR');

  const isDepartmentNameNeeded = state.country === 'JP';

  const shouldShowTaxInformationFieldset = shouldShowTaxFields
    ? (taxRegNumberRequired || taxPayerRequired)
    : false;

  const shouldShowBrazilTaxInscriptionField = shouldShowTaxFields ? state.country === 'BR' : false;

  const validateTaxRegNumber = (e, pattern) => {
    const val = _.trim(e.target.value);
    const { name } = e.target;
    dispatch({ type: 'UPDATE_TAX_REG_NUM', payload: [name, val] });
    const translatedName = t(`personalInfo.${name}`, 'Tax Registration Number');
    if (val === '') {
      setTaxRegError(
        // eslint-disable-next-line react/jsx-filename-extension
        <Trans i18nKey="personalInfo.requiredMsg">
          A value for
          {' '}
          {translatedName}
          {' '}
          is required.
        </Trans>,
      );
      taxFieldCheck(val, false, taxRegError);
    } else if (!val.match(pattern)) {
      setTaxRegError(
        // eslint-disable-next-line react/jsx-filename-extension
        <Trans i18nKey="regExpr.${taxInfo.TaxRegNumber[2]">
          Enter the Tax Registration Number in the
          {' '}
          {taxInfo.TaxRegNumber[3]}
          {' '}
          format
        </Trans>,
      );
      taxFieldCheck(val, false, taxRegError);
    } else {
      taxFieldCheck(val);
      setTaxRegError('');
    }
  };

  const validateTaxPayerId = (e, pattern) => {
    const val = _.trim(e.target.value);
    const { name } = e.target;
    dispatch({ type: 'UPDATE_TAX_FIELDS', payload: [name, val] });
    const translatedName = t(`personalInfo.${name}`, 'Tax Payer Number');
    if (val === '') {
      setTaxPayerError(
        // eslint-disable-next-line react/jsx-filename-extension
        <Trans i18nKey="personalInfo.requiredMsg">
          A value for
          {' '}
          {translatedName}
          {' '}
          is required.
        </Trans>,
      );
      taxFieldCheck(val, false, taxRegError);
    } else if (!val.match(pattern)) {
      setTaxPayerError(
        // eslint-disable-next-line react/jsx-filename-extension
        <Trans i18nKey="regExpr.${taxInfo.TaxPayerID[2]">
          Enter the Tax Payer ID in the
          {' '}
          {taxInfo.TaxPayerID[3]}
          {' '}
          format
        </Trans>,
      );
      taxFieldCheck(val, false, taxPayerError);
    } else {
      taxFieldCheck(val);
      setTaxPayerError('');
    }
  };

  const validateBrazilTaxCNCheck = (e, pattern) => {
    const val = _.trim(e.target.value);
    const { name } = e.target;

    if (name === 'paidCPF') {
      dispatch({ type: 'UPDATE_PAID_CPF', payload: [name, val] });
    } else {
      dispatch({ type: 'UPDATE_PAID_CNPJ', payload: [name, val] });
    }

    if (val === '' || !val.match(pattern)) {
      if (name === 'paidCPF') {
        setBrazilTaxCNError(
          <Trans i18nKey="regExpr.${taxInfo.PaidCPF[2]">
            Enter the CPF in the
            {' '}
            {taxInfo.PaidCPF[3]}
            {' '}
            format
          </Trans>,
        );
      } else {
        setBrazilTaxCNError(
          <Trans i18nKey="regExpr.${taxInfo.PaidCNPJ[2]">
            Enter the CPNJ in the
            {' '}
            {taxInfo.PaidCNPJ[3]}
            {' '}
            format
          </Trans>,
        );
      }
      taxFieldCheck(val, false, brazilTaxCNError);
    } else {
      taxFieldCheck(val);
      setBrazilTaxCNError('');
    }
  };

  React.useEffect(() => {
    if (state.intendedUse === 'PERSONAL' && customerType === 'corporate') {
      dispatch({ type: 'UPDATE_USE', payload: 'COMPANY' });
    }
  }, []);

  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <>
      <div className="row customer-type">
        <div className="col-xs-12">
          <strong>Customer type</strong>
          <div className="row">
            <div key="corporate" className="col-xs-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label aria-label="customer-type">
                <input type="radio" id="corporate-customer-radio" name="customer-type" checked={customerType === 'corporate'} value="corporate" onClick={(e) => { changeCustomerType(e); }} />
                {' '}
                Corporate
              </label>
            </div>
            <div key="individual" className="col-xs-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label aria-label="customer-type">
                <input type="radio" id="individual-customer-radio" name="customer-type" checked={customerType === 'individual'} value="individual" onClick={(e) => { changeCustomerType(e); }} />
                {' '}
                Individual
              </label>
            </div>
          </div>
        </div>
      </div>
      <>
        {shouldShowCompanyNameFieldset() && (
        <div className="row company-info">
          <div className={isAlternativeCompanyNameNeeded && isCompanyRequired === false ? 'col-md-6 col-xs-12' : 'col-xs-12'}>
            <RedwoodInputText
              type="text"
              placeholder={t('signup.organizationPlaceholder')}
              label={t('signup.organization', 'Legal Company Name')}
              required={isCompanyRequired}
              disabled={isOrganizationFieldDisabled()}
              name="organization"
              value={state.companyName}
              maxLength={constants.companyNameCharLength}
              error={orgError.errorMsg}
              valid={orgError.isValid}
              onChange={(e) => {
                onChangeHandler(e, 'UPDATE_ORG', e.target.value);
              }}
              onBlur={(e) => organizationNameCheck(e, isCompanyRequired)}
            />
          </div>
          {isAlternativeCompanyNameNeeded
          && (
          <div className={isCompanyRequired ? 'col-xs-12' : 'col-md-6 col-xs-12'}>
            <RedwoodInputText
              type="text"
              placeholder={t('signup.altEngCompanyNamePlaceholder', 'Enter alternate english company name')}
              label={t('signup.altEnglishCompanyName', 'Alternate English Legal Company Name')}
              name="altEnglishCompanyName"
              value={state.altEnglishCompanyName}
              required={state.companyName !== '' || isCompanyRequired}
              error={altCompanyError.errorMsg}
              valid={altCompanyError.isValid}
              onChange={(e) => {
                onChangeHandler(e, 'UPDATE_AlT_COMPANY_NAME', e.target.value);
              }}
              onBlur={(e) => { onBlurHandler(e, regExp.altEnglishCompanyName, 'UPDATE_AlT_COMPANY_NAME'); }}
            />
          </div>
          )}
        </div>
        )}
        {isDepartmentNameNeeded && (
        <div className="row department-fieldset">
          <div className="col-md-6 col-xs-12">
            <RedwoodInputText
              type="text"
              placeholder={t('signup.departmentNamePlaceholder', 'Enter your Department')}
              required
              label={t('signup.departmentName', 'Department Name')}
              name="departmentName"
              value={state.departmentName}
              valid={errorDptName.isValid}
              error={errorDptName.errorMsg}
              onChange={(e) => {
                onChangeHandler(e, 'UPDATE_DEPT_NAME', e.target.value);
              }}
              onBlur={(e) => onBlurHandler(e, regExp.departmentName, 'UPDATE_DEPT_NAME')}
            />
          </div>
          <div className="col-md-6 col-xs-12">
            <RedwoodInputText
              type="text"
              placeholder={t('signup.jobTitlePlaceholder', 'Enter your job title')}
              label={t('signup.jobTitle', 'Job Title')}
              name="jobTitle"
              value={state.jobTitle}
              onChange={(e) => {
                onChangeHandler(e, 'UPDATE_JOB_TITLE', e.target.value);
              }}
              onBlur={(e) => onBlurHandler(e, '.*', 'UPDATE_JOB_TITLE')}
            />
          </div>
        </div>
        )}
        {shouldShowTaxInformationFieldset && (
        <div className="row tax-info">
          { taxRegNumberRequired && (
          <>
            <div className="col-md-6 col-xs-12">
              <RedwoodSelect
                value={state.taxInfoStatus}
                label={t('personalInfo.taxInformationSelect', 'Tax information')}
                name="taxInfoStatus"
                data={[
                  { value: 'REGISTERED', label: t('personalInfo.taxRegisteredOption', 'Registered') },
                  { value: 'NOTREGISTERED', label: t('personalInfo.taxNotRegisteredOption', 'Not Registered') },
                  { value: 'APPLIEDFOR', label: t('personalInfo.taxAppliedForOption', 'Applied for') },
                ]}
                onChange={(e) => {
                  changeTaxStatus(e);
                  if (e.value === 'REGISTERED') {
                    validateTaxRegNumber({ target: { name: 'taxRegistrationNumber', value: '' } }, taxInfo.TaxRegNumber[1]);
                  }
                }}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.taxRegistrationNumberPlaceholder', 'Enter the Tax Registration Number')}
                label={t('personalInfo.TaxRegistrationNumber', 'Tax Registration Number')}
                name="taxRegistrationNumber"
                value={state.taxRegNumber}
                required={taxRegNumberRequired}
                disabled={state.taxInfoStatus && state.taxInfoStatus.value !== 'REGISTERED'}
                error={taxRegError}
                valid={taxRegError === ''}
                onChange={(e) => {
                  dispatch({ type: 'UPDATE_TAX_REG_NUM', payload: [e.target.name, e.target.value] });
                }}
                onBlur={(e) => validateTaxRegNumber(e, taxInfo.TaxRegNumber[1])}
              />
            </div>
          </>
          )}
          { taxPayerRequired && (
            <div className="col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={t('personalInfo.taxPayerIdPlaceholder', 'Enter the Tax Payer Number')}
                label={t('personalInfo.taxPayerIdLabel', 'Tax Payer Number')}
                name="taxPayerId"
                value={state.taxPayerId}
                required={taxPayerRequired}
                error={taxPayerError}
                valid={taxPayerError === ''}
                onChange={(e) => {
                  dispatch({ type: 'UPDATE_TAX_FIELDS', payload: [e.target.name, e.target.value] });
                }}
                onBlur={(e) => validateTaxPayerId(e, taxInfo.TaxPayerID[1])}
              />
            </div>
          )}
        </div>
        )}
        {shouldShowBrazilTaxInscriptionField && (
        <div className="row brazil-inscription">
          <div className="col-xs-12">
            {customerType === 'corporate' && (
            <RedwoodInputText
              type="text"
              placeholder={t('personalInfo.paidCNPJPlaceholder', 'Enter the CNPJ')}
              label="CNPJ"
              name="paidCNPJ"
              id="paidCNPJ"
              valid={brazilTaxCNError === ''}
              value={state.paidCNPJ}
              required
              error={brazilTaxCNError}
              onChange={(e) => {
                dispatch({ type: 'UPDATE_PAID_CNPJ', payload: [e.target.name, e.target.value] });
              }}
              onBlur={(e) => validateBrazilTaxCNCheck(e, taxInfo.PaidCNPJ[1])}
            />
            )}
            {customerType === 'individual' && (
            <RedwoodInputText
              type="text"
              placeholder={t('personalInfo.paidCPFPlaceholder', 'Enter the CPF')}
              label="CPF"
              name="paidCPF"
              id="paidCPF"
              valid={brazilTaxCNError === ''}
              value={state.paidCPF}
              required
              error={brazilTaxCNError}
              onChange={(e) => {
                dispatch({ type: 'UPDATE_PAID_CPF', payload: [e.target.name, e.target.value] });
              }}
              onBlur={(e) => validateBrazilTaxCNCheck(e, taxInfo.PaidCPF[1])}
            />
            )}
          </div>
        </div>
        )}
      </>
    </>
  );
};

export default TaxRegistrationComponent;
