/* eslint-disable */
import { initialState } from './Store';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_NAME':
      return {
        ...state,
        username: action.payload,
      };
    case 'UPDATE_COUNTRY':
      return {
        ...state,
        country: action.payload[0],
        countryObject: action.payload[1],
        countryInfo: action.payload[2],
        orgId: action.payload[3],
      };
    case 'UPDATE_PROMO_COUNTRY':
      return {
        ...state,
        countryObject: action.payload,
      };
    case 'UPDATE_ACC_NAME':
      return {
        ...state,
        accountName: action.payload,
      };
    case 'UPDATE_HOME_REGION':
      return {
        ...state,
        homeRegion: action.payload,
      };
    case 'HOME_REGIONS_LIST':
      return {
        ...state,
        homeRegions: action.payload,
      };
    case 'NEXT_STEP':
      return {
        ...state,
        nextStep: action.payload,
      };
    case 'UPDATE_FIRST_NAME':
      return {
        ...state,
        firstName: action.payload,
      };
    case 'UPDATE_LAST_NAME':
      return {
        ...state,
        lastName: action.payload,
      };
    case 'UPDATE_ALTERNATE_NAME':
      return {
        ...state,
        alternateName: action.payload,
      };
    case 'UPDATE_COUNTRIES':
      return {
        ...state,
        countries: action.payload,
      };
    case 'UPDATE_ORG':
      return {
        ...state,
        companyName: action.payload,
      };
    case 'UPDATE_DEPT_NAME':
      return {
        ...state,
        departmentName: action.payload,
      };
    case 'UPDATE_AlT_COMPANY_NAME':
      return {
        ...state,
        altEnglishCompanyName: action.payload,
      };
    case 'UPDATE_JOB_TITLE':
      return {
        ...state,
        jobTitle: action.payload,
      };
    case 'UPDATE_ADDRESS':
      return {
        ...state,
        address: { ...state.address, [action.payload[0]]: action.payload[1] },
      };
    case 'UPDATE_PROMO_ADDRESS':
      return {
        ...state,
        address: action.payload,
        addressContinueBtn: false,
      };
    case 'UPDATE_PROMO_TITLE':
      return {
        ...state,
        promoTitle: action.payload,
      };
    case 'RESET_DEFAULT_ADDRESS':
      return {
        ...state,
        address: initialState.address,
      };
    case 'UPDATE_STATES':
      return {
        ...state,
        states: action.payload,
      };
    case 'UPDATE_USE':
      return {
        ...state,
        intendedUse: action.payload,
      };
    case 'UPDATE_TAX_FIELDS':
      return {
        ...state,
        taxPayerId: action.payload[1],
      };
    case 'UPDATE_TAX_REG_NUM':
      return {
        ...state,
        taxRegNumber: action.payload[1],
      };
    case "UPDATE_TAX_INFO_STATUS":
      return {
        ...state,
        taxInfoStatus: action.payload,
      };
    case 'UPDATE_PAID_CPF':
      return {
        ...state,
        paidCPF: action.payload[1],
      };
    case 'UPDATE_PAID_CNPJ':
      return {
        ...state,
        paidCNPJ: action.payload[1],
      };
    case 'UPDATE_PHONE':
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case 'UPDATE_PROMOTIONS':
      return {
        ...state,
        promotions: action.payload,
      };

    case 'UPDATE_PROMO':
      return {
        ...state,
        selectedPromo: action.payload,
      };

    case 'UPDATE_PROMO_ERROR':
      return {
        ...state,
        promoValidationError: action.payload,
      };

    case 'UPDATE_SELECTED_PROMO_ID':
      return {
        ...state,
        selectedPromoId: action.payload,
      };

    case 'UPDATE_CAPTCHA_JWT_TOKEN':
      return {
        ...state,
        captchaJwtToken: action.payload,
      };

    case 'UPDATE_DEFAULT_PROMO':
      return {
        ...state,
        defaultPromo: action.payload,
      };
    case 'UPDATE_PAID_PROMO':
      return {
        ...state,
        isPaid: action.payload,
      };
    case 'UPDATE_ORACLE_EMPLOYEE':
      return {
        ...state,
        oracleEmployee: action.payload,
      };
    case 'UPDATE_PASSWORD':
      return {
        ...state,
        password: action.payload,
      };
    case 'UPDATE_CONFIRM_PASSWORD':
      return {
        ...state,
        confirmPassword: action.payload,
      };
    case 'UPDATE_BILLING_INFO':
      return {
        ...state,
        billingAddress: action.payload[0],
        paymentOptions: action.payload[1],
      };
    case 'UPDATE_VERIFICATION_CODE':
      return {
        ...state,
        verificationCode: action.payload,
      };
    case 'UPDATE_BASIC_DETAILS':
      return {
        ...state,
        sourceType: action.payload[0],
        referrer: action.payload[1],
        language: action.payload[2],
      };
    case 'PAYMENT_RESPONSE':
      return {
        ...state,
        paymentResponse: action.payload,
      };

    case 'UPDATE_SMS_NUMBER':
      return {
        ...state,
        smsNumber: action.payload,
      };

    case 'OPERATION_ID':
      return {
        ...state,
        operationId: action.payload[0],
        paymentStatus: action.payload[1],
      };

    case 'PAYMENT_ERROR':
      return {
        ...state,
        paymentError: action.payload,
      };

    case 'ADDRESS_VALIDATION':
      return {
        ...state,
        addressValidation: action.payload,
      };

    case 'MOBILE_VALIDATION':
      return {
        ...state,
        mobileValidation: action.payload,
      };

    case 'VALID_PHONE_NUMBER':
      return {
        ...state,
        validPhoneNumber: action.payload,
      };

    case 'SHOW_THANK_YOU':
      return {
        ...state,
        showThankYou: action.payload,
      };
    case 'UPDATE_URI':
      return {
        ...state,
        consoleUri: action.payload,
      };
    case 'PAYMENT_VERIFICATION_TIMES':
      return {
        ...state,
        numPaymentVerifyBtnClicked: action.payload,
      };

    case 'UPDATE_PHONE_COUNTRY_CODE':
      return {
        ...state,
        phoneCountryCode: action.payload,
      };

    case 'UPDATE_APIERROR':
      return {
        ...state,
        apiError: action.payload,
      };

    case 'UPDATE_API403ERROR':
      return {
        ...state,
        api403Error: action.payload,
      };
    case 'UPDATE_API403_ERROR_MSG':
      return {
        ...state,
        api403ErrorMsg: action.payload,
      };
    case 'SET_QUERY_STATE':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_DEFAULT_VALUES':
      return {
        ...state,
        defaultValues: action.payload,
      };
    case 'SET_FEATURE_FLAGS':
      return {
        ...state,
        featureFlags: action.payload,
      };
    case 'VERIFY_EMAIL_TOKEN':
      return {
        ...state,
        emailToken: action.payload,
      };

    case 'VALIDATE_EMAIL':
      return {
        ...state,
        validateEmail: action.payload,
      };

    case 'SEND_EMAIL':
      return {
        ...state,
        sendEmail: action.payload,
      };

    case 'UPDATE_USER_SESSION':
      return {
        ...state,
        user_session: action.payload,
      };

    case 'UPDATE_SHOW_BANNER':
      return {
        ...state,
        showBanner: action.payload,
      };

    case 'UPDATE_OPAY_OUTAGE':
      return {
        ...state,
        isOpayOutage: action.payload,
      };
    case 'UPDATE_ORCL_OUTAGE':
      return {
        ...state,
        oracleEmployee: action.payload,
      };

    case 'UPDATE_DOMAIN_BLOCKED':
      return {
        ...state,
        domainBlocked: action.payload,
      };

    case "SHOW_ACCOUNT_NOT_UNIQUE":
      return {
        ...state,
        accountNotUnique: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
