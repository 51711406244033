import React from 'react';
import '../react-redwood.css';
import './RedwoodInputText.css';
import Icon from "../Icon/Icon";
import { Trans } from "react-i18next";

const RedwoodInputText = (props) => {
    const [showInfo, setShowInfo] = React.useState(false);
    let timerId;

    const debounceFn = (fn, timeout = 1500) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => { fn() }, timeout);
    };

    return (
        <div>
            <label
              className={`has-float-label${ props.valid === false ? ' redBorder': ''}`}
              htmlFor={props.id}
            >
                <input
                  autoComplete='chrome-off'
                  {...props}
                  className={props.disabled ? 'disabled': props.class}
                  onFocus={() => { setShowInfo(true); }}
                  aria-describedby={`${props.name}-error`}
                  onChange={
                    (e) => {
                      props.onChange && props.onChange(e);
                      if (props.minLength && e.target.value.length >= props.minLength || e.target.value.length > 6 && e.target.name !== 'email') {
                        debounceFn(() => props.onBlur(e));
                      }
                    }
                  }
                  onBlur={(e) => {
                    props.onBlur && props.onBlur(e)
                  }}
                />
                <span className={`inputName${props.disabled ? ' disabled' : ''}`}>{props.label}</span>
            </label>
            {showInfo
            &&  <>
                    <div id={`${props.name}-error`} className={props.valid === false ? '' : 'hide'} data-testid={`${props.name}-error-label`}>
                        <Icon name="error"/>
                        {props.error}
                    </div>
                    <div id={`${props.name}-info`} className={props.info === false ? '' : 'hide'} data-testid={`${props.name}-info-label`}>
                        <Icon name="info" />
                        {props.infoMsg}
                    </div>
                </>
            }
            {!props.required
            &&  <>
                    <div className="optional">
                        <Trans i18nKey="personalInfo.optional">Optional</Trans>
                    </div>
                    <div className="clear"/>
                </>
            }
            <div className="clear"/>
        </div>
    );
};

export default RedwoodInputText;
