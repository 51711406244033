import * as React from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../state/Store';
import { getOrgId, transformPromos } from '../../Helpers';
import SendMetrics from '../../../CourierService/SendMetrics';

import { fetchPromotionGuests, fetchStateId } from '../../../CourierService/FetchService';
import countries from '../../../data/CountriesInfo.json';
import { navTrack } from '../../../config/adobeAnalytics';

// eslint-disable-next-line no-unused-vars
const PromoLoading = (props) => {
  const { t } = useTranslation();
  const [state, dispatch] = React.useContext(Context);
  const [fetchPromoRetries, setFetchPromoRetries] = React.useState(0);

  const setApiError = () => {
    dispatch({
      type: 'UPDATE_APIERROR',
      payload: true,
    });
  };

  const getStateId = (offer, selectedState, isProvinceFlag) => {
    let statesList = [];
    if (offer.country !== '' && selectedState !== '') {
      fetchStateId(offer.country)
        .then(async (res) => {
          if (res.status === 200) {
            statesList = await res.json();
            // eslint-disable-next-line no-unused-expressions
            const found = !_.isEmpty(statesList)
            && statesList.items.filter((st) => st.id.includes(_.toUpper(selectedState)))[0];
            if (found) {
              const addressFromState = offer.address;
              if (isProvinceFlag) {
                addressFromState.selectedProvince = { value: { id: found.id }, label: found.name };
              } else {
                addressFromState.selectedState = { value: { id: found.id }, label: found.name };
              }
              dispatch({
                type: 'UPDATE_PROMO_ADDRESS',
                payload: addressFromState,
              });
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  const selectOffer = (offer) => {
    dispatch({
      type: 'UPDATE_PROMO',
      payload: offer,
    });

    if (!_.isEmpty(offer.address)) {
      dispatch({
        type: 'UPDATE_PROMO_ADDRESS',
        payload: offer.address,
      });
    }

    const isProvinceFlag = !!offer.address.province;
    const stateOrProvince = isProvinceFlag
      ? offer.address.province : offer.address.state;
    // eslint-disable-next-line no-use-before-define
    getStateId(offer, stateOrProvince, isProvinceFlag);

    if (!_.isEmpty(offer.firstName)) {
      dispatch({
        type: 'UPDATE_FIRST_NAME',
        payload: offer.firstName,
      });
    }

    if (!_.isEmpty(offer.lastName)) {
      dispatch({
        type: 'UPDATE_LAST_NAME',
        payload: offer.lastName,
      });
    }

    if (!_.isEmpty(offer.customerName)) {
      dispatch({
        type: 'UPDATE_ORG',
        payload: offer.customerName,
      });
    }

    if (!_.isEmpty(offer.country)) {
      const countryObject = { value: offer.country, label: t(`countries.${offer.country}`) };
      dispatch({
        type: 'UPDATE_PROMO_COUNTRY',
        payload: countryObject,
      });
      const countryInfo = countries.countries[offer.country];
      if (countryInfo === undefined) {
        dispatch({
          type: 'UPDATE_COUNTRY',
          payload: ['', countryObject, {}, '', ''],
        });
      } else {
        const orgId = getOrgId(countryInfo.tc.OrgName);
        dispatch({
          type: 'UPDATE_COUNTRY',
          payload: [offer.country, countryObject, countryInfo, orgId],
        });
      }
    }

    if (!offer.creditCardRequired) {
      navTrack('oci-signup', state.language, 's1-selected-promo', 'credit-card-not-required');
    }

    if (offer.programType === 'SE-TRIAL') {
      props.updateSETrialAccountName(offer.customerName);
    }

    if (offer.creditCardRequired && state.isOpayOutage) {
      dispatch({
        type: 'UPDATE_SHOW_BANNER',
        payload: true,
      });
    }
  };

  React.useEffect(() => {
    if (!_.isEmpty(state.selectedPromoId)) {
      if (!_.isEmpty(state.username) && !_.isEmpty(props.emailToken)) {
        fetchPromotionGuests(encodeURIComponent(state.username), '')
          .then(async (res) => {
            if (res.status === 200) {
              const result = await res.json();
              const promos = result.items;
              const index = _.findIndex(promos, (p) => p.id === state.selectedPromoId);
              if (index > -1) {
                const selectedPromo = transformPromos([promos[index]])[0];
                selectOffer(selectedPromo);
              }
              SendMetrics({ name: 'PromotionGuests.Count.Metrics', value: 1, userSession: state.user_session });
            } else if (res.status === 400) {
              SendMetrics({ name: 'PromotionGuests.Count.Metrics.Error', value: 1, userSession: state.user_session });
            } else if (res.status >= 500) {
              SendMetrics({ name: 'PromotionGuests.Count.Metrics.Error', value: 1, userSession: state.user_session });
              if (fetchPromoRetries === 3) {
                setApiError();
              } else {
                setFetchPromoRetries(fetchPromoRetries + 1);
              }
            }
          }).catch((error) => {
          // eslint-disable-next-line no-console
            console.log(error);
          });
      }
    }
  }, [state.selectedPromoId, fetchPromoRetries]);

  return null;
};

export default PromoLoading;
