/* eslint-disable camelcase,no-unused-vars,no-console */
import { courier_service_url } from '../config/apiConfig';

const SendAction = (props) => {
  fetch(
    `${courier_service_url}/sendAction`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        source: 'oci_signup_client',
        type: 'action',
        timestamp: Date.now(),
        metadata: [],
        value: '',
        ...props,
      }),
    },
  ).then(async (res) => {
  }).catch((error) => {
    console.log(error);
  });
};

export default SendAction;
