import React from 'react';
import './Accordion.css';
import * as _ from 'lodash';
import { Context } from '../../state/Store';
import Icon from '../Icon/Icon';

const Accordion = (props) => {
  const [state] = React.useContext(Context);
  const onClick = (index) => {
    if (index < props.accordionState) {
      props.openAccordionSection(index);
    }
  };

  const isOpen = (comparison) => (comparison === props.accordionState);

  return (
    <div className="accordionSectionContainer">
      {props.children.map((child, index) => (
        <>
          {_.isEmpty(child.props) ? <> </>
            : (
              <div className="accordionSection">
                <div className={`accordionSectionHeader ${isOpen(index) ? 'activeTab' : (index > props.accordionState) ? 'inactiveTab' : ''}`} key={index} onClick={(e) => onClick(index)}>
                  <h2 className="accordionLabel">{child.props.label}</h2>
                  <div className="accordionArrow">
                    {!isOpen(index) && <Icon name="accordionArrowUp" />}
                    {isOpen(index) && <Icon name="accordionArrowDown" />}
                  </div>
                </div>
                {isOpen(index) && (
                <div>
                  {child.props.children}
                </div>
                )}
              </div>
            )}
        </>
      ))}
    </div>
  );
};

export default Accordion;
