/* eslint-disable camelcase */
import * as _ from 'lodash';
import {
  base_url, planned_outage_banner_url, tld_url, unplanned_outage_banner_url,
} from '../config/apiConfig';

export const fetchRegions = (oracleEmployee, programType) => {
  const serviceTypeParam = _.isString(programType) && programType.toUpperCase() === 'MYSQLDSAWS' ? '&serviceType=MYSQLDSAWS' : '';
  return fetch(
    `${base_url}/region?clientId=SignupUI&oracleEmployee=${oracleEmployee}${serviceTypeParam}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const fetchEmailToken = (emailToken) => fetch(
  `${base_url}/email/verifyEmail?token=${emailToken}`,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchAssociatedAccounts = (email) => fetch(
  `${base_url}/sendAssociatedAccountsByEmail?email=${email}`,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchBanner = () => fetch(
  `${base_url}/banner`,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchValidateAccount = (body) => fetch(
  `${base_url}/validateAccountName`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  },
);

export const fetchValidateAccountRecursively = (body) => fetch(
  `${base_url}/validateAccountName`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  },
);

export const fetchSecurityLink = (body) => fetch(
  `${base_url}/email/sendSecurityLink`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  },
);

export const fetchCaptchaToken = (body) => fetch(
  `${base_url}/verifyCaptchaToken`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  },
);

export const fetchPromotionGuests = (email, token) => fetch(
  `${base_url}/promotionGuests?email=${email}&captchaJwtToken=${token}`,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchValidateEmail = (body) => fetch(
  `${base_url}/validateEmail`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  },
);

export const fetchStateId = (country) => fetch(
  `${base_url}/states?countryId=${country}`,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchPaymentsByEmail = (email, body) => fetch(
  `${base_url}/payments?email=${email}`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  },
);

export const fetchCreateAccount = (email, body) => fetch(
  `${base_url}/createAccount?email=${email}&language=en`,
  {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body,
  },
);

export const fetchPollLocationUrl = (operationId, programType) => fetch(
  `${base_url}/pollLocationUrl/${operationId}${_.toUpper(programType) === 'MYSQLDSAWS' ? '?programType=MYSQLDSAWS' : ''}`,
  {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchAccountStatusByWorkRequest = (requestId, programType, region) => fetch(
  `${base_url}/accountStatus?workRequestId=${requestId}&region=${region}${_.toUpper(programType) === 'MYSQLDSAWS' ? '&programType=MYSQLDSAWS' : ''}`,
  {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchPlannedOutageBanner = () => fetch(
  `${planned_outage_banner_url}`,
  {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchUnplannedOutageBanner = () => fetch(
  `${unplanned_outage_banner_url}`,
  {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  },
);

export const fetchEmailTld = () => {
  const expiration = 10 * 60; // 10 minutes
  const storedEmailTlds = localStorage.getItem('email-tld');
  const storedEmailTldsExpiration = localStorage.getItem('email-tld:ts');

  if (storedEmailTlds !== null && storedEmailTldsExpiration !== null) {
    // found in localStorage
    const maturity = (Date.now() - storedEmailTldsExpiration) / 1000;
    if (maturity < expiration) {
      return Promise.resolve({
        status: 200,
        text: () => Promise.resolve(storedEmailTlds),
      });
    }
    localStorage.removeItem('email-tld');
    localStorage.removeItem('email-tld:ts');
  }
  return fetch(
    `${tld_url}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'text/plain' },
    },
  ).then((resp) => {
    if (resp.status === 200) {
      resp.clone().text().then((emails) => {
        localStorage.setItem('email-tld', emails);
        localStorage.setItem('email-tld:ts', Date.now());
      });
    }
    return resp;
  });
};
