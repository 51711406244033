/* eslint-disable no-shadow,react/prop-types,
import/no-named-as-default,import/no-named-as-default-member
*/
import * as React from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import MobileVerifyComponent from '../MobileVerification/MobileVerifyComponent';
import AgreementComponent from '../Agreement/AgreementComponent';
import SecurityVerificationComponent from '../SecurityVerification/SecurityVerificationComponent';
import Accordion from '../../common/Accordion/Accordion';
import PersonalInfoComponent from '../PersonalInfo/PersonalInfoComponent';
import { Context } from '../../state/Store';
import '../SignupForm/SignUpFormContainer.css';
import Notifier from '../../common/Notifier/Notifier';
import { getFeatureFlag } from '../Helpers';
import ExistingEmailDialog from '../Dialogs/ExistingEmailDialog/ExistingEmailDialog';
import ThrottlingBanner from '../ThrottlingBanner/ThrottlingBanner';

const UserInfoComponent = (props) => {
  const [state] = React.useContext(Context);
  const [touched, setTouched] = React.useState({});
  const [error, setError] = React.useState({
    address1: { val: '' },
    address2: { val: '' },
    address3: { val: '' },
    address4: { val: '' },
    city: { val: '' },
    state: { val: '' },
    postalcode: { val: '' },
    province: { val: '' },
    county: { val: '' },
    phoneNumber: { val: '' },
  });
  const [showPaymentDetails, setShowPaymentDetails] = React.useState(false);
  const [accordionState, setAccordionOpenState] = React.useState(0);
  const [createAccountStatus, setCreateAccountStatus] = React.useState({
    checked: false,
    createAccountApi: false,
  });
  const [checkbox, setCheckbox] = React.useState(false);
  const { toggleCallback, programType } = props;
  const { t } = useTranslation();
  const { featureFlags } = state;

  const updateError = (name, isValid, val, errMsg) => {
    setError((error) => ({
      ...error,
      ...{ [name]: { isValid, val, msg: errMsg } },
    }));
  };

  const updateTouched = (name, status) => {
    setTouched((touched) => ({ ...touched, ...{ [name]: status } }));
  };

  const updateCheckbox = (status) => {
    setCheckbox(status);
  };

  const displayPaymentDetails = (flag) => setShowPaymentDetails(flag);

  const openAccordionSection = (accordionIndex) => {
    setAccordionOpenState(accordionIndex);
  };

  const updateCreateAccountStatus = (checkbox, button) => {
    setCreateAccountStatus({ checked: checkbox, createAccountApi: button });
  };

  // const getSmsFeatureFlag = () => {
  //   if(!_.isEmpty(state.featureFlags)){
  //     const index = _.findIndex(state.featureFlags, function (o) {
  //       return o.featureName === 'sms';
  //     });
  //     return state.featureFlags[index].isActive;
  //   } else {
  //     return false;
  //   }
  // };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {state.nextStep
        && state.countryInfo !== undefined
        && state.operationId === '' && (
          <div className="contentContainer">
            <div className="upperContentContainer">
              <ThrottlingBanner />
              {state.apiError && (
                <Notifier
                  type="error"
                  message={t('signup.apiError')}
                  position="apiError"
                />
              )}
              <ExistingEmailDialog />
              {/* eslint-disable-next-line no-nested-ternary */}
              {!_.isEmpty(state.selectedPromo)
              && !state.selectedPromo.creditCardRequired
              && !state.selectedPromo.validationCodeRequired ? (
                <Accordion
                  accordionState={accordionState}
                  openAccordionSection={openAccordionSection}
                >
                  <div
                    label={t('personalInfo.addressInfo', 'Address Information')}
                  >
                    <PersonalInfoComponent
                      error={error}
                      updateError={updateError}
                      updateTouched={updateTouched}
                      touched={touched}
                      openAccordionSection={openAccordionSection}
                    />
                  </div>
                  {getFeatureFlag('sms', featureFlags) && (
                    <div
                      label={
                        !_.isEmpty(state.selectedPromo)
                        && state.selectedPromo.programType === 'SE-TRIAL'
                          ? t(
                            'mobileVerify.customerPhoneLabel',
                            'Customer phone number',
                          )
                          : t(
                            'mobileVerify.contactPhoneLabel',
                            'Contact phone number',
                          )
                      }
                    >
                      <MobileVerifyComponent
                        openAccordionSection={openAccordionSection}
                      />
                    </div>
                  )}

                  <></>
                </Accordion>
                ) : !_.isEmpty(state.selectedPromo)
                && !state.selectedPromo.creditCardRequired ? (
                  <Accordion
                    accordionState={accordionState}
                    openAccordionSection={openAccordionSection}
                  >
                    <div
                      label={t('personalInfo.addressInfo', 'Address Information')}
                    >
                      <PersonalInfoComponent
                        error={error}
                        updateError={updateError}
                        updateTouched={updateTouched}
                        touched={touched}
                        openAccordionSection={openAccordionSection}
                      />
                    </div>
                    {getFeatureFlag('sms', featureFlags) && (
                    <div
                      label={t(
                        'mobileVerify.mobileVerificationLabel',
                        'Mobile Verification',
                      )}
                    >
                      <MobileVerifyComponent
                        openAccordionSection={openAccordionSection}
                      />
                    </div>
                    )}
                  </Accordion>
                  ) : (
                    <Accordion
                      accordionState={accordionState}
                      openAccordionSection={openAccordionSection}
                    >
                      <div
                        label={t('personalInfo.addressInfo', 'Address Information')}
                      >
                        <PersonalInfoComponent
                          error={error}
                          updateError={updateError}
                          updateTouched={updateTouched}
                          touched={touched}
                          openAccordionSection={openAccordionSection}
                        />
                      </div>
                      {getFeatureFlag('sms', featureFlags) && (
                      <div
                        label={t(
                          'mobileVerify.mobileVerificationLabel',
                          'Mobile Verification',
                        )}
                      >
                        <MobileVerifyComponent
                          openAccordionSection={openAccordionSection}
                        />
                      </div>
                      )}
                      <div
                        label={state.isPaid ? t('securityVerification.paymentVerifyLabel', 'Payment/Identity Verification')
                          : t('securityVerification.paymentVerifyLabelFree', 'Payment/Identity Verification - You will not be charged')}
                      >
                        <SecurityVerificationComponent
                          showPaymentDetails={showPaymentDetails}
                          displayPaymentDetails={displayPaymentDetails}
                          openAccordionSection={openAccordionSection}
                          updateCreateAccountStatus={updateCreateAccountStatus}
                          updateCheckbox={updateCheckbox}
                          programType={programType}
                        />
                      </div>
                    </Accordion>
                  )}
            </div>
            <AgreementComponent
              createAccountStatus={createAccountStatus}
              updateCreateAccountStatus={updateCreateAccountStatus}
              updateCheckbox={updateCheckbox}
              checkbox={checkbox}
              toggleCallback={toggleCallback}
              programType={programType}
            />
          </div>
      )}
    </>
  );
};

export default UserInfoComponent;
