import React from 'react';
import Icon from "../Icon/Icon";
import ReactPasswordStrength from 'react-password-strength';
import './RedwoodPassword.css';
import { Trans } from "react-i18next";

class RedwoodPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            type: "password",
            showHideIcon: "showIcon",
            hide: false,
            showPassword: false,
            showInfo: false,
        };
    }


    handleBlur = () => {

        if (this.props.pattern) {
            this.setState({error: false});
            this._input.checkValidity();
        }

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    triggerInvalid = () => {
        this.setState ({ error: true });
    }

    toggleType = () => {
        this.type = this.state.type === 'password' ? 'text' : 'password';
        this.setState({type : this.type, showPassword: !this.state.showPassword});
    }

    onClickHandler = () => {
        this.setState({showInfo: true});
    }

    onChangeHandler = (callback) => {
        this.props.onChange(callback);
    }

    render() {
        const escChars = "\` \~ \< \> \\";
        return (
            <div>
            <label className={`has-float-label passwd ${this.props.isPasswordInvalid === true ? 'redBorder': ''}`}>

            { this.props.passwordStrengthIndicator === "true" ? (
                <ReactPasswordStrength
                    className="customClass"
                    minLength={8}
                    minScore={2}
                    changeCallback={this.onChangeHandler}
                    scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                    inputProps={{
                        name: this.props.name,
                        type:this.state.type,
                        onBlur: this.handleBlur,
                        autoFocus: true,
                        autoComplete: "new-password",
                        'data-testid': this.props.testId,
                        className: "form-control",
                        placeholder: this.props.placeholder,
                        tabIndex:this.props.tabIndex,
                        required:this.props.required ? true : false,
                        pattern:this.props.pattern,
                        onInvalid:this.triggerInvalid,
                        onClick:this.onClickHandler,
                        onFocus:this.onClickHandler,
                        'aria-describedby': this.props.showError ? `${this.props.name}-error-description`:`${this.props.name}-conditions-description`
                    }}
                />
            ) : (

                <input ref={c => (this._input = c) }
                       autoComplete="new-password"
                       type={this.state.type}
                       name={this.props.name}
                       data-testid={this.props.testId}
                       value={this.props.value}
                       tabIndex={this.props.tabIndex}
                       placeholder={this.props.placeholder}
                       required={this.props.required ? true : false}
                       pattern={this.props.pattern}
                       onBlur={this.handleBlur}
                       onInvalid={this.triggerInvalid}
                       onChange={this.props.onChange}
                       aria-describedby={this.props.showError ? `${this.props.name}-error-description`:`${this.props.name}-conditions-description`}
                />
            )}
                <span className="inputName">{this.props.label}</span>
                <div onClick={this.toggleType} className= {this.state.showPassword ? "toggleBtn hideEyeIcon ${this.state.showHideIcon}" : "toggleBtn showEyeIcon ${this.state.showHideIcon}" }/>


            </label>
            { this.state.showInfo === true &&
            <div id={`${this.props.name}-conditions-description`} className={this.props.valid === false ? "hide" : ""}>

                <Icon testId="first-validation-icon" name={this.props.condition1 ? 'greenCheck' : (this.props.validity === false ? 'error': 'grayCheck')}/>
                <Trans i18nKey="signup.pwCondition1"> Password must contain a minimum of 8 characters, 1 lowercase, 1 uppercase, 1 numeric, and 1 special character </Trans>.<br/>

                <Icon testId="second-validation-icon" name={this.props.condition2 ? 'greenCheck' :  'error'}/>
                <Trans i18nKey="signup.pwCondition2"> Password cannot exceed 40 characters, contain the users first name, last name, email address, spaces, or {escChars} special characters</Trans>.
            </div>
            }
            <div id={`${this.props.name}-error-description`} className={`${this.props.showError && this.props.validity === false ? "" : "hide"}`}>
                <Icon name="errorIcon"/>
                {this.props.error}
            </div>
                {
                    this.props.showPasswordIcon
                    &&  (
                        <div>
                            <Icon testId="third-validation-icon" name='greenCheck'/>
                            <Trans i18nKey="signup.confirmPwMatched">Passwords Matched</Trans>
                        </div>
                        )
                }
        </div>
        );
    }
}

export default RedwoodPassword;
